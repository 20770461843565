<template>
  <div class="content">
    <el-tabs v-model="activeName" type="border-card">
      <el-tab-pane v-for="(item, index) in reports" :key="index" :label="item" :name="item">
        <div v-if="activeName == item">
          <report-section :selected="item"></report-section>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import ReportSection from '../components/report'
export default {
  components: {
    ReportSection
  },
  data () {
    return {
      activeName: 'Тайлан /Төлбөрийн хэрэгсэлээр/',
      reports: ['Тайлан /Төлбөрийн хэрэгсэлээр/']
    }
  }
}
</script>
