<template>
  <gmap-map :center="center" :zoom="15" :options="mapOptions" :draggable="false" style="width:100%;  height: 300px; margin-top: 5px;">
    <gmap-marker :position="center" :draggable="true" @drag="handleDrag"></gmap-marker>
  </gmap-map>
</template>

<script>
export default {
  props: ['pos'],
  data () {
    return {
      mapOptions: {
        scrollwheel: true,
        mapTypeControl: true,
        streetViewControl: false,
        minZoom: 0,
        maxZoom: 100,
        gestureHandling: 'greedy'
      },
      center: {
        lat: Number(this.pos.lat), lng: Number(this.pos.lng)
      }
    }
  },
  methods: {
    handleDrag (e) {
      const lat = e.latLng.lat()
      const lng = e.latLng.lng()
      this.$emit('handleChangePosition', { lat, lng })
    }
  }
}
</script>
