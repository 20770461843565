<template>
    <div class="login-container">
        <h3>Нууц үг сэргээх</h3>
        <el-alert title="Хэрэглэгчийн нэр эсвэл нууц үг буруу байна!" v-if="error" type="error"></el-alert>
        <el-form ref="forgotForm" :model="forgotForm" :rules="rules" v-on:submit.prevent="onSubmit">
            <el-form-item prop="confirmation" label="Баталгаажуулах код" >
                <el-input v-model="forgotForm.confirmation"></el-input>
            </el-form-item>
            <el-form-item prop="password" label="Нууц үг" >
                <el-input type="password" v-model="forgotForm.password"></el-input>
            </el-form-item>
            <el-form-item prop="repassword" label="Нууц үг давтах" >
                <el-input type="password" v-model="forgotForm.repassword"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button v-loading="load" :disabled="load" type="primary" @click="submit()" @keyup.enter="submit()">Илгээх</el-button>
            </el-form-item>
            <el-form-item>
                <el-button :disabled="load" @click="$router.push('/signIn')" type="warning">Нэвтрэх</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
import { forgotPassword } from '@/helpers/auth.js'
export default {
  name: 'Reset',
  props: ['email'],
  data () {
    var validatePass = (rule, value, callback) => {
      console.log('validate1', value)
      if (value === '') {
        callback(new Error('Please input the password'))
      } else {
        if (this.forgotForm.password !== '') {
          this.$refs.forgotForm.validateField('repassword')
        }
        callback()
      }
    }
    var ValidateRePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Нууц үг давтах утга хоосон байна!'))
      } else if (value !== this.forgotForm.password) {
        callback(new Error('Нууц үг, нууц үг давтахтай таарахгүй байна'))
      } else {
        callback()
      }
    }
    return {
      load: false,
      valid: false,
      error: false,
      passwordVisible: false,
      forgotForm: {
        confirmation: '',
        password: '',
        repassword: ''
      },
      rules: {
        confirmation: [
          { required: true, message: 'Confirmation code is required', trigger: 'blur' }
        ],
        password: [
          { validator: validatePass, trigger: 'blur' }
        ],
        repassword: [
          { validator: ValidateRePass, trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    submit () {
      this.$refs.forgotForm.validate(valid => {
        if (valid) {
          if (this.email === '') {
            this.$notify.error({
              title: 'Алдаа гарлаа',
              message: 'Имэйл хаягын утга хоосон байна!'
            })
          } else {
            forgotPassword(this.email, this.forgotForm.confirmation, this.forgotForm.password).then(response => {
              if (response.status === 200) {
                this.$notify.success({
                  title: 'Амжилттай',
                  message: `Таны нууц үг амжилттай солигдлоо`
                })
              } else if (response.status === 400 && response.statusText === 'error') {
                this.$notify.error({
                  title: 'Алдаа гарлаа',
                  message: `Та имэйл, кодоо шалгаад дахин илгээнэ үү! Алдаа: ${response.error}`
                })
              }
            })
            this.$router.push({ path: '/signIn' })
          }
        } else {
          console.log('Form is invalid', valid)
          this.$notify.error({
            title: 'Алдаа гарлаа',
            message: 'Илгээхэд алдаа гарлаа та хэсэг хугацааны дараа дахин оролдоно уу'
          })
        }
      })
    }
  }
}
</script>
