import { mainRequests } from '@/helpers/custom'
export default {
  // #region Order
  getFoodOrdersLoc (outlets, startDate, endDate) {
    return new Promise(function (resolve, reject) {
      let body = {
        outlets: outlets,
        start_date: startDate,
        end_date: endDate
      }
      mainRequests.postOrder('/orders/getFoodOrdersLoc', body).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  getFoodOrderTimeRange (outlets, min, max, limit, orderType, startDate, endDate, channel) {
    return new Promise(function (resolve, reject) {
      let body = {
        outlets: outlets,
        accepted_status_start: min,
        accepted_status_end: max,
        limit: limit,
        order_type: orderType,
        start_date: startDate,
        end_date: endDate,
        channel: channel
      }
      mainRequests.postOrder('/orders/getFoodOrdersTime', body).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  cancelOrder (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests.postOrder('/orders/cancelOrder', payload).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  getOrderChanges (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests.postOrder('/orders/getOrderChanges', payload).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  getOrderByUuid (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests.postOrder('/orders/getOrderByUuid', payload).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  // coupon region
  getCoupon (startDate, endDate) {
    return new Promise(function (resolve, reject) {
      let body = {
        startDate: startDate,
        endDate: endDate
      }
      mainRequests.postOrder('/coupon/getCoupon', body).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  generateCoupon (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests.postOrder('/coupon/generateCoupon', payload).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  getDashboard () {
    return new Promise(function (resolve, reject) {
      mainRequests.postOrder('/coupon/getDashboard').then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  getCouponProducts () {
    return new Promise(function (resolve, reject) {
      mainRequests.postOrder('/coupon/getCouponProducts').then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  createCouponProduct (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests.postOrder('/coupon/createCouponProduct', payload).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  deleteCouponProduct (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests.postOrder('/coupon/deleteCouponProduct', payload).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  }
  // #ends
}
