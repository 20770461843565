<template>
<div>
  <el-row style="padding:10px">
    <el-col :span="4">
    <el-select v-model="outletname" placeholder="Салбар" :clearable="true">
    <el-option v-for="(item,index) in outletDataSource" :key="index" :label="item.outletname" :value="item.outletname"></el-option>
    </el-select>
    </el-col>
    <el-col :span="5">
      <el-date-picker v-model="startDate" type="datetime" placeholder="Эхлэх өдөр" >
      </el-date-picker>
    </el-col>
    <el-col :span="5">
      <el-date-picker v-model="endDate" type="datetime" placeholder="Дуусах өдөр" >
      </el-date-picker>
    </el-col>
    <el-col :span="4">
    <el-button type="primary" @click="filterData" icon="el-icon-edit" :disabled="outletname.length===0">Хайх</el-button>
    </el-col>
  </el-row>
  <el-row style="padding:20px 10px">
        <el-table :data="data" style ="width: 100%" v-loading="loading" element-loading-background="rgba(0, 0, 0, 0.3)" border stripe>
          <el-table-column fixed width="160" label="Салбар" prop="outletname" :sortable="true"/>
          <el-table-column fixed width="160" label="Хаасан цаг" prop="createdAt" :sortable="true"/>
          <el-table-column fixed width="160" label="Үргэлжлэх" prop="duration" :sortable="true"/>
          <el-table-column fixed width="160" label="Хаалт дуусах цаг" prop="suspendedAt" :sortable="true"/>
          <el-table-column label="Захиалга хаасан" width="150" prop="employee"></el-table-column>
        </el-table>
  </el-row>
</div>
</template>

<script>
import managerServices from '@/services/managerServices.js'
import dateFormat from 'dateformat'

export default {
  data: function () {
    return {
      loading: false,
      data: [],
      outletname: '',
      outletDataSource: [],
      startDate: '',
      endDate: '',
    }
  },
  mounted () {},
  created () {
    managerServices.getOutlets().then((res) => {
      if (res.statusCode === 200) {
        this.outletDataSource = res.result  
      }
      let hours = (new Date()).getHours()
      this.startDate = new Date(new Date((new Date()).getFullYear(), (new Date()).getMonth(), (new Date()).getDate() - 1, 0, 0, 0).setHours(8))
      this.endDate = new Date(new Date((new Date()).getFullYear(), (new Date()).getMonth(), (new Date()).getDate(), 0, 59, 59).setHours(hours))
    })
    // this.getData()
  },
  computed: {
    dayList () {
      return Object.keys(this.filter.days).map(key => ({ value: key, label: this.filter.days[key] }))
    }
  },
  methods: {
    filterData () {
      if (this.outletname && this.startDate && this.endDate) {
        this.loading = true

        let start = new Date(this.startDate.toString().split('GMT')[0] + ' UTC').toISOString().slice(0, 19).replace('T', ' ')
        let end = new Date(this.endDate.toString().split('GMT')[0] + ' UTC').toISOString().slice(0, 19).replace('T', ' ')
        const payload = {
          outletname: this.outletname,
          startDate: start,
          endDate: end
        }
        managerServices.getOutletSuspentions(payload).then((res) => {
          this.loading = false
          if (res.statusCode === 200) {
            console.log(res.result, 'resdata??');
            this.data = res.result
            // this.data = res.result.map(s => ({ ...s, weekdayname_mn: this.filter.days[s.weekdayname] }))
          } else {
            this.$notify({ type: 'warning', message: 'Мэдээлэл татхад алдаа гарлаа' })
          }
        })
      } else {
        this.$notify({
          title: 'Анхааруулга',
          message: 'Таны сонгосон өдрөөр салбарын түр зогсолтыг харуулах боломжгүй байна.',
          type: 'warning'
        })
      }
    },
  },
  watch: {
    'filter.outletname' (val) {
      this.getData()
    }
  }
}
</script>
