import { mainRequests } from '@/helpers/custom'
export default {
  // #region Cars
  getCarsList (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests.getDriver('/cars/getCars', payload).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  addNewCar (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests.postDriver('/cars/addCar', payload).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  updateCar (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests.postDriver('/cars/updateCar', payload).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  logDisributedCar (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests.postDriver('/cars/disributedCar', payload).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  getActiveUnDistributionCar (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests.postDriver('/cars/getActiveUnDistributionCar', payload).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  getActiveDistributionCar (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests.postDriver('/cars/getActiveDistributionCar', payload).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  // #endregion
  // #region Drivers
  changeUser (body) {
    return new Promise(function (resolve, reject) {
      mainRequests.postDriver('/drivers/changeUser', body).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  unUsignedDriver (body) {
    return new Promise(function (resolve, reject) {
      mainRequests.postDriver('/drivers/unUsignedDriver', body).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  createUser (body) {
    return new Promise(function (resolve, reject) {
      mainRequests.postDriver('/drivers/createUser', body).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  getDriverNamesByOutlet (outlets) {
    return new Promise(function (resolve, reject) {
      let body = {
        outlets: outlets
      }
      mainRequests.postDriver('/drivers/getDriverNamesByOutlet', body).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  getDriversByOutlet (outlets, type, startDate, endDate) {
    return new Promise(function (resolve, reject) {
      let body = {
        outlets: outlets,
        type: type,
        start_date: startDate,
        end_date: endDate
      }
      mainRequests.postDriver('/drivers/getDriversByOutlet', body).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  getReadyDriversByOutlet (outlets, type, startDate, endDate) {
    return new Promise(function (resolve, reject) {
      let body = {
        outlets: outlets,
        type: type,
        start_date: startDate,
        end_date: endDate
      }
      mainRequests.postDriver('/drivers/getReadyDriversByOutlet', body).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  stopDriver (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests.postDriver('/drivers/stopDriver', payload).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  returnMoney (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests.postDriver('/drivers/returnMoney', payload).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  startDriver (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests.postDriver('/drivers/startDriver', payload).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  }
  // #endRegion
}
