<template>
  <div>
    <el-row :gutter="10">
      <div v-for="o in 9" :key="o">
        <el-col :span="4" v-for="(item, index) in reports" :key="index">
          <card v-if="o===parseInt(item.name[2])" :data="item"></card>
        </el-col>
        </div>
    </el-row>
  </div>
</template>
<script>
import Card from '@/components/card'
import reportServices from '@/services/reportServices.js'
export default {
  mounted () {
  },
  created () {
    this.conf.children[0].query.value[0][':START'] = `'` + (new Date((new Date()).toString().split('GMT')[0] + ' UTC').toISOString().slice(0, 10) + ' 08:00:00') + `'`
    this.conf.children[0].query.value[1][':END'] = `'` + (new Date((new Date(new Date((new Date()).getFullYear(), (new Date()).getMonth(), (new Date()).getDate() + 1, 0, 0, 0).setHours(8))).toString().split('GMT')[0] + ' UTC').toISOString().slice(0, 10) + ' 08:00:00') + `'`
    this.outlets.forEach(element => {
      this.conf.children[1].query.value[0][':VAL'] = `'` + element + `'`
      reportServices.getReportData(JSON.stringify(this.conf), this.id).then(data => {
        let temp = {}
        temp.data = data.data[0]
        temp.name = element
        this.reports.push(temp)
      })
    })
  },
  components: { Card },
  data: function () {
    return {
      outlets: ['PH9-KHANUUL', 'PH8-SANSAR', 'PH7-ZAISAN', 'PH6-KHARKHORIN', 'PH5-PEACE-AVENUE', 'PH4-UNESCO-STREET', 'PH3-OLYMPIC-STREET', 'PH2-CENTRAL-SQUARE', 'PH1-KHOROOLOL'],
      reports: [],
      id: 43,
      conf: {
        'children': [
          {
            'type': 'query-builder-rule',
            'query': {
              'rule': '21',
              'value': [
                {
                  ':START': "'2019-03-07'"
                },
                {
                  ':END': "'2019-03-08'"
                }
              ],
              'detailsId': [
                21
              ],
              'selectedOperand': 'Огноо',
              'selectedOperator': '='
            }
          },
          {
            'type': 'query-builder-rule',
            'query': {
              'rule': '23',
              'value': [
                {
                  ':VAL': "'PH1-KHOROOLOL'"
                }
              ],
              'detailsId': [
                23
              ],
              'selectedOperand': 'Салбар',
              'selectedOperator': '='
            }
          },
          {
            'type': 'query-builder-rule',
            'query': {
              'rule': '25',
              'value': [
                {
                  ':VAL': "'PH'"
                }
              ],
              'detailsId': [
                25
              ],
              'selectedOperand': 'Бренд',
              'selectedOperator': '='
            }
          }
        ],
        'logicalOperator': 'Any'
      }
    }
  },
  methods: {
  }
}
</script>
