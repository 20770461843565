<template>
  <div class="menuEdit" v-loading="cardLoading" element-loading-text="Сурталчилгааны мэдээлэл татаж байна..."
    element-loading-spinner="el-icon-loading">
    <div class="clearfix">
      <el-row>
        <el-col>
          <el-button type="success" size="mini" icon="el-icon-plus" @click="dialogFormVisible = true">Нэмэх</el-button>
        </el-col>
      </el-row> <br />
      <el-row :gutter="24">
        <el-col :span="24" :offset="0">
          <el-table size="mini" :data="bannerList" border="" style="width: 100%">
            <el-table-column prop="img_url" label="Зураг" width="130">
              <template slot-scope="scope">
                <el-image :src="cdn + scope.row.img_url" style="width: 100px;"></el-image>
              </template>
            </el-table-column>
            <el-table-column prop="redirect_url" label="Шилжих зам">
            </el-table-column>
            <el-table-column prop="is_active" label="Төлөв"
              :filters="[{ text: 'Харагдахгүй', value:0 }, { text: 'Харагдах', value:1 }]" :filter-method="filterTag"
              width="120px" filter-placement="bottom-end">
              <template slot-scope="scope">
                <el-tag :type="scope.row.is_active === 0 ? 'info' : 'success'" disable-transitions>
                  <b v-if="scope.row.is_active===0">Харагдахгүй</b>
                  <b v-else>Харагдана</b>
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column sortable prop="sort" label="Эрэмбэ" width="90px">
            </el-table-column>
            <el-table-column width="120px" label="Үйлдэл">
              <template slot-scope="scope">
                <el-button type="danger" icon="el-icon-edit" round @click="showEdit(scope.row)" size="mini">Засах
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
    </div>
    <!-- menus table end -->
    <!-- Form -->
    <el-dialog title="Суртчилгаа нэмэх" :visible.sync="dialogFormVisible" @close="closeProgress()" width="840px">
      <el-form :model="banner" ref="service" label-width="250px" class="demo-ruleForm">
        <el-form-item label="Сурталчилгааны төрөл" prop="type">
          <el-input style="width:250px;" size="mini" v-model="banner.type"
            placeholder="Сурталчилгааны төрөл оруулна уу">
          </el-input>
        </el-form-item>
        <el-form-item label="Сурталчилгааны гарчиг" prop="type">
          <el-input style="width:250px;" size="mini" v-model="banner.label"
            placeholder="Сурталчилгааны гарчиг оруулна уу">
          </el-input>
        </el-form-item>
        <el-form-item label="Сурталчилгааны дэлгэрэнгүй" prop="type">
          <el-input type="textarea" v-model="banner.description"></el-input>
        </el-form-item>
        <el-form-item label="Хугацаа текст" prop="type">
          <el-input style="width:250px;" size="mini" v-model="banner.time"
            placeholder="Хугацаа текст">
          </el-input>
        </el-form-item>
        <el-form-item label="Товчны текс" prop="type">
          <el-input style="width:250px;" size="mini" v-model="banner.button"
            placeholder="Товчны текст">
          </el-input>
        </el-form-item>
        <el-form-item label="Зураг" class="img-con">
          <el-button v-if="!editImage" type="primary" @click="handleEditImageButton" size="mini" icon="el-icon-edit"></el-button>
          <el-upload v-if="editImage" action="/" :auto-upload="false"
            :on-change="(e) => handleUpload(e)"
            list-type="picture-card"
            :file-list="[{ name: '', url: cdn + banner.img_url }]"
            ><i class="el-icon-plus"></i></el-upload>
          <el-image style="width:100%" v-else :src="cdn + banner.img_url">
            <template #placeholder>
              <div class="image-slot" style="text-align:center;">
                <img src="https://i.stack.imgur.com/h6viz.gif">
              </div>
            </template>
          </el-image>
          <el-input v-model="banner.img_url" disabled size="mini"></el-input>
        </el-form-item>
        <el-form-item label="Үсрэх зам" prop="redirect_url">
          <el-input style="width:250px;" size="mini" v-model="banner.redirect_url"
            placeholder="Үсрэх замын url оруулна уу">
          </el-input>
        </el-form-item>
        <el-form-item label="Үсрэх path" prop="redirect_path">
          <el-input style="width:250px;" size="mini" v-model="banner.redirect_path"
            placeholder="Redirect path оруулна уу"></el-input>
        </el-form-item>
        <el-form-item label="Зураг зам /mobile/" prop="img_url_mobile">
          <el-input style="width:250px;" size="mini" v-model="banner.img_url_mobile"
            placeholder="Суртчилгааны image url mobile оруулна уу"></el-input>
        </el-form-item>
        <el-form-item label="Сурталчилгааны төлөв" prop="is_active">
          <el-radio-group v-model="banner.is_active" size="mini">
            <div>
              <el-radio-button label="0">Нуух</el-radio-button>
              <el-radio-button label="1">Харах</el-radio-button>
            </div>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="Сурталчилгааны эрэмбэ">
          <template>
            <el-input-number style="width:100px;" v-model="banner.sort" controls-position="right"
              :min="1" size="mini">
            </el-input-number>
          </template>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="closeProgress()">Буцах</el-button>
        <el-button type="success" @click="save()">Нэмэх</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="'Сурталчилгааны дугаар : ' + bannerEditKfc.id + ' '" :visible.sync="dialogVisible"
      @close="closeProgressEdit()" width="55%" top="100px" class="process-dialog">
      <el-form :model="bannerEditKfc" ref="service" label-width="250px" class="demo-ruleForm">
        <el-form-item label="Сурталчилгааны төрөл" prop="type">
          <el-input style="width:250px;" size="mini" v-model="bannerEditKfc.type"
            placeholder="Сурталчилгааны төрөл оруулна уу"></el-input>
        </el-form-item>
        <el-form-item label="Сурталчилгааны гарчиг" prop="type">
          <el-input style="width:260px;" size="mini" v-model="bannerEditKfc.label"
            placeholder="Сурталчилгааны гарчиг оруулна уу">
          </el-input>
        </el-form-item>
        <el-form-item label="Сурталчилгааны дэлгэрэнгүй" prop="type">
          <el-input type="textarea" v-model="bannerEditKfc.description"></el-input>
        </el-form-item>
        <el-form-item label="Хугацаа текст" prop="type">
          <el-input style="width:250px;" size="mini" v-model="bannerEditKfc.time"
            placeholder="Хугацаа текст">
          </el-input>
        </el-form-item>
        <el-form-item label="Товчны текс" prop="type">
          <el-input style="width:250px;" size="mini" v-model="bannerEditKfc.button"
            placeholder="Товчны текст">
          </el-input>
        </el-form-item>
        <el-form-item label="Зураг" class="img-con">
          <el-button v-if="!editImage" type="primary" @click="handleEditImageButton" size="mini" icon="el-icon-edit"></el-button>
          <el-upload v-if="editImage" action="/" :auto-upload="false"
            :on-change="(e) => handleUploadEditKfc(e)"
            list-type="picture-card"
            :file-list="[{ name: '', url: cdn + bannerEditKfc.img_url }]"
            ><i class="el-icon-plus"></i></el-upload>
          <el-image style="width:100%" v-else :src="cdn + bannerEditKfc.img_url">
            <template #placeholder>
              <div class="image-slot" style="text-align:center;">
                <img src="https://i.stack.imgur.com/h6viz.gif">
              </div>
            </template>
          </el-image>
          <el-input v-model="bannerEditKfc.img_url" disabled size="mini"></el-input>
        </el-form-item>
        <el-form-item label="Үсрэх холбоос" prop="redirect_url">
          <el-input style="width:250px;" size="mini" v-model="bannerEditKfc.redirect_url"
            placeholder="Redirect url оруулна уу"></el-input>
        </el-form-item>
        <el-form-item label="Үсрэх path" prop="redirect_url">
          <el-input style="width:250px;" size="mini" v-model="bannerEditKfc.redirect_path"
            placeholder="Redirect path оруулна уу"></el-input>
        </el-form-item>
        <el-form-item label="Зураг холбоос /мобайл/" prop="img_url_mobile">
          <el-input style="width:250px;" size="mini" v-model="bannerEditKfc.img_url_mobile"
            placeholder="Сурталчилгааны image url mobile оруулна уу"></el-input>
        </el-form-item>
        <el-form-item label="Сурталчилгааны төлөв" prop="is_active">
          <el-radio-group v-model="bannerEditKfc.is_active" size="mini">
            <div>
              <el-radio-button label="0">Нуух</el-radio-button>
              <el-radio-button label="1">Харах</el-radio-button>
            </div>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="Сурталчилгааны эрэмбэ">
          <template>
            <el-input-number style="width:250px;" v-model="bannerEditKfc.sort" controls-position="right"
              :min="1" size="mini">
            </el-input-number>
          </template>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">Буцах</el-button>
        <el-button type="success" @click="update()">Засварлах</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import managerServices from '@/services/managerServices.js'
export default {
  components: {
  },
  data () {
    return {
      cdn: 'https://397isne8v4.execute-api.ap-southeast-1.amazonaws.com/prod/resize',
      dialogFormVisible: false,
      bannerList: [],
      cardLoading: true,
      loadUpload: false,
      imageNull: 'none',
      bannerDetail: {},
      banner: {
        img_url: '',
        label: '',
        description: '',
        time: '',
        button: '',
        redirect_url: '',
        type: '',
        img_url_mobile: '',
        is_active: 0,
        sort: 0
      },
      bannerEditKfc: {},
      dialogVisible: false,
      editImage: false
    }
  },
  created () {
    this.cardLoading = true
    this.getBannerList()
  },
  methods: {
    getBannerList () {
      managerServices.getBanner()
        .then(data => {
          this.bannerList = data.result
          this.cardLoading = false
        })
    },
    showEdit (data) {
      this.bannerEditKfc = data
      this.dialogVisible = true
    },
    filterTag (value, row) {
      return row.is_active === value
    },
    onChangeImage (file) {
      this.loadUpload = true
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        this.$message.error('Зурагны хэмжээ ихдээ 2MB байх ёстой!')
        this.imageNull = 'none'
        this.loadUpload = false
      } else {
        if (!file.name) {
          this.$message.error('Зурагны aнгли нэрийг заавал оруулах ёстой!')
          this.imageNull = 'none'
          this.loadUpload = false
        }
      }
      return isLt2M
    },
    closeProgress () {
      this.banner = {}
      this.getBannerList()
      this.imageNull = ''
      this.dialogFormVisible = false
    },
    closeProgressEdit () {
      this.bannerEditKfc = {}
      this.getBannerList()
      this.dialogVisible = false
    },
    save () {
      managerServices.createBanner(this.banner)
        .then(response => {
          if (response.statusCode === 200 && response.success) {
            this.$notify({
              title: 'Амжилттай',
              message: 'Сурталчилгаа нэмэгдлээ',
              type: 'success'
            })
            this.closeProgress()
          } else if (response.statusCode === 'failed') {
            console.log('nemehed_garsan_aldaa' + response.err)
            const error = response.err.map(err => err.message)
            this.$notify({
              title: 'Амжилтгүй',
              message: 'Сурталчилгаа нэмэхэд алдаа гарлаа' +
                        ' ' + error,
              type: 'warning'
            })
          }
        })
    },
    update () {
      managerServices.editBanner(this.bannerEditKfc)
        .then(response => {
          if (response.statusCode === 200 && response.success) {
            this.$notify({
              title: 'Амжилттай',
              message: 'Сурталчилгаа засварлагдаа',
              type: 'success'
            })
            this.closeProgressEdit()
          } else if (!response.data.type) {
            console.log('nemehed_garsan_aldaa' + response.data.err)
            this.$notify({
              title: 'Амжилтгүй',
              message: 'Сурталчилгаа засварлахад алдаа гарлаа' +
                        ' ' +
                        response.data.err,
              type: 'warning'
            })
          }
        })
    },
    async handleUpload (e) {
      this.submitLoading = true
      const res = await this.fetchImages([e], 'kfc>banner')
      this.banner.img_url = res[0]
      this.editImage = false
      this.submitLoading = false
    },
    async handleUploadEditKfc (e) {
      this.submitLoading = true
      const res = await this.fetchImages([e], 'kfc>banner')
      this.bannerEditKfc.img_url = res[0]
      this.editImage = false
      this.submitLoading = false
    },
    fetchImages (imgArr, dir) {
      return new Promise((resolve, reject) => {
        const urlsToReturn = []
        Promise.all(imgArr.map(async img => {
          const here = await this.uploadToServer(img, dir)
          urlsToReturn.push(here)
        })).then(() => {
          resolve(urlsToReturn)
        })
      })
    },
    uploadToServer (img, dir) {
      return new Promise((resolve, reject) => {
        toBase64(img.raw).then((result64) => {
          fetch('https://397isne8v4.execute-api.ap-southeast-1.amazonaws.com/prod/upload?' + 'dir=' + dir, {
            method: 'POST',
            headers: {
              'Content-Type': 'text/plain'
            },
            body: result64
          }).then(response => response.json()).then(data => {
            resolve(data.param)
          })
        })
      })
    },
    handleEditImageButton () {
      this.editImage = true
    }
  }
}
const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = () => {
    const baseString = reader.result
    const hook = ';base64,'
    const hookIndex = baseString.indexOf(hook)
    const clearedString = baseString.slice(hookIndex + hook.length, baseString.length)
    resolve(clearedString)
  }
  reader.onerror = error => reject(error)
})
</script>
