<template>
  <div class="stats driveradddialog">
    <el-dialog v-if="dialogVisible" title="Жолооч нэмэх" :visible.sync="dialogVisible" width="500px" :before-close="handleClose">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">
        <el-form-item label="Жолоочийн нэр" prop="name">
          <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item label="Регистрийн дугаар" prop="registrationNo">
          <el-input v-model="ruleForm.registrationNo" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="Имэйл" prop="email">
          <el-input v-model="ruleForm.email"></el-input>
        </el-form-item>
        <el-form-item label="Хүргэлтийн баг" prop="deliveryteam">
          <el-select v-model="ruleForm.deliveryteam" placeholder="Хүргэлтийн баг" style="width:100%" @change="changeTeam">
            <el-option v-for="(item, index) in teams" :key="index" :value="item.teamId" :label="item.team_name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Нууц үг" prop="pass">
          <el-input type="password" v-model="ruleForm.pass" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button type="info" @click="handleClose">Хаах</el-button>
        <el-button type="success" @click="submitForm('ruleForm')">Нэмэх</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import driverServices from '@/services/driverServices.js'
export default {
  created () {
  },
  data () {
    return {
      teams: [],
      dialogVisible: false,
      ruleForm: {
        name: '',
        registrationNo: '',
        email: '',
        deliveryteam: '',
        outlet: '',
        pass: ''
      },
      rules: {
        name: [
          { required: true, message: 'Та нэрээ оруулна уу', trigger: 'blur' }
        ],
        email: [
          { required: true, message: 'Та имэйлээ оруулна уу', trigger: 'blur' },
          { type: 'email', message: 'Буруу имэйл оруулсан байна.', trigger: ['blur', 'change'] }
        ],
        pass: [
          { required: true, message: 'Та нууц үгээ оруулна уу', trigger: 'blur' }
        ],
        registrationNo: [
          { required: true, message: 'Та регисртийн дугаар оруулна уу', trigger: 'blur' }
        ],
        deliveryteam: [
          { required: true, message: 'Та хүргэлтийн багаа заавал сонгоно уу', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    handleClose () {
      this.dialogVisible = false
    },
    changeTeam () {
      let self = this
      this.teams.forEach(element => {
        if (element.teamId === self.ruleForm.deliveryteam) { self.ruleForm.outlet = element.outletId }
      })
    },
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          driverServices.createDriver(this.ruleForm).then(data => {
            if (data === 'ok') {
              this.$notify({
                title: 'Амжилттай',
                message: 'Жолоочийг нэмлээ',
                type: 'success'
              })
            } else if (data === 'already') {
              this.$notify({
                title: 'Анхааруулга',
                message: 'Жолооч давхардсан байна.',
                type: 'warning'
              })
            } else {
              this.$notify.error({
                title: 'Алдаа',
                message: 'Алдаа гарлаа'
              })
            }
          }).catch(err => {
            this.$notify.error({
              title: 'Алдаа',
              message: 'алдаа: ' + err
            })
          })
        } else {
          this.$notify({
            title: 'Анхааруулга',
            message: 'Зарим зүйлийг дутуу бөглөсөн байна.',
            type: 'warning'
          })
        }
      })
    },
    resetForm (formName) {
      this.$refs[formName].resetFields()
    }
  }
}
</script>
<style lang="scss">
  .payment-title {
    float: left;
    width: 70px;
    margin-right: 15px;
  }
  .driveradddialog {
    span.el-dialog__title {
      color: #fff;
      background: #3a8ee6;
      padding: 5px 17px;
      border-radius: 3px;
    }
  }
</style>
