<template>
    <div class="login-container">
        <h3>Нэвтрэх хэсэг</h3>
        <el-alert title="Хэрэглэгчийн нэр эсвэл нууц үг буруу байна!" v-if="error" type="error"></el-alert>
        <el-form v-on:submit.prevent="onSubmit">
            <el-form-item prop="email" label="И-мэйл" >
                <el-input v-model="user.email"></el-input>
            </el-form-item>
            <el-form-item prop="pass" label="Нууц үг" >
                <el-input v-model="user.pass" type="password" @keyup.enter.native="onSubmit()"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button v-loading="load" :disabled="load" type="primary" @click="onSubmit()" @keyup.enter="onSubmit()">Нэвтрэх</el-button>
            </el-form-item>
            <p class="recover-pass">
              <a @click="$router.push('reset')" class="recover">
                <span>Нууц үг сэргээх</span>
              </a>
            </p>
        </el-form>
    </div>
</template>
<script>
import { signIn } from '@/utils/auth.js'
export default {
  created () {
    if (this.login === true) {
      this.$router.push('/main')
      this.loading = true
    }
  },
  data () {
    return {
      user: {
        email: '',
        password: ''
      },
      load: false,
      error: false,
      loading: false,
      year: null
    }
  },
  methods: {
    onSubmit () {
      this.loading = false
      this.load = true
      signIn(this.user.email, this.user.pass)
      // var payload = {
      //   email: this.user.email,
      //   password: this.user.pass
      // }
      // this.$store.dispatch('authentication/login', payload)
    }
  }
}
</script>
<style scoped>
.md-field label {
    font-size: 16px;
}
.md-field.md-focused label, .md-field.md-has-value label {
    font-size: .8875rem !important;
}
</style>
