<template>
    <el-dialog :title="outlet.name_mn+' салбарын мэдээлэл засах'" :visible.sync="dialogVisible" width="840px">
          <el-form ref="form" :model="outlet" label-width="140px" :rules="rules">
            <el-row>
            <el-col :span="12">
            <el-form-item label="Салбар:">
              <el-input  v-model="outlet.outletname" :readonly="true"></el-input>
            </el-form-item>
            </el-col>
            <el-col :span="12">
            <el-form-item label="Салбарын нэр:">
              <el-input  v-model="outlet.name_mn" :readonly="true"></el-input>
            </el-form-item>
            </el-col>
            <el-form-item label="Хаяг:" prop="address">
              <el-input type="textarea" v-model="outlet.address"></el-input>
            </el-form-item>
            <el-col :span="12">
            <el-form-item label="Утас:" prop="phone" style="padding-bottom:10px">
              <el-input v-model="outlet.phone"></el-input>
            </el-form-item>
            </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="Төлөв:">
                  <el-switch v-model="outlet.status" active-value="1" inactive-value="0"></el-switch>
                </el-form-item>
              </el-col>
            </el-row>
            <Title title="Тохиргоо"></Title>
            <el-row>
              <el-col :span="12">
                <el-form-item label="Хүргэлттэй эсэх:">
                  <el-switch v-model="outlet.have_delivery" :active-value="1" :inactive-value="0"></el-switch>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Очиж авах эсэх:">
                  <el-switch v-model="outlet.have_pickup" :active-value="1" :inactive-value="0"></el-switch>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="Урьдчилсан захиала авах:" label-width="200px">
                  <el-switch v-model="outlet.have_advanced" :active-value="1" :inactive-value="0"></el-switch>
                </el-form-item>
              </el-col>
               <el-col :span="12">
                <el-form-item label="Вэб дээр харагдах:">
                  <el-input-number v-model="outlet.sortnumber" :step="100"></el-input-number>
                </el-form-item>
              </el-col>
            </el-row>
          <Title title="Захиалга шилжүүлэх"></Title>
            <el-row style="padding-bottom:8px">
              <el-col :span="8">
                <el-form-item label="Захиалга шилжүүлэх:" label-width="180px" >
                  <el-switch v-model="outlet.is_redirect" :active-value="1" :inactive-value="0"></el-switch>
                </el-form-item>
              </el-col>
              <el-col :span="16">
                <el-form-item label="Шилжүүлэх салбар:" label-width="180px" prop="redirect_outletname">
                  <el-select v-model="outlet.redirect_outletname" :clearable="true">
                    <el-option v-for="(item,index) in redirectOutlets" :label="item.outletname" :key="index" :value="item.outletname"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row style="padding:4px">
              <el-col :span="8">
                <el-form-item label="Захиалга шилжүүлэх:" prop="redirect_starttime" label-width="90px">
                  <el-time-picker style="width:140px !important" v-model="outlet.redirect_starttime"></el-time-picker>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="-" label-width="30px" prop="redirect_endtime">
                  <el-time-picker v-model="outlet.redirect_endtime"></el-time-picker>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="Дараа өдөрт орох" prop="redirect_shift">
                  <el-switch style="width:100px" v-model="outlet.redirect_shift" :active-value="1" :inactive-value="0"></el-switch>
                </el-form-item>
              </el-col>
            </el-row>
          <Title title="Үнэ"></Title>
            <el-row style="padding-bottom:10px;">
                <el-col :span="11">
                  <el-form-item label="Хүргэлтийн дүн:" prop="delivery_charge">
                    <el-input-number v-model="outlet.delivery_charge" :step="1000"></el-input-number>
                  </el-form-item>
                </el-col>
                <el-col :span="13">
                  <el-form-item label="Захиалгын хамгийн бага дүн:" prop="minimum_order_amount" label-width="200px">
                    <el-input-number  v-model="outlet.minimum_order_amount" :step="1000"></el-input-number>
                  </el-form-item>
                </el-col>
            </el-row>
            <el-form-item label="Хүргэлт үнэгүй хийгдэх /онлайн/:" prop="free_delivery_online" label-width="260px" style="padding-bottom:10px;">
              <el-input-number v-model="outlet.free_delivery_online" :step="1000"></el-input-number>
            </el-form-item>
            <el-form-item label="Хүргэлт үнэгүй хийгдэх /дуудлагын төв/:" prop="free_delivery_cc" label-width="260px" style="padding-bottom:10px;">
              <el-input-number  v-model="outlet.free_delivery_cc" :step="1000"></el-input-number>
            </el-form-item>
            <Title title="Салбарын хаах, нээх цаг /Вэб/"></Title>
            <el-row style="padding:4px">
                <el-col :span="12">
                  <el-form-item label="R-Кeeper ID:" prop="storeId" label-width="160px">
                    <el-input placeholder="R-Keeper ID оруулна уу" v-model="outlet.storeId" :step="1000"></el-input>
                  </el-form-item>
                </el-col>
            </el-row>
            <el-row style="padding:4px">
              <el-col :span="10">
                <el-form-item label="Нээх цаг:" prop="start_time" label-width="160px">
                  <el-time-picker v-model="outlet.start_time"></el-time-picker>
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item label="-" label-width="30px" prop="end_time">
                  <el-time-picker v-model="outlet.end_time"></el-time-picker>
                </el-form-item>
              </el-col>
            </el-row>
             <el-row style="padding:4px">
                <el-col :span="12">
                  <el-form-item label="Google map url:" prop="google_map_url" label-width="160px">
                    <el-input placeholder="Google map url оруулна уу" v-model="outlet.google_map_url" :step="1000"></el-input>
                  </el-form-item>
                </el-col>
            </el-row>
          </el-form>
          <el-row style="margin-top:20px;">
              <el-col :span="12">
                <el-button type="primary" @click="hide">Буцах</el-button>
              </el-col>
              <el-col :span="12" style="display:flex;justify-content:end;">
                <el-button type="success" @click="onSubmit" :loading="load">Хадгалах</el-button>
              </el-col>
          </el-row>
      </el-dialog>
</template>
<script>

import managerServices from '@/services/managerServices.js'
import { getUserName } from '@/utils/auth.js'
import Title from './title.vue'
import dateFormat from 'dateformat'

export default {
  components: { Title },
  props: ['getData'],
  created () {
    let self = this
    this.$bus.$on('dialogShowOutlet', function (row) {
      self.show(row)
    })
    managerServices.getOutlets().then((res) => {
      if (res.statusCode === 200) {
        this.redirectOutlets = res.result
      }
    })
    getUserName().then(data => {
      this.user = data.username.split('@')[0]
    })
  },
  data () {
    return {
      dialogVisible: false,
      load: false,
      user: '',
      outlet: {},
      redirectOutlets: [],
      operationTypes: [{ value: 'main', label: 'Үндсэн' }, { value: 'express', label: 'Экспрэсс' }]
    }
  },
  computed: {
    showIsRedirect () {
      return this.outlet.is_redirect === 1
    },
    rules () {
      let result = {
        address: [{ required: true, message: 'Хаягаа оруулна уу', trigger: 'change' }],
        phone: [{ required: true, message: 'Утасны дугаар оруулна уу', trigger: 'change' }],
        delivery_charge: [{ required: true, message: 'Дүнгээ оруулна уу', trigger: 'change' }],
        minimum_order_amount: [{ required: true, message: 'Дүнгээ оруулна уу', trigger: 'change' }],
        free_delivery_online: [{ required: true, message: 'Дүнгээ оруулна уу', trigger: 'change' }],
        free_delivery_cc: [{ required: true, message: 'Дүнгээ оруулна уу', trigger: 'change' }],
        storeId: [{ required: true, message: 'R-Keeper ID оруулна уу', trigger: 'change' }],
        start_time: [{ required: true, message: 'Эхлэх цаг оруулна уу', trigger: 'change' }],
        end_time: [{ required: true, message: 'Дуусах цаг оруулна уу', trigger: 'change' }],
        google_map_url: [{ required: true, message: 'Google map ID оруулна уу', trigger: 'change' }],
        web_show: [{ required: true, message: 'Вэб дээр харуулах уу', trigger: 'change' }]
      }
      if (this.outlet.is_redirect === 1) {
        result = { ...result,
          redirect_outletname: [{ required: true, message: 'Шилжүүлэх салбараа сонгоно уу', trigger: 'change' }],
          redirect_starttime: [{ required: true, message: 'Эхлэх цагаа оруулна уу', trigger: 'change' }],
          redirect_endtime: [{ required: true, message: 'Дуусах цагаа оруулна уу', trigger: 'change' }]
        }
      }
      return result
    }
  },
  methods: {
    show (row) {
      this.dialogVisible = true
      this.outlet = row
    },
    hide () {
      this.dialogVisible = false
    },
    onSubmit () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$confirm('Өөрчлөлт хийхдээ итгэлтэй байна уу ?', 'Анхаарууллага', {
            confirmButtonText: 'Тийм',
            cancelButtonText: 'Үгүй',
            type: 'warning'
          }).then(() => {
            const payload = {
              user: this.user,
              address: this.outlet.address,
              phone: this.outlet.phone,
              outletname: this.outlet.outletname,
              have_advanced: this.outlet.have_advanced,
              have_delivery: this.outlet.have_delivery,
              have_pickup: this.outlet.have_pickup,
              status: this.outlet.status,
              is_redirect: this.outlet.is_redirect,
              redirect_starttime: this.outlet.redirect_starttime ? dateFormat(this.outlet.redirect_starttime, 'HH:MM:ss') : '',
              redirect_endtime: this.outlet.redirect_endtime ? dateFormat(this.outlet.redirect_endtime, 'HH:MM:ss') : '',
              redirect_outletname: this.outlet.redirect_outletname,
              sortnumber: this.outlet.sortnumber,
              redirect_shift: this.outlet.redirect_shift,
              delivery_charge: this.outlet.delivery_charge,
              minimum_order_amount: this.outlet.minimum_order_amount,
              free_delivery_online: this.outlet.free_delivery_online,
              free_delivery_cc: this.outlet.free_delivery_cc,
              storeId: this.outlet.storeId,
              start_time: this.outlet.start_time ? dateFormat(this.outlet.start_time, 'HH:MM') : '',
              end_time: this.outlet.end_time ? dateFormat(this.outlet.end_time, 'HH:MM') : '',
              google_map_url: this.outlet.google_map_url
            }
            this.load = true
            managerServices.saveOutletConfig(payload).then((res) => {
              this.load = false
              if (res.statusCode === 400) {
                if (res.errorType === 'validation') {
                  const errorMessage = Object.keys(res.errors).map(key => res.errors[key][0]).join(', ')
                  this.$message({
                    type: 'warning',
                    message: errorMessage
                  })
                } else {
                  this.$message({
                    type: 'warning',
                    message: res.message
                  })
                }
              } else if (res.statusCode === 200) {
                this.$message({
                  type: 'success',
                  message: res.message
                })
                this.getData()
                this.hide()
              }
            }).catch(() => {
              this.load = false
              this.$message({
                type: 'error',
                message: 'Тохиргоо хадгалах явцад алдаа гарлаа'
              })
            })
          })
        } else {
          this.$message({
            type: 'warning',
            message: 'Хадгалах мэдээлэл алдаатай байна !!!'
          })
        }
      })
    }
  }
}
</script>
