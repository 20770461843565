<template>
  <div class="content">
    <el-row :gutter="10">
      <el-col :xs="24" :sm="24" :md="12" :lg="9" :xl="8">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span class="titleAcc">Хувийн тохиргоо</span>
          </div>
          <el-form ref="form" :model="form" label-width="120px">
            <el-form-item label="Зураг">
              <img src="../../public/img/profile.jpg" alt="" width="40%" style="border-radius:100%;">
            </el-form-item>
            <el-form-item label="Зураг оруулах">
              <el-upload class="upload-demo">
                <el-button size="small" type="primary">Click to upload</el-button>
                <div slot="tip" class="el-upload__tip">500kb -аас бага хэмжээтэй png, jpg өргөтгөлтэй зураг оруулна уу</div>
              </el-upload>
            </el-form-item>
            <el-form-item label="Таны овог">
              <el-input v-model="form.lastname"></el-input>
            </el-form-item>
            <el-form-item label="Таны нэр">
              <el-input v-model="form.firstname"></el-input>
            </el-form-item>
            <el-form-item label="Төрсөн аймаг, хот">
              <el-select v-model="form.region" placeholder="аймаг, хот">
                <el-option label="Улаанбаатар" value="Улаанбаатар"></el-option>
                <el-option label="Дархан" value="Дархан"></el-option>
                <el-option label="Эрдэнэт" value="Эрдэнэт"></el-option>
                <el-option label="Завхан" value="Завхан"></el-option>
                <el-option label="Өвөрхангай" value="Өвөрхангай"></el-option>
                <el-option label="Говь-Алтай" value="Говь-Алтай"></el-option>
                <el-option label="Өмнөговь" value="Өмнөговь"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Төрсөн өдөр">
              <el-col :span="24">
                <el-date-picker type="date" placeholder="Сонгох" v-model="form.date1" style="width: 100%;"></el-date-picker>
              </el-col>
            </el-form-item>
            <el-form-item label="Бүсийн менежер">
              <el-switch v-model="form.delivery"></el-switch>
            </el-form-item>
            <el-form-item label="Товч танилцуулга">
              <el-input type="textarea" v-model="form.desc"></el-input>
            </el-form-item>
            <el-form-item style="margin: 30px 0 0px 0;">
              <el-button type="primary" @click="onSubmit">Хадгалах</el-button>
              <el-button>Цуцлах</el-button>
            </el-form-item>
          </el-form>
        </el-card>
      </el-col>
      <el-col :xs="24" :sm="24" :md="12" :lg="9" :xl="8">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span class="titleAcc">График</span>
          </div>
          <el-form ref="chart" :model="chart" label-width="120px">
            <el-form-item label="Сонголт">
              <el-select v-model="chart.sales" placeholder="Статус сонгох" style="width: 80%;">
                <el-option label="Борлуулалт" value="PieChart"></el-option>
                <el-option label="KPI" value="BarChart"></el-option>
                <el-option label="Санал Гомдол" value="Complain"></el-option>
                <el-option label="Захиалга" value="Order"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Төрөл">
              <el-select v-model="chart.category" placeholder="График сонгох" style="width: 80%;">
                <el-option label="Pie Chart" value="PieChart"></el-option>
                <el-option label="Bar Chart" value="BarChart"></el-option>
                <el-option label="Doughnut" value="Doughnut"></el-option>
                <el-option label="Line Chart" value="LineChart"></el-option>
              </el-select>
            </el-form-item>
            <div class="chartItem">
              <bar-chart :chart-data="totalToday1"></bar-chart>
            </div>
            <el-form-item style="margin: 30px 0 0px 0;">
              <el-button type="primary" @click="onSubmit">Хадгалах</el-button>
              <el-button>Цуцлах</el-button>
            </el-form-item>
          </el-form>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import BarChart from '../charts/bar.js'
export default {
  components: {
    BarChart
  },
  data () {
    return {
      form: {
        lastname: '',
        firstname: '',
        region: '',
        date1: '',
        delivery: false,
        type: [],
        resource: '',
        desc: ''
      },
      chart: {
        sales: '',
        kpi: '',
        complain: '',
        order: ''
      },
      totalToday1: {
        labels: ['Хүргэлт', 'Ирж авах', 'Ресторан'],
        datasets: [{
          label: '',
          backgroundColor: [
            '#c6a700',
            '#373737',
            '#c67100'
          ],
          data: [40, 45, 48]
        }]
      }
    }
  },
  methods: {
    onSubmit () {
      console.log('submit!')
    }
  }
}
</script>
<style>
  span.titleAcc {
    font-size: 20px;
    font-weight: bold;
  }

  .chartItem {
    width: 70%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
</style>
