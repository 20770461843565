<template>
  <div>
    <el-table :data="products" size="mini" border stripe height="100vh">
        <el-table-column label="#" prop="id" sortable>
        </el-table-column>
        <el-table-column label="Бүтээгдэхүүн" prop="product.name_mon" sortable>
        </el-table-column>
        <el-table-column label="Дэлгэрэнгүй" prop="product.desc_mon" sortable>
        </el-table-column>
        <el-table-column label="Үйлдэл">
          <template slot-scope="scope">
            <el-button type="danger" icon="el-icon-delete" round @click="removeProduct(scope.row)" size="mini">Хасах
            </el-button>
          </template>
        </el-table-column>
    </el-table>
  </div>
</template>
<script>
import couponService from '@/services/orderServices'
export default {
  props: ['products'],
  data () {
    return {}
  },
  methods: {
    removeProduct (item) {
      couponService.deleteCouponProduct({ id: item.id }).then(response => {
        if (response.statusCode === 200) {
          this.$notify({
            title: 'Купоны жагсаалтаас бүтээгдэхүүнийг амжилттай хаслаа',
            message: response.message,
            type: 'success'
          })
          this.$bus.emit('reload')
        }
      })
    }
  }
}
</script>
