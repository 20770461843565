<template>
<div>
  <el-dialog title="Салбарын мэдээлэл засах" :visible.sync="visible" :before-close="handleHide">
    <el-form v-loading="submitLoading" label-position="top">
      <el-form-item label="Нэр (Монгол)">
        <el-input v-model="formData.name_mn"></el-input>
      </el-form-item>
      <el-form-item label="Нэр (Англи)">
        <el-input v-model="formData.name_en"></el-input>
      </el-form-item>
      <el-form-item label="Хүргэлттэй эсэх">
        <el-switch v-model="deliveryHandler" @change="changeDelivery"></el-switch>
      </el-form-item>
      <el-form-item label="Очиж авах боломжтой эсэх">
        <el-switch v-model="pickupHandler" @change="changePickup"></el-switch>
      </el-form-item>
      <el-form-item label="Нээх цаг">
        <el-time-select :picker-options="{ start: '00:00', step: '00:15', end: '10:00' }" v-model="formData.start_time"></el-time-select>
      </el-form-item>
      <el-form-item label="Хаах цаг">
        <el-time-select :picker-options="{ start: '18:00', step: '00:15', end: '24:00' }" v-model="formData.end_time"></el-time-select>
      </el-form-item>
      <el-form-item label="Байршил">
        <google-map :pos="{ lat: formData.latitude, lng: formData.longitude}" @handleChangePosition="(obj) => handleChangePosition(obj)"></google-map>
      </el-form-item>
      <el-form-item label="Зураг" class="img-con">
        <el-button v-if="!editImage" type="primary" @click="handleEditImageButton" size="mini" icon="el-icon-edit"></el-button>
        <el-upload v-if="editImage" action="/" :auto-upload="false"
          :on-change="(e) => handleUpload(e)"
          list-type="picture-card"
          :file-list="[{ name: '', url: cdn + formData.img_url }]"
          ><i class="el-icon-plus"></i></el-upload>
        <el-image style="width:100%" v-else :src="cdn + formData.img_url">
          <template #placeholder>
            <div class="image-slot" style="text-align:center;">
              <img src="https://i.stack.imgur.com/h6viz.gif">
            </div>
          </template>
        </el-image>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
      <el-button>Буцах</el-button>
      <el-button :loading="submitLoading" @click="handleSave" type="primary">Хадгалах</el-button>
      </span>
    </template>
  </el-dialog>
</div>
</template>

<script>
import GoogleMap from './googleMap.vue'
import managerServices from '@/services/managerServices.js'
export default {
  components: { GoogleMap },
  props: ['visible', 'handleHide', 'currentData', 'refetch'],
  data () {
    return {
      cdn: 'https://397isne8v4.execute-api.ap-southeast-1.amazonaws.com/prod/resize',
      submitLoading: false,
      deliveryHandler: this.have_delivery === 1 || false,
      pickupHandler: this.have_pickup === 1 || false,
      editImage: false,
      formData: {
        outletname: this.currentData.outletname,
        img_url: this.currentData.img_url,
        start_time: this.currentData.start_time,
        end_time: this.currentData.end_time,
        latitude: this.currentData.latitude,
        longitude: this.currentData.longitude,
        name_mn: this.currentData.name_mn,
        name_en: this.currentData.name_en,
        have_delivery: this.currentData.have_delivery,
        have_pickup: this.currentData.have_pickup
      }
    }
  },
  methods: {
    handleEditImageButton () {
      this.editImage = true
    },
    handleChangePosition (pos) {
      this.formData.latitude = pos.lat
      this.formData.longitude = pos.lng
    },
    async handleSave () {
      if (this.formData.end_time === '24:00') { this.formData.end_time = '23:59' }
      managerServices.saveOutletInfo(this.formData).then(data => {
        if (data.statusCode === 200 && data.success) {
          this.$notify({ title: 'Амжилттай', message: 'Амжилттай хадгалагдлаа.', type: 'success' })
          this.refetch()
          this.handleHide()
        } else {
          this.$notify({ title: 'Алдаа гарлаа', message: 'Алдаа гарлаа', type: 'error' })
        }
      })
    },
    async handleUpload (e) {
      this.submitLoading = true
      const res = await this.fetchImages([e], 'kfc>pictures')
      this.formData.img_url = res[0]
      this.editImage = false
      this.submitLoading = false
    },
    changeDelivery (val) {
      if (val) {
        this.formData.have_delivery = 1
      } else {
        this.formData.have_delivery = 0
      }
    },
    changePickup (val) {
      if (val) {
        this.formData.have_pickup = 1
      } else {
        this.formData.have_pickup = 0
      }
    },
    fetchImages (imgArr, dir) {
      return new Promise((resolve, reject) => {
        const urlsToReturn = []
        Promise.all(imgArr.map(async img => {
          const here = await this.uploadToServer(img, dir)
          urlsToReturn.push(here)
        })).then(() => {
          resolve(urlsToReturn)
        })
      })
    },
    uploadToServer (img, dir) {
      return new Promise((resolve, reject) => {
        toBase64(img.raw).then((result64) => {
          fetch('https://397isne8v4.execute-api.ap-southeast-1.amazonaws.com/prod/upload?' + 'dir=' + dir, {
            method: 'POST',
            headers: {
              'Content-Type': 'text/plain'
            },
            body: result64
          }).then(response => response.json()).then(data => {
            resolve(data.param)
          })
        })
      })
    }
  },
  created () {
    if (this.currentData.have_delivery === 1) {
      this.deliveryHandler = true
    } else {
      this.deliveryHandler = false
    }
    if (this.currentData.have_pickup === 1) {
      this.pickupHandler = true
    } else {
      this.pickupHandler = false
    }
  }
}

const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = () => {
    const baseString = reader.result
    const hook = ';base64,'
    const hookIndex = baseString.indexOf(hook)
    const clearedString = baseString.slice(hookIndex + hook.length, baseString.length)
    resolve(clearedString)
  }
  reader.onerror = error => reject(error)
})
</script>

<style scoped>
  .img-con{
    position: relative;
  }
  .img-con .el-button {
    position: absolute;
    width: 100px;
    top: 15px;
    right: 15px;
    z-index: 1;
    opacity: 1;
    transition: 0.5s ease;
  }
  .img-con label{
    margin-bottom: 6px !important;
  }
  .el-loading-mask{
    position: fixed !important;
  }
</style>
