<template>
  <div class="home">
    <el-row style="margin-bottom: 25px">
      <el-col :span="4">
        <span class="select-field">Дүүрэг : </span>
        <el-select clearable v-model="district" placeholder="Дүүргээ сонгоно уу?" @change="changeDisrict" size="mini">
          <el-option v-for="district in districts" :key="district.key"
            :label="district.key + '(' + district.doc_count + ')'" :value="district.key">
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="4">
        <span class="select-field">Хороо : </span>

        <el-select :disabled="horoos.length === 0" clearable v-model="horoo" placeholder="Хороогоо сонгоно уу?"
          size="mini">
          <el-option v-for="horoo in horoos" :key="horoo.key" :label="horoo.key + ' (' + horoo.doc_count + ')'"
            :value="horoo.key">
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="4">
        <span class="select-field">ПОД : </span>

        <el-select :disabled="pods.length === 0" clearable v-model="pod" placeholder="ПОД сонгоно уу?" size="mini">
          {{ pod }}
          <el-option v-for="pod in pods" :key="pod.key" :label="pod.key" :value="pod.key">
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="5">
        <el-input style="width:90%" placeholder="Хайх хаягаа оруулна уу?" size="mini" @change="search"
          v-model="addressValue">
          <i slot="prefix" class="el-input__icon el-icon-search"></i>

          <template #append>
            <el-button @click="search">Хайх</el-button>
          </template>

        </el-input>
      </el-col>
      <el-col :span="2">
        <el-button type="success" @click="() => { dialogFormVisible1 = true; this.newApart = {} }">Шинээр
          нэмэх</el-button>
      </el-col>
    </el-row>
    <!-- <el-autocomplete
      class="inline-input"
      v-model="value"
      :fetch-suggestions="querySearch"
      placeholder="Please Input"
      @select="handleSelect()"
      style="width: 500px"
    ></el-autocomplete> -->
    <el-row>
      <el-col :span="19">
        <el-table size="mini" :data="apartsTable" stripe style="width: 100%">
          <el-table-column prop="city" label="Хот" width="60"></el-table-column>
          <el-table-column prop="district" label="Дүүрэг" width="70"></el-table-column>
          <el-table-column prop="horoo" label="Хороо" width="100"></el-table-column>
          <el-table-column prop="bairname" label="Хаяг"></el-table-column>
          <!-- <el-table-column prop="podcode" label="TOKTOK" width="100"></el-table-column> -->
          <!-- <el-table-column prop="podph" label="Под PH" width="100"></el-table-column> -->
          <el-table-column prop="podkfc" label="Под KFC" width="100"></el-table-column>
          <!-- <el-table-column prop="podtoktok" label="Под TOKTOK" width="100"></el-table-column>  -->
          <el-table-column label="" width="200">
            <template slot-scope="scope">
              <div class="action">
                <el-button type="primary" size="mini" @click="handleEdit(scope.row._id)">Edit</el-button>
                <el-button type="primary" size="mini" @click="handleLog(scope.row._id)">Лог харах</el-button>
              </div>
              <!-- <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.row)">Delete</el-button> -->
            </template>
          </el-table-column>
        </el-table>
      </el-col>
      <el-col :span="5">&nbsp;</el-col>
    </el-row>
    <el-row style="margin:20px 0; font-size:15px;">
      <el-col :span="17">Сүүлд орсон өөрчлөлтүүд :</el-col>
      <el-button type="primary" size="mini" plain @click="handleAllLog()">Бүх лог харах</el-button>

    </el-row>
    <el-row>
      <el-col :span="19">
        <el-table size="mini" :data="logs" stripe style="width: 100%">
          <el-table-column prop="action" label="Үйлдэл" width="100"></el-table-column>
          <el-table-column prop="emp" label="Хэрэглэгч" width="220"></el-table-column>
          <el-table-column label="Он сар" width="160">
            <template #default="scope">
              <div style="display: flex; align-items: center">
                {{ new Date(scope.row.timestamp).toLocaleString('en-GB') }}
              </div>
            </template>

          </el-table-column>
          <el-table-column prop="city" label="Хот" width="60"></el-table-column>
          <el-table-column prop="district" label="Дүүрэг" width="70"></el-table-column>
          <el-table-column prop="horoo" label="Хороо" width="100"></el-table-column>
          <el-table-column prop="bairname" label="Хаяг"></el-table-column>
          <el-table-column prop="bairnote" label="Албан бус хаяг"></el-table-column>
          <!-- <el-table-column prop="podcode" label="TOKTOK" width="100"></el-table-column> -->
          <!-- <el-table-column prop="podph" label="Под PH" width="100"></el-table-column> -->
          <!-- <el-table-column prop="podkfc" label="Под KFC" width="100"></el-table-column> -->
          <!-- <el-table-column prop="podtoktok" label="Под TOKTOK" width="100"></el-table-column>  -->
          <!-- <el-table-column label="" width="150">
            <template slot-scope="scope">
              <el-button type="primary" size="mini" plain @click="handleEdit(scope.row)">Edit</el-button>
            </template>
          </el-table-column> -->
        </el-table>
      </el-col>
      <el-col :span="5">&nbsp;</el-col>
    </el-row>
    <el-dialog title="Хаяг засах" :visible.sync="dialogFormVisible" width="500px">
      <el-form :model="apart">
        <el-form-item label="Хот:" label-width="120px">
          <el-input v-model="apart.city"></el-input>
        </el-form-item>
        <el-form-item label="Дүүрэг:" label-width="120px">
          <!-- <el-input v-model="apart.district"></el-input> -->
          <el-select clearable v-model="apart.district" placeholder="Дүүргээ сонгоно уу?" size="mini">
            <el-option v-for="district in districts" :key="district.key"
              :label="district.key + '(' + district.doc_count + ')'" :value="district.key">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Хороо:" label-width="120px">
          <!-- <el-input v-model="apart.horoo"></el-input> -->
          <el-select clearable v-model="apart.horoo" placeholder="Хороогоо сонгоно уу?" size="mini">
            <el-option v-for="horoo in horoos" :key="horoo.key" :label="horoo.key + ' (' + horoo.doc_count + ')'"
              :value="horoo.key">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Хаяг:" label-width="120px">
          <el-input v-model="apart.bairname"></el-input>
        </el-form-item>
        <el-form-item label="Албан бус хаяг:" label-width="120px">
          <el-input v-model="apart.bairnote"></el-input>
        </el-form-item>
        <!-- <el-form-item label="Х/Бүс:" label-width="120px">
          <el-input v-model="apart.podcode"></el-input>
        </el-form-item> -->
        <!-- <el-form-item label="PH Х/Бүс:" label-width="120px">
          <el-input v-model="apart.podph"></el-input>
        </el-form-item> -->
        <el-form-item label="KFC Х/Бүс:" label-width="120px">
          <!-- <el-select v-model="apart.podkfc" placeholder="Подоо сонгоно уу?">
            <el-option key="" label="Хүргэлтийн бүсээс гадна" value=""></el-option>
            <el-option v-for="pod in pods" :key="pod.key" :label="pod.key" :value="pod.key">
            </el-option>
          </el-select> -->
          <!-- <el-input v-model="apart.podkfc"></el-input> -->

          <el-autocomplete v-model="apart.podkfc" :fetch-suggestions="querySearch" clearable
            placeholder="Подоо сонгоно уу?" @select="handleSelectkfc" />

        </el-form-item>
        <el-form-item label="Өргөрөг:" label-width="120px">
          <el-input v-model="apart.latitude"></el-input>
        </el-form-item>
        <el-form-item label="Уртраг:" label-width="120px">
          <el-input v-model="apart.longitude"></el-input>
        </el-form-item>
        <!-- <el-form-item label="TOKTOK Х/Бүс:" label-width="120px">
          <el-input v-model="apart.podtoktok"></el-input>
        </el-form-item> -->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">Буцах</el-button>
        <el-button type="primary" @click="update">Засах</el-button>
      </span>
    </el-dialog>
    <el-dialog title="Хаяг нэмэх" :visible.sync="dialogFormVisible1" width="500px">
      <el-form :model="newApart">
        <el-form-item label="Хот:" label-width="120px">
          <!-- <el-input v-model="newApart.city"></el-input> -->
          <el-select v-model="newApart.city">
            <el-option v-for="(city) in cities" :key="city.key" :label="city.key" :value="city.key">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Дүүрэг:" label-width="120px">
          <el-select v-model="newApart.district" placeholder="Дүүргээ сонгоно уу?" @change="changeDisrict1">
            <el-option v-for="(district) in districts" :key="district.key" :label="district.key" :value="district.key">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Хороо:" label-width="120px">
          <el-select v-model="newApart.horoo" placeholder="Хороогоо сонгоно уу?">
            <el-option v-for="horoo in horoos" :key="horoo.key" :label="horoo.key" :value="horoo.key">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Хаяг:" label-width="120px">
          <el-input v-model="newApart.bairname"></el-input>
        </el-form-item>
        <el-form-item label="Албан бус хаяг:" label-width="120px">
          <el-input v-model="newApart.bairnote"></el-input>
        </el-form-item>
        <!-- <el-form-item label="Х/Бүс:" label-width="120px">
          <el-input v-model="newApart.podcode"></el-input>
        </el-form-item> -->
        <!-- <el-form-item label="PH Х/Бүс:" label-width="120px">
          <el-input v-model="newApart.podph"></el-input>
        </el-form-item> -->
        <el-form-item label="KFC Х/Бүс:" label-width="120px">
          <!-- <el-select v-model="newApart.podkfc" placeholder="Подоо сонгоно уу?">
            <el-option v-for="pod in pods" :key="pod.key" :label="pod.key" :value="pod.key">
            </el-option>
          </el-select> -->
          <!-- <el-input v-model="newApart.podkfc"></el-input> -->

          <el-autocomplete v-model="newApart.podkfc" :fetch-suggestions="querySearchNew" clearable
            placeholder="Подоо сонгоно уу?" @select="handleSelectkfc" />

        </el-form-item>
        <el-form-item label="Өргөрөг:" label-width="120px">
          <el-input v-model="newApart.latitude"></el-input>
        </el-form-item>
        <el-form-item label="Уртраг:" label-width="120px">
          <el-input v-model="newApart.longitude"></el-input>
        </el-form-item>
        <!-- <el-form-item label="TOKTOK Х/Бүс:" label-width="120px">
          <el-input v-model="newApart.podtoktok"></el-input>
        </el-form-item> -->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible1 = false">Cancel</el-button>
        <el-button type="primary" @click="create">Confirm</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<style scoped>
.home .select-field {
  font-size: 14px;
}

.action {
  display: flex;
}
</style>
<script>
// import { deleteApart, create, update,  getAddress, getHoroos } from '../helpers/request.js'
import addressServices from '@/services/addressServices.js'
// import managerServices from '@/services/managerServices.js'
import { getUserName } from '@/utils/auth.js'

export default {
  name: 'home',
  created () {
    this.handleAllLog()
    addressServices.getCities().then(response => {
      if (response.data.success) {
        this.cities = response.data.data
      }
    })
    addressServices.getDistricts().then(response => {
      if (response.data.success) {
        this.districts = response.data.data
      }
      console.log('get District');
    })
    getUserName().then(data => {
      this.user = data.username.split('@')[0]
    })
    addressServices.getPodList().then(response => {
      if (response.data.success) {
        this.pods = response.data.data
      }
    })
  },
  data () {
    return {
      user: '',
      dialogFormVisible: false,
      dialogFormVisible1: false,
      cities: [],
      city:'',
      districts: [],
      district: '',
      horoos: [],
      horoo: '',
      aparts: [],
      apartsTable: [],
      apart: {},
      newApart: {},
      addressValue: '',
      pods: [],
      pod: '',
      limit: 10,
      from: 0,
      logs: []
    }
  },
  watch: {
    'dialogFormVisible' (val) {
      if (!val) {
        this.search()
      }
    },

    'apart.district' (data) {
      this.changeDisrict(data)
    },

    'newApart.district' (data) {
      this.changeDisrict(data)
    }
  },
  methods: {
    create () {
      // this.newApart.user = this.user
      const newApart = {
        city: this.newApart.city,
        district: this.newApart.district,
        horoo: this.newApart.horoo,
        bairname: this.newApart.bairname,
        bairnote: this.newApart.bairnote,
        podkfc: this.newApart.podkfc,
        latitude: this.newApart.latitude,
        longitude: this.newApart.longitude,
        _id: this.newApart._id
      }

      addressServices.createAddress(newApart).then(response => {
        console.log(response)
        if (response.data.success) {
          this.$notify({
            title: 'Амжилттай.',
            message: 'Хаягийг амжилттай нэмлээ.',
            type: 'success'
          })
        } else {
          this.$notify({
            title: 'Анхааруулга.',
            message: 'Хаягийг нэмэх үйлдэл амжилтгүй боллоо.',
            type: 'warning'
          })
        }
        // this.dialogFormVisible1 = false
      })
    },
    querySearch (queryString, cb) {
      if (!queryString) { queryString = '' }
      queryString = queryString.toLowerCase()
      const searchedData = this.pods.filter((item) => {
        const val = item.key.toLowerCase()
        if (val.includes(queryString)) {
          return item
        }
      })
      const mappedData = searchedData.map(item => ({ ...item, value: item.key }))
      cb(mappedData)
    },
    querySearchNew (queryString, cb) {
      if (queryString) {
        this.newApart.podkfc = queryString.toLowerCase()
      }
      if (!queryString) { queryString = '' }
      queryString = queryString.toLowerCase()
      const searchedData = this.pods.filter((item) => {
        const val = item.key.toLowerCase()
        if (val.includes(queryString)) {
          return item
        }
      })
      const mappedData = searchedData.map(item => ({ ...item, value: item.key }))
      cb(mappedData)
    },
    update () {
      // this.apart.user = this.user
      const updatedApart = {
        city: this.apart.city,
        district: this.apart.district,
        horoo: this.apart.horoo,
        bairname: this.apart.bairname,
        bairnote: this.apart.bairnote,
        podkfc: this.apart.podkfc,
        latitude: this.apart.latitude,
        longitude: this.apart.longitude,
        _id: this.apart._id
      }
      addressServices.updateAddress(updatedApart).then(response => {
        console.log(response)
        if (response.data.success) {
          this.$notify({
            title: 'Амжилттай.',
            message: 'Хаягийг амжилттай засварлалаа.',
            type: 'success'
          })
        } else {
          this.$notify({
            title: 'Анхааруулга.',
            message: 'Хаягийг засварлах үйлдэл амжилтгүй боллоо.',
            type: 'warning'
          })
        }
        this.search()
        this.dialogFormVisible = false
      })
    },
    handleDelete (data) {
      this.$confirm('Энэ хаягийг бүр устгах гэж байна Үнэхээр итгэлтэй байна уу?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        addressServices.deleteAddress(data.id).then(response => {
          if (response.status === 'success') {
            this.$notify({
              title: 'Амжилттай.',
              message: 'Хаягийг амжилттай устгалаа.',
              type: 'success'
            })
          } else {
            this.$notify({
              title: 'Анхааруулга.',
              message: 'Хаягийг устгах үйлдэл амжилтгүй боллоо.',
              type: 'warning'
            })
          }
          this.search()
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: 'Delete canceled'
        })
      })
    },
    handleEdit (id) {
      this.apart = this.aparts.find(item => item._id === id)
      this.dialogFormVisible = true
    },
    handleAllLog () {
      addressServices.getLastLogs().then((response) => {
        if (response.data.success) {
          this.logs = response.data.data
        }
      }).catch((error) => {
        this.$notify({
          title: 'Алдаа',
          message: error.response.data.message,
          type: 'error'
        })
      })
    },
    handleLog (id) {
      console.log(id)
      addressServices.getLogById(id).then((response) => {
        if (response.data.success) {
          this.logs = response.data.data
        }
      }).catch((error) => {
        this.$notify({
          title: 'Алдаа',
          message: error.response.data.message,
          type: 'error'
        })
      })
    },
    search () {
      console.log(this.addressValue, this.district, this.horoo, this.pod)
      addressServices.getAddress(this.district, this.horoo, this.pod, this.addressValue, this.limit, this.from).then(response => {
        console.log(response)
        if (response.data.success) {
          this.aparts = response.data.data
          this.apartsTable = response.data.data
        }
      })
    },
    changeDisrict (district) {
      this.horoo = []
      addressServices.getHoroos(district).then(response => {
        if (response.data.success) {
          this.horoos = response.data.data
        }
      })
    },
    changeDisrict1 () {
      this.horoo = []
      addressServices.getHoroos(this.newApart.district).then(response => {
        if (response.data.success) {
          this.horoos = response.data.data
        }
      })
    },
    // querySearch (queryString, cb) {
    //   addressServices.getAddress(queryString, this.district, this.horoo).then(response => {
    //     if (response.status === 'success') {
    //       this.aparts = response.data
    //       cb(response.data)
    //     }
    //   })
    // },
    handleSelect () {
      this.aparts.forEach(element => {
        if (element.value === this.value) {
          this.apart = element
        }
      })
    },
    handleSelectkfc (item) {
      console.log(item)
    }
  }
}
</script>
