<template>
<div>
  <el-row style="padding:10px">
    <el-col :span="4">
    <el-button type="primary" @click="handleEdit" icon="el-icon-menu">Цэс татах</el-button>
    </el-col>
  </el-row>
  <el-row style="padding:20px 10px">
        <el-table :data="data" size="mini" v-loading="loading" element-loading-background="rgba(0, 0, 0, 0.3)" border stripe>
          <el-table-column label="Гарчиг" prop="outletname" :sortable="true"/>
          <el-table-column label="Гариг" prop="weekdayname_mn" :sortable="true">
          </el-table-column>
          <el-table-column label="Гарчигг" prop="starttime"></el-table-column>
          <el-table-column label="Гарчигг" prop="endtime"></el-table-column>
          <el-table-column label="Гарчиг" prop="pickupstarttime"></el-table-column>
          <el-table-column label="Гарчиг" prop="pickupendtime"></el-table-column>
        </el-table>
  </el-row>
</div>
</template>

<script>
import menuServices from '@/services/menuServices.js'

export default {
  data: function () {
    return {
      loading: false,
      data: []
    }
  },
  mounted () {},
  components: { },
  created () {
    this.getData()
  },
  computed: {

  },
  methods: {
    getData () {
      if (this.filter.outletname) {
        this.loading = true
        menuServices.getMenus({}).then((res) => {
          this.loading = false
          console.log(res.data, 'data')
        })
      } else {
        this.data = []
      }
    },
    handleEdit () {
      console.log('edit')
    }
  },
  watch: {
    'filter.outletname' (val) {
      this.getData()
    }
  }
}
</script>
