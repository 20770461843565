import { mainRequests } from '@/helpers/custom'
export default {
  getCities () {
    return new Promise(function (resolve, reject) {
      mainRequests
        .getAddress('/getCities')
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getLastLogs () {
    return new Promise(function (resolve, reject) {
      mainRequests
        .getAddress('/getLastLogs')
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getLogById (id) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .getAddress('/getLogById?_id=' + id)
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getDistricts () {
    return new Promise(function (resolve, reject) {
      mainRequests
        .getAddress('/getDistricts')
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getPodList () {
    return new Promise(function (resolve, reject) {
      mainRequests
        .getAddress('/getPods', {})
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  // getPodConfigs (filter = {}) {
  //   return new Promise(function (resolve, reject) {
  //     mainRequests
  //       .postNewManager('/store/pod/list', filter)
  //       .then((res) => {
  //         resolve(res)
  //       })
  //       .catch((err) => {
  //         reject(err)
  //       })
  //   })
  // },
  getHoroos (district) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .getAddress('/getHoroo?district=' + district)
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getAddress (district, horoo, pod, address, limit, from) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .getAddress(
          '/findAll?district=' +
            district +
            '&horoo=' +
            horoo +
            '&podkfc=' +
            pod +
            '&address=' +
            address +
            '&limit=' +
            limit +
            '&from=' +
            from
        )
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  searchMatch (name) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .getAddress('/searchMatch?address=' + name)
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  updateAddressLoc (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAddress('/upsertById', payload)
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  createAddress (apart) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAddress('/upsertByAddress', apart)
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getAddressNearby (lat, lon) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .getAddress('/searchByLocation?lat=' + lat + '&lon=' + lon)
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  deleteAddress (id) {
    let apart = {
      id: id
    }
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAddress('/deleteAddress', apart)
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  updateAddress (apart) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAddress('/upsertById', apart)
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}
