<template>
  <div style="width: 98%">
    <el-row>
      <el-col :span="24" style="display: flex;justify-content: flex-end;">
        <el-button v-if="isTbfIt" type="success" @click="addUser" style="">Жолооч
          нэмэх</el-button>
        <el-button type="danger" @click="startDriver" style="">Жолооч
          эхлүүлэх</el-button>
      </el-col>
    </el-row>
    <el-tabs v-model="activeTab" type="border-card" @tab-click="tabClick()"
      style="margin-top:20px; height: 81wh;padding-bottom: 0;">
      <el-tab-pane label="Жолоочийн тохиргоо" name="Жолоочийн тохиргоо">
        <div v-if="activeTab == 'Жолоочийн тохиргоо'" v-loading="orderLoad">
          <!-- <div style="height:76vh;"> -->
          <el-tabs v-model="activeDriverTab" type="border-card" @tab-click="tabClick()">
            <el-tab-pane label="Идэвхитэй" name="Идэвхитэй" />
            <el-tab-pane label="Идэвхигүй" name="Идэвхигүй" />
            <el-input v-model="searchName" style="width: 440px; marginBottom:20px;" placeholder="Нэрээр хайх">
              <i slot="prefix" class="el-input__icon el-icon-search"></i>
            </el-input>
            <el-table :data="filteredDrivers" size="mini" border stripe height="65vh">
              <el-table-column label="Нэр" prop="name" sortable>
              </el-table-column>
              <el-table-column label="РД" prop="registrationNo" sortable>
              </el-table-column>
              <el-table-column label="Салбар нэр" prop="outlet" sortable>
              </el-table-column>
              <el-table-column label="Ажил" prop="usertype" sortable>
              </el-table-column>
              <el-table-column label="Төлөв" prop="status" sortable>
              </el-table-column>
              <el-table-column label="Код" prop="pin" sortable>
              </el-table-column>
              <el-table-column label="Чипний код" prop="rfid" sortable>
              </el-table-column>
              <el-table-column>
                <template slot-scope="scope">
                  <el-button size="mini" @click="handleEdit(scope.row)" type="danger">Засах</el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-tabs>
          <!-- </div> -->
        </div>
      </el-tab-pane>
      <el-tab-pane label="Жолоочийн мэдээлэл" name="Жолоочийн мэдээлэл">
        <div v-if="activeTab == 'Жолоочийн мэдээлэл'" v-loading="orderLoad">
          <div style="height:76vh;">
            <driversInfo :driverData="driversGet" @reload="reload"></driversInfo>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
    <el-row>
      <el-col :span="18">
        <el-dialog title="Мэдээлэл засах" :visible.sync="show" width="450px">
          <el-form ref="form" :model="user" label-width="120px">
            <el-form-item label="Нэр:">
              <el-input disabled v-model="user.name"></el-input>
            </el-form-item>
            <el-form-item label="РД:" label-width="120px">
              <el-input v-model="user.registrationNo"></el-input>
            </el-form-item>
            <el-form-item label="Утасны дугаар:" label-width="120px">
              <el-input v-model="user.phone"></el-input>
            </el-form-item>
            <el-form-item label="Ажил:" label-width="120px">
              <el-select v-model="user.usertype" placeholder="Ажилаа сонгоно уу?">
                <el-option label="Жолооч" value="driver"></el-option>
                <el-option label="Менежер" value="manager"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Төлөв:" label-width="120px">
              <el-select v-model="user.status" placeholder="Cтатусаа сонгоно уу?">
                <el-option label="Хүргэлтэд гараагүй" value="0"></el-option>
                <el-option label="Хүргэлтэд гарсан" value="1"></el-option>
                <el-option label="Идэвхигүй" value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Чипний код:" label-width="120px">
              <el-input v-model="user.rfid"></el-input>
            </el-form-item>
            <el-form-item label="Код:" label-width="120px">
              <el-input v-model="user.pin"></el-input>
            </el-form-item>
            <el-form-item v-if="outlets.length > 1" label="Салбар:" label-width="120px">
              <el-select v-model="user.outlet" placeholder="Салбараа сонгоно уу?">
                <el-option v-for="item in outlets" :key="item" :label="item" :value="item">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="hide">Буцах</el-button>
              <el-button type="success" @click="onSubmit">Хадгалах</el-button>
            </el-form-item>
          </el-form>
        </el-dialog>
      </el-col>
      <el-col :span="6">
        <el-dialog title="Жолооч нэмэх" :visible.sync="show1" width="450px">
          <el-form ref="form" :model="user1" label-width="120px">
            <el-form-item label="Нэр:">
              <el-input v-model="user1.name"></el-input>
            </el-form-item>
            <el-form-item label="Е-майл:">
              <el-input v-model="user1.email"></el-input>
            </el-form-item>
            <el-form-item label="РД:" label-width="120px">
              <el-input v-model="user1.registrationNo"></el-input>
            </el-form-item>
            <el-form-item label="Утасны дугаар:" label-width="120px">
              <el-input v-model="user1.phone"></el-input>
            </el-form-item>
            <el-form-item label="Чипний код:" label-width="120px">
              <el-input v-model="user1.rfid"></el-input>
            </el-form-item>
            <el-form-item label="Код:" label-width="120px">
              <el-input v-model="user1.pin"></el-input>
            </el-form-item>
            <el-form-item v-if="outlets.length > 1" label="Салбар:" label-width="120px">
              <el-select v-model="user1.outlet" placeholder="Салбараа сонгоно уу?">
                <el-option v-for="item in outlets" :key="item" :label="item" :value="item">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="hide1">Буцах</el-button>
              <el-button type="success" @click="onSubmit1">Хадгалах</el-button>
            </el-form-item>
          </el-form>
        </el-dialog>
      </el-col>
    </el-row>
    <el-dialog title="Жолооч эхлүүлэх" :visible.sync="show2" width="300px" :before-close="handleClose">
      <el-row>
        <el-col :span="24">
          <el-select v-model="selected" style="width:100%">
            <el-option v-for="(item, index) in driverList" :key="index" :label="item.name" :value="item.name"></el-option>
          </el-select>
        </el-col>
      </el-row><br>
      <el-row>
        <el-col :span="24">
          <el-input-number v-model="money" :min="0" :max="50000" :step="1000" style="width:100%"></el-input-number>
        </el-col>
      </el-row>
      <span slot="footer">
        <el-button type="info" @click="handleClose">Гарах</el-button>
        <el-button type="success" @click="startDriverF">Эхлүүлэх</el-button>
      </span>
    </el-dialog>

  </div>
</template>
<script>
import driverService from '@/services/driverServices.js'
import DriversInfo from '@/components/driversInfo'
import { getGroups } from '@/utils/auth.js'
export default {
  mounted () { },
  components: { DriversInfo },
  created () {
    getGroups().then(data => {
      const index = data.indexOf('AddressEditor')
      if (index > -1) {
        data.splice(index, 1)
      }
      const index1 = data.indexOf('MenuEditor')
      if (index1 > -1) {
        data.splice(index1, 1)
      } else {
        this.isTbfIt = false
      }
      this.outlets = data
      this.reload()
    })
  },
  computed: {
    driversGet () {
      let ret = []
      this.drivers.forEach(element => {
        if (element.status > -1 && element.status < 3) {
          ret.push(element)
        }
      })
      return ret
    },
    driverList () {
      let ret = []
      this.drivers.forEach(element => {
        if (element.status === 2) {
          ret.push(element)
        }
      })
      return ret
    },
    filteredDrivers () {
      let drivers = [];
      if (this.activeDriverTab === 'Идэвхитэй') {
        drivers = this.data.filter(item => item.status !== 'Идэвхигүй')
      } else if (this.activeDriverTab === 'Идэвхигүй') {
        drivers = this.data.filter(item => item.status === 'Идэвхигүй')
      }
      return drivers.filter(
        (data) =>
          !this.searchName ||
          data.name.toLowerCase().includes(this.searchName.toLowerCase())
      );
    },
  },
  data: function () {
    return {
      activeTab: 'Жолоочийн тохиргоо',
      activeDriverTab: 'Идэвхитэй',
      searchName: '',
      orderLoad: true,
      isTbfIt: true,
      data: [],
      selected: '',
      money: 15000,
      show2: false,
      show1: false,
      show: false,
      user1: {},
      user: {},
      outlets: [],
      drivers: []
    }
  },
  methods: {
    tabClick () {
      this.reload()
    },
    reload () {
      getGroups().then(data => {
        this.outlets = data
        let hours = (new Date()).getHours()
        let date1 = ''; let date2 = ''
        if (hours < 8) {
          date1 = new Date(new Date((new Date()).getFullYear(), (new Date()).getMonth(), (new Date()).getDate() - 1, 0, 0, 0).setHours(8))
          date2 = new Date(new Date((new Date()).getFullYear(), (new Date()).getMonth(), (new Date()).getDate(), 0, 59, 59).setHours(hours))
        } else {
          date1 = new Date(new Date((new Date()).getFullYear(), (new Date()).getMonth(), (new Date()).getDate(), 0, 0, 0).setHours(8))
          date2 = new Date(new Date((new Date()).getFullYear(), (new Date()).getMonth(), (new Date()).getDate() + 1, 0, 0, 0).setHours(8))
        }
        this.orderLoad = true
        if (this.isTbfIt) {
          if (this.activeTab === 'Жолоочийн тохиргоо') {
            driverService.getDriversByOutlet(this.outlets, 'all').then(data => {
              if (data.statusCode === 200) {
                data.result.forEach(element => {
                  // element.usertype = element.usertype === 'driver' ? 'Жолооч' : 'Менежер'
                  element.status = element.status === 0 ? 'Хүргэлтэд гараагүй' : (element.status === 1 ? 'Хүргэлтэд гарсан' : 'Идэвхигүй')
                })
                this.data = data.result
                this.orderLoad = false

              }
            })
          } else if (this.activeTab === 'Жолоочийн мэдээлэл') {
            driverService.getDriversByOutlet(this.outlets, 'driver', new Date(date1.toString().split('GMT')[0] + ' UTC').toISOString().slice(0, 19).replace('T', ' '), new Date(date2.toString().split('GMT')[0] + ' UTC').toISOString().slice(0, 19).replace('T', ' ')).then(data => {
              if (data.statusCode === 200) {
                this.drivers = data.result
                this.orderLoad = false
              }
            })
          }

        } else {
          if (this.activeTab === 'Жолоочийн тохиргоо') {
            driverService.getReadyDriversByOutlet(this.outlets, 'all').then(data => {
              if (data.statusCode === 200) {
                data.result.forEach(element => {
                  // element.usertype = element.usertype === 'driver' ? 'Жолооч' : 'Менежер'
                  element.status = element.status === 0 ? 'Хүргэлтэд гараагүй' : (element.status === 1 ? 'Хүргэлтэд гарсан' : 'Идэвхигүй')
                })
                this.data = data.result
                this.orderLoad = false

              }
            })
          } else if (this.activeTab === 'Жолоочийн мэдээлэл') {
            driverService.getReadyDriversByOutlet(this.outlets, 'driver', new Date(date1.toString().split('GMT')[0] + ' UTC').toISOString().slice(0, 19).replace('T', ' '), new Date(date2.toString().split('GMT')[0] + ' UTC').toISOString().slice(0, 19).replace('T', ' ')).then(data => {
              if (data.statusCode === 200) {
                this.drivers = data.result
                this.orderLoad = false
              }
            })
          }
        }
      })
    },
    onSubmit () {
      var copyUser = JSON.parse(JSON.stringify(this.user))
      if (copyUser.status.length > 2) {
        copyUser.status = copyUser.status === 'Хүргэлтэд гараагүй' ? 0 : (copyUser.status === 'Хүргэлтэд гарсан' ? 1 : 2)
      }
      driverService.changeUser(copyUser).then(data => {
        if (data.statusCode === 200) {
          this.$notify({
            title: 'Амжилттай',
            message: 'Амжилттай өөрчиллөө.',
            type: 'success'
          })
        } else {
          this.$notify.error({
            title: 'Алдаа',
            message: data.message
          })
        }
        this.reload()
        this.show = false
      })
    },
    handleEdit (user) {
      // this.user.role = this.user.role === 2 ? 'Менежер' : 'Жолооч'
      // this.user.status = this.user.status === 0 ? 'Хүргэлтэд гараагүй' : (this.user.status === 0 ? 'Хүргэлтэд гарсан' : 'Идэвхигүй')
      this.user = JSON.parse(JSON.stringify(user))
      this.show = true
    },
    startDriver () {
      this.show2 = true
    },
    startDriverF () {
      let body = {
        driver_name: this.selected,
        money: this.money,
        user: 'UserName'
      }
      driverService.startDriver(body).then(data => {
        if (data.statusCode === 200) {
          this.$notify({
            title: data.message,
            message: data.data,
            type: 'success'
          })
          this.show2 = false
          this.reload()
        } else {
          this.$notify({
            title: data.message,
            message: data.message,
            type: 'error'
          })
        }
      })
    },
    handleClose () {
      this.show2 = false
    },
    hide1 () {
      this.show1 = false
    },
    hide () {
      this.show = false
    },
    onSubmit1 () {
      if (this.outlets.length === 1) {
        this.user1.outlet = this.outlets[0]
      }
      driverService.createUser(this.user1).then(data => {
        this.$notify({
          title: data.message,
          message: data.data,
          type: 'success'
        })
        if (data.statusCode === 200) {
          this.show1 = false
          this.reload()
        }
      })
    },
    addUser () {
      this.show1 = true
    }
  }
}

</script>
