<template>
    <el-dialog :title="outlet.name_mn" :visible="dialogViewVisible"  @close="hide" width="800px" style="font-weight:500">
          <el-form ref="form" :model="outlet" label-width="140px">
            <el-row>
              <el-col :span="12">
            <el-form-item label="Салбар:">
              <el-input  v-model="outlet.outletname" :readonly="true"></el-input>
            </el-form-item>
            </el-col>
            <el-col :span="12">
            <el-form-item label="Салбарын нэр:">
              <el-input  v-model="outlet.name_mn" :readonly="true"></el-input>
            </el-form-item>
            </el-col>
            <el-col :span="12">
            <el-form-item label="Утас:" prop="phone" style="padding-bottom:10px">
              <el-input v-model="outlet.phone"></el-input>
            </el-form-item>
            </el-col>
            </el-row>
            <el-form-item label="Хаяг:">
              <el-input type="textarea" v-model="outlet.outletname" :readonly="true"></el-input>
            </el-form-item>
            <el-row>
              <el-col :span="12">
                <el-form-item label="Үйлчилгээний төрөл:">
                  <el-input v-if="outlet.operation_type=='express'" value="Экспрэсс" :readonly="true" />
                  <el-input v-if="outlet.operation_type=='main'" value="Үндсэн" :readonly="true" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Төлөв:">
                  <el-input v-if="outlet.status==='1'" value="Идвэхитэй" class="txt-true" :readonly="true"></el-input>
                  <el-input v-if="outlet.status==='0'" value="Идвэхигүй" class="txt-false" :readonly="true"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <Title title="Тохиргоо"></Title>
            <el-row>
              <el-col :span="12">
                <el-form-item label="Хүргэлттэй эсэх:">
                  <el-input v-if="outlet.have_delivery===1" value="Хүргэлттэй" class="txt-true" :readonly="true"></el-input>
                  <el-input v-if="outlet.have_delivery===0" value="Хүргэлтгүй" class="txt-false" :readonly="true"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Очиж авах эсэх:">
                  <el-input v-if="outlet.have_pickup===1" value="Авна" class="txt-true" :readonly="true"></el-input>
                  <el-input v-if="outlet.have_pickup===0" value="Авахгүй" class="txt-false" :readonly="true"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="Урьдчилсан захиала авах:" label-width="200px">
                  <el-input v-if="outlet.have_advanced===1" value="Авна" class="txt-true" :readonly="true"></el-input>
                  <el-input v-if="outlet.have_advanced===0" value="Авахгүй" class="txt-false" :readonly="true"></el-input>
                </el-form-item>
              </el-col>
               <el-col :span="12">
                <el-form-item label="Вэб дээр харагдах:">
                  <el-input v-if="outlet.web_show===1" value="Харагдана" class="txt-true" :readonly="true"></el-input>
                  <el-input v-if="outlet.web_show===0" value="Харагдахгүй" class="txt-false" :readonly="true"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <Title title="Захиалга шилжүүлэх"></Title>
            <el-row>
              <el-col :span="12">
                <el-form-item label="Захиалга шилжүлэх:">
                  <el-input v-if="outlet.is_redirect===1" value="Шилжүүлнэ" class="txt-true" :readonly="true"></el-input>
                  <el-input v-if="outlet.is_redirect===0" value="Шилжүүлэхгүй" class="txt-false" :readonly="true"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Шилжүүлэх салбар:">
                  <el-input v-model="outlet.redirect_outletname" class="txt-bold" :readonly="true"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="20">
                <el-form-item label="Захиалга шилжүлэх цаг:" label-width="160px">
                  <el-time-picker v-model="outlet.redirect_starttime" :readonly="true"></el-time-picker>
                  -
                  <el-time-picker v-model="outlet.redirect_endtime" :readonly="true"></el-time-picker>
                </el-form-item>
                </el-col>
            </el-row>
            <Title title="Үнэ"></Title>
            <el-row>
                <el-col :span="11">
                  <el-form-item label="Хүргэлтийн дүн:">
                    <el-input v-model="outlet.delivery_charge" :readonly="true"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="13">
                  <el-form-item label="Захиалгын хамгийн бага дүн:" label-width="200px">
                    <el-input  v-model="outlet.minimum_order_amount" :readonly="true"></el-input>
                  </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                  <el-form-item label="Очиж авах захиалгын хамгийн бага дүн:" label-width="260px">
                    <el-input  v-model="outlet.minimum_pickup_order_amount" :readonly="true"></el-input>
                  </el-form-item>
            </el-row>
            <el-form-item label="Хүргэлт үнэгүй хийгдэх /онлайн/:" label-width="260px">
              <el-input v-model="outlet.free_delivery_online" :readonly="true"></el-input>
            </el-form-item>
            <el-form-item label="Хүргэлт үнэгүй хийгдэх /дуудлагын төв/:" label-width="260px">
              <el-input  v-model="outlet.free_delivery_cc" :readonly="true"></el-input>
            </el-form-item>
          </el-form>
          <el-row style="margin-top:20px;">
              <el-col :span="12">
                <el-button type="primary" @click="hide">Хаах</el-button>
              </el-col>
          </el-row>
      </el-dialog>
</template>
<script>

import Title from './title.vue'

export default {
  components: { Title },
  created () {
    let self = this
    this.$bus.$on('dialogShowOutletConfigView', function (row) {
      self.show(row)
    })
  },
  data () {
    return {
      dialogViewVisible: false,
      load: false,
      outlet: {}
    }
  },
  computed: {
  },
  methods: {
    numberFormat (value) {
      return value ? value.toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0
    },
    show (row) {
      this.load = false
      this.dialogViewVisible = true
      this.outlet = row
      this.load = true
    },
    hide () {
      this.dialogViewVisible = false
    },
    onSubmit () {

    }
  }
}
</script>

<style>
.el-form-item__content {
  margin-top: 0px;
}
.txt-true > .el-input__inner {
  color: green!important;
}
.txt-false > .el-input__inner {
  color: red!important;;
}
.el-date-editor--time {
  width: 120px!important;
}
.el-divider {
  margin: 12px 0!important;
}
.txt-bold > .el-input__inner {
  font-weight: 600;
  color: #292929;
}

</style>
