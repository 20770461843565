<template>
    <el-dialog title="Хуваарь засах" :visible.sync="dialogVisible" width="820px">
          <el-form ref="form" :model="data" label-width="140px"  :rules="rules">
              <el-form-item label="Салбар:">
                <el-input v-model="data.outletname" :readonly="true"></el-input>
              </el-form-item>
              <Title title="Цагийн хуваарь" />
              <el-row v-for="(day,i) in days" :key="Math.random()+i" style="padding:5px" :gutter="20">
                <el-col :span="8">
                <el-form-item label-width="100px" :label="day.label+' :'" :key="i" :prop="day.value+'.starttime'">
                <el-time-picker v-model="data[day.value].starttime"/>
                </el-form-item>
                </el-col>
                <el-col :span="8">
                <el-form-item label="-" :key="i"  label-width="40px" :prop="day.value+'.endtime'">
                  <el-time-picker v-model="data[day.value].endtime"/>
                </el-form-item>
                </el-col>
                <el-col :span="6" style="margin-left: 30px;margin-top:0px">
                  <el-label>Дараа өдөрт орох</el-label>
                  <div>
                    <el-switch v-model="data[day.value].shift" :active-value="1" :inactive-value="0" active-text="Тийм" inactive-text="Үгүй" style="margin-top:7px;"/>
                  </div>
                </el-col>
              </el-row>
              <Title title="Очиж авах цагийн хуваарь" />
              <el-row v-for="(day,i) in days" :key="i" style="padding:8px" :gutter="20">
                <el-col :span="8">
                <el-form-item label-width="100px" :label="day.label+' :'" :key="i" :prop="day.value+'.pickupstarttime'">
                <el-time-picker v-model="data[day.value].pickupstarttime"/>
                </el-form-item>
                </el-col>
                <el-col :span="8">
                <el-form-item label="-" :key="i"  label-width="40px" :prop="day.value+'.pickupendtime'">
                <el-time-picker v-model="data[day.value].pickupendtime"/>
                </el-form-item>
                </el-col>
                <el-col :span="6" style="margin-left: 30px;margin-top:0px">
                  <el-label >Дараа өдөрт орох</el-label>
                  <div>
                    <el-switch v-model="data[day.value].pickup_shift" :active-value="1" :inactive-value="0" active-text="Тийм" inactive-text="Үгүй" style="margin-top:7px;"/>
                  </div>
                </el-col>
              </el-row>
          </el-form>
          <el-row style="margin-top:20px;" >
              <el-col :span="12">
                <el-button type="primary" @click="hide">Буцах</el-button>
              </el-col>
              <el-col :span="12" style="display:flex;justify-content:end;">
                <el-button type="success" @click="onSubmit" :loading="load">Хадгалах</el-button>
              </el-col>
          </el-row>
      </el-dialog>
</template>
<script>

import Title from '@/components/outletSettings/title.vue'
import { getUserName } from '@/utils/auth.js'
import managerServices from '@/services/managerServices.js'
import dateFormat from 'dateformat'

export default {
  props: ['days', 'getData'],
  components: { Title },
  created () {
    let self = this
    this.$bus.$on('dialogShowSchedule', function (row) {
      self.show(row)
      console.log(row, 'data-loaded')
    })
    getUserName().then(data => {
      this.user = data.username.split('@')[0]
    })
  },
  data () {
    return {
      dialogVisible: false,
      load: false,
      user: '',
      localDays: [],
      data: {
        outletname: '',
        Monday: { starttime: '', endtime: '', shift: '', pickupstarttime: '', pickupendtime: '', pickup_shift: '', id: '' },
        Tuesday: { starttime: '', endtime: '', shift: '', pickupstarttime: '', pickupendtime: '', pickup_shift: '', id: '' },
        Wednesday: { starttime: '', endtime: '', shift: '', pickupstarttime: '', pickupendtime: '', pickup_shift: '', id: '' },
        Thursday: { starttime: '', endtime: '', shift: '', pickupstarttime: '', pickupendtime: '', pickup_shift: '', id: '' },
        Friday: { starttime: '', endtime: '', shift: '', pickupstarttime: '', pickupendtime: '', pickup_shift: '', id: '' },
        Saturday: { starttime: '', endtime: '', shift: '', pickupstarttime: '', pickupendtime: '', pickup_shift: '', id: '' },
        Sunday: { starttime: '', endtime: '', shift: '', pickupstarttime: '', pickupendtime: '', pickup_shift: '', id: '' }
      }
    }
  },
  computed: {
    rules () {
      let result = {}
      this.days.map(day => {
        result[day.value + '.starttime'] = [{ required: true, message: 'Эхлэх цагаа оруулна уу', trigger: 'change' }]
        result[day.value + '.endtime'] = [{ required: true, message: 'Дуусах цагаа оруулна уу', trigger: 'change' }]
        result[day.value + '.pickupstarttime'] = [{ required: true, message: 'Очиж авах эхлэх цагаа оруулна уу', trigger: 'change' }]
        result[day.value + '.pickupendtime'] = [{ required: true, message: 'Очиж авах дуусах цагаа оруулна уу', trigger: 'change' }]
      })
      return result
    }
  },
  methods: {
    show (row) {
      this.dialogVisible = true
      this.data = row
    },
    hide () {
      this.dialogVisible = false
    },
    onSubmit () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$confirm('Өөрчлөлт хийхдээ итгэлтэй байна уу ?', 'Анхаарууллага', {
            confirmButtonText: 'Тийм',
            cancelButtonText: 'Үгүй',
            type: 'warning'
          }).then(() => {
            let data = {}
            Object.keys(this.data).map(key => {
              const value = this.data[key]
              if (value.id) {
                data[key] = { id: value.id,
                  starttime: dateFormat(value.starttime, 'HH:MM:ss'),
                  endtime: dateFormat(value.endtime, 'HH:MM:ss'),
                  shift: value.shift,
                  pickupstarttime: dateFormat(value.pickupstarttime, 'HH:MM:ss'),
                  pickupendtime: dateFormat(value.pickupendtime, 'HH:MM:ss'),
                  pickup_shift: value.pickup_shift }
              } else {
                data[key] = value
              }
            })
            console.log(data, 'before save?')
            this.load = true
            managerServices.saveScheduleConfig({ user: this.user, data: JSON.stringify(data) }).then((res) => {
              this.load = false
              if (res.status === 'failed') {
                this.$message({
                  type: 'warning',
                  message: res.message
                })
              } else if (res.statusCode === 200) {
                this.$message({
                  type: 'success',
                  message: res.message
                })
                this.getData()
                this.hide()
              }
            }).catch(() => {
              this.load = false
              this.$message({
                type: 'error',
                message: 'Тохиргоо хадгалах явцад алдаа гарлаа'
              })
            })
          })
        } else {
          this.$message({
            type: 'warning',
            message: 'Хадгалах мэдээлэл алдаатай байна !!!'
          })
        }
      })
    }
  }
}
</script>
