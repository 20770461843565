<template>
  <div class="stats">
    <el-dialog v-if="dialogVisible" :visible.sync="dialogVisible" width="450px" :before-close="handleClose">
      <div class="dialog-body" v-loading="!load">
        <el-row :gutter="20">
          <el-col>
            <div class="complaintInfo">
              <h3><i class="fas fa-shopping-cart"></i>&nbsp;&nbsp;Гомдол</h3>
              <ul>
                <li><b>Төрөл: </b>{{complaint.complaint_key}}</li>
                <li><b>Дэлгэрэнгүй: </b>{{complaint.note}}</li>
                <li><b>Утасны дугаар:</b> {{complaint.phone}}</li>
                <li><b>Салбар:</b> {{complaint.outletname}}</li>
                <li><b>Захиалгын дугаар:</b> {{complaint.order_number}}</li>
                <li><b>Бүртгэсэн цаг:</b> {{complaint.created_at}}</li>
                <li><b>Гомдолын цаг:</b> {{complaint.doing_at}}</li>
              </ul>
            </div>
          </el-col>
        </el-row>
      </div>
      <span slot="footer">
        <el-button @click="handleClose">Буцах</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  created () {
    let self = this
    this.$bus.$on('dialogShowComp', function (comp) {
      // console.log(refer)
      self.show(comp)
    })
  },
  data () {
    return {
      dialogVisible: false,
      load: false,
      complaint: {},
      tableData: [],
      full_address: '',
      addr_desc: ''
    }
  },
  methods: {
    show (comp) {
      this.load = false
      this.dialogVisible = true
      this.complaint = comp
      this.load = true
    },
    handleClose () {
      this.dialogVisible = false
    }
  }
}
</script>
<style>
  .complaintInfo .el-table__header-wrapper {
    display: none;
  }

  .el-dialog__body {
    padding: 30px 35px !important
  }

  .stats h3 {
    color: rgb(59, 59, 59);
    font-size: 18px;
    font-weight: 900 !important;
  }
  .el-dialog {
    margin-top: 10vh;
  }
  .el-dialog__body {
    height: 90%;
  }
  .complaintInfo ul li {
    line-height: 25px;
  }
</style>
