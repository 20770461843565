<template>
  <div class="stats">
    <el-row :gutter="20">
      <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <i class="fas fa-chart-pie stat"></i>
            <span class="stat">Борлуулалт</span>
            <span class="stat1">{{ stats[0].money}}₮ ({{stats[0].count}}ш)</span>
          </div>
          <div class="statData">
            <el-table :data="tableData" style="width: 100%" size="mini">
              <el-table-column prop="outletName" width="150px" label="Салбар">
              </el-table-column>
              <el-table-column prop="count" width="50px" label="Тоо">
              </el-table-column>
              <el-table-column prop="money" label="Борлуулалт">
              </el-table-column>
            </el-table>
          </div>
          <el-button size="mini" class="statBtn" @click="$router.push('report')" round>Дэлгэрэнгүй</el-button>
        </el-card>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6" class="kpiStat">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <i class="fas fa-chart-line stat"></i>
            <span class="stat">KPI</span>
            <div v-if="stats[0].kpi" style="display: inline; width: 38%;">
              <!-- <span class="stat2 statt">AD {{stats[0].kpi[0].kpi_a}} % In Store {{stats[0].kpi[0].kpi_i}} %</span> -->
              <span class="stat2 statt">AD {{parseInt(stats[0].kpi[0].AD)}} % In Store {{parseInt(stats[0].kpi[0].InStore)}} %</span>
            </div>
          </div>
          <div class="statData">
            <el-table :data="tableData" style="width: 100%" size="mini">
              <el-table-column prop="outletName" width="180px" label="Салбар">
              </el-table-column>
              <el-table-column prop="kpi_a" width="70px" label="AD">
              </el-table-column>
              <el-table-column prop="kpi_i" label="In Store">
              </el-table-column>
            </el-table>
          </div>
          <el-button size="mini" class="statBtn" @click="$router.push('reportSalbar')" round>Дэлгэрэнгүй</el-button>
        </el-card>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6" class="complainStat">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <i class="fas fa-comments stat"></i>
            <span class="stat">Санал Гомдол</span>
            <span class="stat1">{{stats[0].gom}} ш</span>
          </div>
          <div class="statData">
            <el-table :data="tableData" style="width: 100%" size="mini">
              <el-table-column prop="outletName" width="160px" label="Салбар">
              </el-table-column>
              <el-table-column prop="san" label="Санал">
              </el-table-column>
              <el-table-column prop="gom" width="70px" label="Гомдол">
              </el-table-column>
            </el-table>
          </div>
          <el-button size="mini" class="statBtn" @click="$router.push('report')" round>Дэлгэрэнгүй</el-button>
        </el-card>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6" class="orderStat1">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <i class="fas fa-users stat"></i>
            <span class="stat">Захиалгын хугацаа</span>
            <span class="stat1">{{stats[0].averageOrderDuration}}мин</span>
          </div>
          <div class="statData">
            <el-table :data="tableRankArray" style="width: 100%" size="mini">
              <el-table-column prop="outletName" width="170px" label="Салбар">
              </el-table-column>
              <el-table-column prop="rank" width="60px" label="Ранк">
              </el-table-column>
              <el-table-column prop="averageOrderDuration"  label="Хугацаа/Мин">
              </el-table-column>
            </el-table>
          </div>
          <div>
            <el-button size="mini" class="statBtn" @click="$router.push('orders')" round>Дэлгэрэнгүй</el-button>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { getGroups } from '@/utils/auth.js'
export default {
  props: ['stats'],
  data () {
    return {
      outlets: [],
      tableData: [],
      tableRankArray: []
    }
  },
  created () {
    getGroups().then(data => {
      const index = data.indexOf('AddressEditor')
      if (index > -1) {
        data.splice(index, 1)
      }
      const index1 = data.indexOf('MenuEditor')
      if (index1 > -1) {
        data.splice(index1, 1)
      }
      this.outlets = data
    })
    var i = 0
    let tempArray = []
    let tempRankedArray = []
    this.stats.forEach(element => {
      if (i !== 0) {
        let temp = {}
        temp.rank = element.rank
        temp.averageOrderDuration = element.averageOrderDuration
        temp.averageDeliveryDuration = element.averageDeliveryDuration
        temp.count = element.count
        temp.san = element.gom
        temp.gom = element.san
        temp.money = element.money
        temp.outletName = element.outletName
        temp.count = element.count
        temp.kpi_i = parseInt(element.kpi[0].InStore) + '%'
        temp.kpi_a = parseInt(element.kpi[0].AD) + '%'
        temp.driverOk = element.driverOk
        temp.driverBusy = element.driverBusy
        tempArray.push(temp)
        if (temp.rank !== '') {
          tempRankedArray.push(temp)
        }
      }
      this.tableRankArray = tempRankedArray.sort((a, b) => a.rank - b.rank)
      this.tableData = tempArray
      this.$forceUpdate()
      i++
    })
  }
}
</script>
<style lang="scss">
  .stats {
    .el-card__body {
      padding:0 !important
    }
    h3 {
      color: rgb(59, 59, 59);
      font-size: 18px;
      width: 89%;
      display: block;
      font-weight: 900 !important;
      margin-right: auto;
      margin-left: auto;
      margin: 0;
      margin-bottom: 5px;
      text-align: center;
      // background: #dedede;
    }

    i.stat {
      font-size: 25px;
      padding: 10px;
      background: rgb(25, 113, 82);
      color: #fff;
      border-radius: 5px;
      border-radius: 5px;
      -webkit-box-shadow: 0px 0px 41px 3px rgba(25, 113, 82, 0.2);
      box-shadow: 0px 0px 41px 3px rgba(25, 113, 82, 0.2);
    }

    span.stat {
      font-size: 20px;
      font-weight: bold;
      margin-left: 20px;
    }

    span.stat1 {
      float: right;
      font-size: 17px;
      font-weight: bold;
      background: #eaeaea;
      padding: 8px 15px;
      border-radius: 16px;
      color: #000;
      display: block;
      width: fit-content;
      margin-top: 6px;
    }

    span.stat2 {
      float: right;
      font-size: 17px;
      font-weight: bold;
      background: #eaeaea;
      padding: 8px 15px;
      border-radius: 16px;
      color: #000;
      display: block;
      width: fit-content;
      margin-top: 6px;
    }

    .statData {
      .el-table .cell {
        font-size: 14px;
        font-weight: bold;
        padding-left: 0;
        margin-left: 19px;
      }
    }
    .statBtn {
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .statLink {
      text-decoration: none;
      width: 100%;
    }
  }
</style>
