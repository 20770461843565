<template>
  <div>
    <el-card>
        <div slot="header" class="clearfix">
            <span style="float: left"><b>Хуудас нэмэх</b></span>
            <el-button @click="() => handleShowDialog(null)" style="float:right;" type="primary" size="mini">Нэмэх <i class="el-icon-plus"></i></el-button>
        </div>
        <el-table :data="pages" size="mini">
            <el-table-column label="Нэр" prop="title"></el-table-column>
            <el-table-column label="Дэлгэрэнгүй" prop="description"></el-table-column>
            <el-table-column label="Идэвхитэй эсэх" prop="is_active">
                <template slot-scope="scope">
                    <el-tag type="success" size="mini" v-if="scope.row.is_active === 1">Тийм</el-tag>
                    <el-tag type="info" size="mini" v-else>Үгүй</el-tag>
                </template>
            </el-table-column>
            <el-table-column>
            <template #default="scope">
                <el-button @click="() => handleShowDialog(scope.row)" style="border:none;" circle icon="el-icon-s-tools"></el-button>
            </template>
            </el-table-column>
        </el-table>
    </el-card>
    <career-dialog v-if="dialogVisible" :currentData="dataForDialog" :visible="dialogVisible" :handleHide="handleHideDialog" :refetch="getPages"></career-dialog>
  </div>
</template>

<script>
import CareerDialog from './careerDialog.vue'
export default {
  components: { CareerDialog },
  data () {
    return {
      pages: [],
      dialogVisible: false,
      dataForDialog: null
    }
  },
  beforeMount () {
    this.getPages()
  },
  methods: {
    console (msg, info) {
      console.log(msg, info)
    },
    handleHideDialog () {
      this.dialogVisible = false
    },
    handleShowDialog (data) {
      this.dataForDialog = data
      this.dialogVisible = true
    },
    async getPages () {
      let res = await fetch('http://52.77.176.213/api/web/getCareerPage')
      res = await res.json()
      this.pages = res.result
    }
  }
}
</script>
