import Vue from 'vue'
import App from './App'
import router from './router'
import VueResource from 'vue-resource'
import Amplify from 'aws-amplify'
import vuetify from './plugins/vuetify'
import awsconfig from './aws-exports'
import VueBus from 'vue-bus'
import ElementUI from 'element-ui'
import locale from 'element-ui/lib/locale/lang/mn'
import 'element-ui/lib/theme-chalk/index.css'
import * as VueGoogleMaps from 'vue2-google-maps'
import Croppa from 'vue-croppa'
import moment from 'moment'

Vue.use(Croppa)
Vue.use(VueGoogleMaps, {
  installComponents: true,
  load: {
    key: 'AIzaSyCfah4vICMLxfhwz5D5hzrInIa7IWkWOaM',
    // key: 'AIzaSyBSdReOrwV_HTJySiN9A-XEJ7P79cBTOTg',
    region: 'MN',
    language: 'mn',
    libraries: 'places'
  }
})
Vue.use(ElementUI, { locale })
Vue.use(VueResource)
Vue.use(VueBus)
Amplify.configure(awsconfig)

Vue.use(Amplify)
Vue.config.productionTip = false
Vue.prototype.moment = moment

require('../scss/main.scss')
new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
