<template>
  <div>
    <div>
      <CouponCard/>
    </div>
    <div style="width: 100%">
      <el-row>
        <el-col>
          <el-button v-if="isCoupon" type="success" @click="createCoupon" style="margin: 15px; margin-left: 0px;">Купон код үүсгэх</el-button>

          <el-button type="warning" @click="addProduct" style="margin: 15px; margin-left: 0px;">Бүтээгдэхүүн нэмэх</el-button>

          <el-tabs v-model="activeTab"  @tab-click="tabClick(activeTab)" type="border-card">
            <el-tab-pane label="Хүргэлтийн бүтээгдэхүүн" name="Хүргэлтийн бүтээгдэхүүн">
              <div v-if="activeTab == 'Хүргэлтийн бүтээгдэхүүн'">
                <div style="height:76vh;">
                  <CouponProduct :products="productDelivery"/>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="Очиж авах бүтээгдэхүүн" name="Очиж авах бүтээгдэхүүн">
              <div v-if="activeTab == 'Очиж авах бүтээгдэхүүн'">
                <div style="height:76vh;">
                  <CouponProduct :products="productPickup"/>
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </el-col>
      </el-row>
      <!-- Add Products -->
      <el-dialog title="Бүтээгдэхүүн нэмэх" :visible.sync="showProduct" width="480px">
          <el-form ref="productForm" :model="productForm" label-width="120px" :rules="productRules">
            <el-form-item label="Цэсээс сонгох" prop="singleType">
              <el-select v-model="productForm.singleType" name="singleType" placeholder="Select" @change="typeChanged(productForm.type)" >
                  <el-option
                    v-for="item in productType"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="Бүтээгдэхүүн" label-width="120px" prop="singleProductKey">
              <el-select v-model="productForm.singleProductKey" name="singleProductKey" placeholder="Select" >
                <el-option
                      v-for="item in productForm.product"
                      :key="item.pkey"
                      :label="item.name_mon"
                      :value="item.pkey">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="closeProduct('productForm')">Хаах</el-button>
              <el-button type="success" @click="submitProduct('productForm')">Нэмэх</el-button>
            </el-form-item>
          </el-form>
      </el-dialog>
      <!-- / Products -->
      <!-- create Coupon -->
      <el-dialog title="Купон үүсгэх" :visible.sync="showCreate" width="480px">
          <el-form ref="couponForm" :model="couponForm" label-width="120px" :rules="rules">
            <el-form-item label="Эхлэх огноо" prop="start_distribution_date">
              <el-date-picker v-model="couponForm.start_distribution_date" type="date" placeholder="Эхлэх огноо"></el-date-picker>
            </el-form-item>
            <el-form-item label="Дуусах огноо" label-width="120px" prop="end_distribution_date">
              <el-date-picker v-model="couponForm.end_distribution_date" type="date" placeholder="Дуусах огноо"></el-date-picker>
            </el-form-item>
            <el-form-item label="Купоны тоо/ш" label-width="120px" prop="count">
              <el-input-number v-model="couponForm.count" :min="1" :max="50000"></el-input-number>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="closeCreate">Хаах</el-button>
              <el-button type="success" @click="submitForm('couponForm')">Хадгалах</el-button>
            </el-form-item>
          </el-form>
      </el-dialog>
      <!-- / createCoupon -->
    </div>
  </div>
</template>
<script>
import couponService from '@/services/orderServices'
import menuServices from '@/services/menuServices'
import CouponCard from '@/components/coupon/couponCart'
import CouponProduct from '@/components/coupon/couponProduct'
import moment from 'moment'

export default {
  created () {
    this.reload()
    let self = this
    this.$bus.$on('reload', function () {
      self.reload()
    })
  },
  components: {
    CouponCard,
    CouponProduct
  },
  data: function () {
    return {
      activeTab: 'Хүргэлтийн бүтээгдэхүүн',
      start_distribution_date: moment().format('YYYY-MM-DD 08:00:00'),
      end_distribution_date: moment().format('YYYY-MM-DD 08:00:00'),
      showCreate: false,
      showProduct: false,
      isCoupon: true,
      products: {},
      productPickup: [],
      productDelivery: [],
      couponForm: {
        count: 0,
        start_distribution_date: '',
        end_distribution_date: ''
      },
      productForm: {
        type: '',
        product: {},
        singleType: '',
        singleProductKey: ''
      },
      productType: [{
        value: 1,
        label: 'Хүргэлтийн'
      }, {
        value: 2,
        label: 'Очиж авах'
      }],
      rules: {
        count: [
          { required: true, message: 'Please Coupon name', trigger: 'blur' }
        ],
        start_distribution_date: [
          { required: true, message: 'Please pick start date', trigger: 'change' }
        ],
        end_distribution_date: [
          { type: 'date', required: true, message: 'Please pick a enddate', trigger: 'change' }
        ]
      },
      productRules: {
        singleType: [
          { required: true, message: 'Цэсээс сонгоно уу', trigger: 'blur' }
        ],
        singleProductKey: [
          { required: true, message: 'Бүтээгдэхүүнийг сонгоно уу', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    tabClick (data) {
      this.reload()
    },
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const form = {
            count: this.couponForm.count,
            start_distribution_date: moment(this.couponForm.start_distribution_date).format('YYYY-MM-DD HH:mm:ss'),
            end_distribution_date: moment(this.couponForm.end_distribution_date).format('YYYY-MM-DD HH:mm:ss')
          }

          couponService.generateCoupon(form).then(response => {
            this.$notify({
              title: 'Купон код амжилттай үүсгэлээ',
              message: response.message,
              type: 'success'
            })
            if (response.statusCode === 200) {
              this.reload()
              this.showCreate = false
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    submitProduct (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const form = {
            type: this.productForm.singleType,
            menu: this.productForm.product.find(x => x.pkey === this.productForm.singleProductKey)
          }
          couponService.createCouponProduct(form).then(response => {
            this.$notify({
              title: 'Бүтээгдэхүүн амжилттай үүсгэлээ',
              message: response.message,
              type: 'success'
            })

            if (response.statusCode === 200) {
              this.resetForm(formName)
              this.reload()
              this.showProduct = false
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetForm (formName) {
      this.$refs[formName].resetFields()
    },
    getProducts () {
      couponService.getCouponProducts().then(res => {
        if (res.statusCode === 200) {
          this.productDelivery = res.result.filter(item => item.type === 1).map(obj => ({ id: obj.id, product: obj.menu }))
          this.productPickup = res.result.filter(item => item.type === 2).map(obj => ({ id: obj.id, product: obj.menu }))
        }
      })
    },
    reload () {
      this.getProducts()
    },
    createCoupon () {
      this.showCreate = true
    },
    addProduct () {
      this.showProduct = true
    },
    typeChanged (type) {
      this.productForm.product = []
      this.singleProductKey = null
      menuServices.getMenus({ order_type: type }).then(res => {
        if (res.statusCode === 200) {
          this.productForm.product = res.result
        } else {
          this.$notify({
            title: 'Алдаа гарлаа',
            message: 'Алдаа: ' + res.message,
            type: 'error'
          })
        }
      })
    },
    closeCreate () {
      this.showCreate = false
    },
    closeProduct (formName) {
      this.showProduct = false
      this.resetForm(formName)
    }
  }
}
</script>
<style lang="scss">
  .charts {
    margin: 17px 0;
    .graph-card {
      .el-card__header {
        padding: 10px 20px;
      }
      h3 {
        margin: 0 auto;
        text-align: center;
        color: #fff;
        background: #3a8ee6;
        padding: 5px 17px;
        border-radius: 3px;
        width: fit-content;
        display: block;
        font-weight: 400;
        font-size: 17px;
      }
    }
  }
 </style>
