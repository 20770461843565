<template>
  <div class="stats reportCom">
    <el-row>
      <el-col :span="4">
        <el-select v-model="selectedOutlet" placeholder="Select" @change="calc">
          <el-option
            v-for="(item, index) in outletNames"
            :key="index"
            :label="item"
            :value="index">
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="5" v-if="this.selected !== 'Тайлан /Шөнө/' && this.selected !== 'Тайлан /Шөнө Жолооч/'">
        <el-date-picker v-model="datetime1" type="datetime" placeholder="Эхлэх огноо" >
          </el-date-picker>
      </el-col>
      <el-col :span="5" v-if="this.selected !== 'Тайлан /Шөнө/' && this.selected !== 'Тайлан /Шөнө Жолооч/'">
        <el-date-picker v-model="datetime2" type="datetime" placeholder="Дуусах огноо" >
          </el-date-picker>
      </el-col>
      <el-col :span="3" v-if="this.selected === 'Тайлан /Шөнө/' || this.selected === 'Тайлан /Шөнө Жолооч/'">
        <el-date-picker v-model="date1" type="date" placeholder="Эхлэх огноо" >
          </el-date-picker>
      </el-col>
      <el-col :span="3" v-if="this.selected === 'Тайлан /Шөнө/' || this.selected === 'Тайлан /Шөнө Жолооч/'">
        <el-date-picker v-model="date2" type="date" placeholder="Дуусах огноо" >
          </el-date-picker>
      </el-col>
      <el-col :span="3" v-if="this.selected === 'Тайлан /Шөнө/' || this.selected === 'Тайлан /Шөнө Жолооч/'">
      <el-time-picker v-model="time1">
        </el-time-picker>
      </el-col>
      <el-col :span="3" v-if="this.selected === 'Тайлан /Шөнө/' || this.selected === 'Тайлан /Шөнө Жолооч/'">
        <el-time-picker v-model="time2">
          </el-time-picker>
      </el-col>
      <el-col :span="2">
        <el-button type="primary" @click="calc()" plain>Боловсруулах</el-button>
      </el-col>
    </el-row>
    <el-table v-loading="reportLoad" :data="reportData" show-summary :summary-method="getSummaries">
      <el-table-column v-for="(item, index) in reportCols" :key="index" :label="item.mon" :prop="item.name" sortable></el-table-column>
    </el-table>
 
    <br>
    <div class="action">
      <el-button type="primary" @click="onexport">Татаж авах (xlsx)</el-button>
    <!-- <el-button type="primary" @click="printOK">Хэвлэх</el-button> -->
      <el-button type="success" @click="printOK1">Хэвлэх</el-button>
     </div>

  </div>
</template>
<script>
import XLSX from 'xlsx'
import printJS from 'print-js'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import reportServices from '@/services/reportServices.js'
import { getGroups } from '@/utils/auth.js'
pdfMake.vfs = pdfFonts.pdfMake.vfs
export default {
  props: ['selected'],
  created () {
    getGroups().then(data => {
      const index = data.indexOf('AddressEditor')
      if (index > -1) {
        data.splice(index, 1)
      }
      const index1 = data.indexOf('MenuEditor')
      if (index1 > -1) {
        data.splice(index1, 1)
      }
      this.outletNames = data
      let hours = (new Date()).getHours()
      if (hours < 8) {
        this.datetime1 = new Date(new Date((new Date()).getFullYear(), (new Date()).getMonth(), (new Date()).getDate() - 1, 0, 0, 0).setHours(8))
        this.datetime2 = new Date(new Date((new Date()).getFullYear(), (new Date()).getMonth(), (new Date()).getDate(), 0, 59, 59).setHours(hours))
        this.date1 = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 1)
        this.date2 = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())
        this.time1 = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 1, 22, 0, 0)
        this.time2 = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 2, 0, 0)
      } else {
        this.datetime1 = new Date(new Date((new Date()).getFullYear(), (new Date()).getMonth(), (new Date()).getDate(), 0, 0, 0).setHours(8))
        this.datetime2 = new Date(new Date((new Date()).getFullYear(), (new Date()).getMonth(), (new Date()).getDate() + 1, 0, 0, 0).setHours(8))
        this.date1 = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())
        this.date2 = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1)
        this.time1 = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 22, 0, 0)
        this.time2 = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1, 2, 0, 0)
      }
      this.calc()
    })
  },
  data () {
    return {
      outletNames: [],
      reportCols1: [],
      selectedOutlet: 0,
      datetime1: '',
      datetime2: '',
      date1: '',
      date2: '',
      time1: '',
      time2: '',
      tableBool: false,
      reportData: [],
      reportCols: [],
      reportLoad: true,
      disabledDates: date => {
        const today = new Date((new Date()).getDate() + 1)

        return date > today
      },
      disabledDates1: date => {
        const today = new Date((new Date()).getDate() + 1)

        return date > today || this.datetime1 > date
      }
    }
  },
  methods: {
    getSummaries (param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = 'Нийт:'
          return
        } else if (data.length === 0) {
          sums[index] = '0'
        }
        const values = data.map(item => Number(item[column.property]))
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return prev + curr
            } else {
              return prev
            }
          }, 0)
        } else {
          sums[index] = 'N/A'
        }
      })
      return sums
    },
    printOK1 () {
      let printT = []
      let colNameT = []
      let total = []
      this.reportCols.forEach(element => {
        let temp12 = {
          text: element.mon,
          style: 'header',
          alignment: 'center'
        }
        colNameT.push(temp12)
        total.push({ text: '0', alignment: 'center' })
      })
      printT.push(colNameT)
      this.reportData.forEach(element => {
        let temp = []
        let i = 0
        this.reportCols.forEach(element1 => {
          let temp123
          if (element[element1.name]) {
            temp123 = {
              text: element[element1.name].toString(),
              alignment: 'center'
            }
          } else {
            temp123 = {
              text: '0',
              alignment: 'center'
            }
          }
          temp.push(temp123)
          if (i === 0) {
            total[i].text = 'НИЙТ '
            total[i].style = 'header'
          } else if (Number.isInteger(element[element1.name])) {
            total[i].text = parseInt(total[i].text) + parseInt(element[element1.name])
          } else {
            total[i].text = ' '
          }
          i++
        })
        printT.push(temp)
      })
      printT.push(total)
      console.log(printT)
      var docDefinition = {
        pageSize: 'A4',
        pageOrientation: 'landscape',
        content: [
          { style: 'title', text: this.selected },
          {
            style: 'tableExample',
            table: {
              headerRows: 1,
              widths: [],
              body: printT
            }
          },
          { style: 'date', text: 'Эхлэх огноо: ' + (new Date(this.datetime1.toString().split('GMT')[0] + ' UTC').toISOString().slice(0, 19).replace('T', ' ')) + '    Дуусах огноо: ' + (new Date(this.datetime2.toString().split('GMT')[0] + ' UTC').toISOString().slice(0, 19).replace('T', ' ')) },
          { style: 'date', text: 'Баталгаажуулсан: /............................................/' },
          { style: 'date', text: 'Хэвлэсэн огноо: ' + (new Date((new Date()).toString().split('GMT')[0] + ' UTC').toISOString().slice(0, 19).replace('T', ' ')) }
        ],
        styles: {
          date: {
            fontSize: 12,
            margin: [0, 0, 5, 15]
          },
          title: {
            fontSize: 12,
            bold: true,
            margin: [0, 0, 5, 15]
          },
          header: {
            fontSize: 11,
            bold: true,
            margin: [0, 0, 5, 0]
          },
          tableExample: {
            fontSize: 11,
            margin: [0, 5, 0, 15]
          },
          tableHeader: {
            bold: true,
            fontSize: 13,
            color: 'black'
          }
        },
        defaultStyle: {
          alignment: 'justify'
        }
      }
      for (var i = 0; i < this.reportCols.length; i++) {
        docDefinition.content[1].table.widths.push('auto')
      }
      var name = new Date(this.datetime1.toString().split('GMT')[0] + ' UTC').toISOString().slice(0, 10) + ' ' + new Date(this.datetime2.toString().split('GMT')[0] + ' UTC').toISOString().slice(0, 10)
      pdfMake.createPdf(docDefinition).download(name + '.pdf')
    },
    printOK () {
      let printT = []
      let colNameT = []
      this.reportData.forEach(element => {
        let temp = {}
        this.reportCols.forEach(element1 => {
          temp[this.colName[element1]] = element[element1]
        })
        printT.push(temp)
      })
      this.reportCols.forEach(element => {
        colNameT.push(this.colName[element])
      })
      // console.log(this.colName)
      printJS({ printable: printT, type: 'json', properties: colNameT, documentTitle: this.selected, header: 'Баталгаажуулсан: /.........................../', headerStyle: 'font-weight: 100;', font_size: '10pt' })
    },
    calc () {
      if (
        (
          (
            (this.datetime1 === null || this.datetime2 === null) ||
              (this.datetime1 > this.datetime2)
          ) &&
            (this.selected !== 'Тайлан /Шөнө/' && this.selected !== 'Тайлан /Шөнө Жолооч/')
        ) ||
          (
            (this.selected === 'Тайлан /Шөнө/' || this.selected !== 'Тайлан /Шөнө Жолооч/') &&
            (
              (this.date1 === null || this.date2 === null || this.time1 === null || this.time2 === null) ||
              (this.date1 > this.date2)
            )
          )
      ) {
        this.$notify({
          title: 'Анхааруулга',
          message: 'Таны сонгосон өдрөөр тайлан боловсруулах боломжгүй байна.',
          type: 'warning'
        })
      } else {
        this.reportLoad = true
        let body
        let aa = new Date(this.datetime1.toString().split('GMT')[0] + ' UTC').toISOString().slice(0, 19).replace('T', ' ')
        let ab = new Date(this.datetime2.toString().split('GMT')[0] + ' UTC').toISOString().slice(0, 19).replace('T', ' ')
        let startTime = new Date(this.date1.toString().split('GMT')[0] + ' UTC').toISOString().slice(0, 10) + ' ' + new Date(this.time1.toString().split('GMT')[0] + ' UTC').toISOString().slice(11, 19)
        let endTime = new Date(this.date2.toString().split('GMT')[0] + ' UTC').toISOString().slice(0, 10) + ' ' + new Date(this.time2.toString().split('GMT')[0] + ' UTC').toISOString().slice(11, 19)
        if (this.selected === 'Тайлан /Жолооч/') {
          this.reportCols = [
            { name: 'driver', mon: 'Нэр' },
            { name: 'register', mon: 'РД' },
            { name: 'transportType', mon: 'Т/Х' },
            { name: 'cash', mon: 'Бэлэн' },
            { name: 'coupon', mon: 'Купон' },
            { name: 'credit_card', mon: 'Карт' },
            { name: 'dans', mon: 'Данс' },
            { name: 'online', mon: 'Онлайн' },
            { name: 'trans', mon: 'Тоо' },
            { name: 'count_del_perf', mon: 'Амжилттай хүргэлт' },
            { name: 'delivery_fee', mon: 'Хүргэлт дүн' },
            { name: 'menu_total', mon: 'Захиалга дүн' },
            { name: 'total', mon: 'Дүн' }
          ]
          body = {
            'show_date': '%Y',
            'show_time': false,
            'show_outlet': false,
            'show_type': false,
            'show_channel': false,
            'report_name': 'paytype',
            'show_driver': true,
            'show_pod': false,
            'start_time': aa,
            'end_time': ab,
            'array_outlets': [this.outletNames[this.selectedOutlet]]
          }
          console.log(body)
        } else if (this.selected === 'Тайлан /Бараа/') {
          body = {
            'report_name': 'managerSales',
            'start_time': aa,
            'end_time': ab,
            'outlet_name': this.outletNames[this.selectedOutlet]
          }
          console.log(body)
        } else if (this.selected === 'Тайлан /Төлбөрийн хэрэгсэлээр/') {
          this.reportCols = [
            { name: 'cash', mon: 'Бэлэн' },
            { name: 'coupon', mon: 'Купон' },
            { name: 'credit_card', mon: 'Карт' },
            { name: 'dans', mon: 'Данс' },
            { name: 'online', mon: 'Онлайн' },
            { name: 'trans', mon: 'Нийт гүйлгээ' },
            { name: 'total', mon: 'Нийт дүн' }
          ]
          body = {
            'show_date': '%Y',
            'show_time': false,
            'show_outlet': false,
            'show_type': false,
            'show_channel': false,
            'show_driver': false,
            'show_pod': false,
            'report_name': 'paytype',
            'start_time': aa,
            'end_time': ab,
            'array_outlets': this.outletNames[this.selectedOutlet]
          }
          console.log(body)
        } else if (this.selected === 'Тайлан /Хүргэлтийн бүсээр/') {
          this.reportCols = [
            { name: 'pod', mon: 'Под' },
            { name: 'trans', mon: 'Захиалгийн тоо' },
            { name: 'ordered_from', mon: 'Суваг' },
            { name: 'total', mon: 'Нийт дүн' }
          ]
          body = {
            'show_date': '%Y',
            'show_time': false,
            'show_outlet': false,
            'show_type': false,
            'show_channel': true,
            'show_driver': false,
            'show_pod': true,
            'report_name': 'paytype',
            'start_time': aa,
            'end_time': ab,
            'array_outlets': this.outletNames[this.selectedOutlet]
          }
          console.log(body)
        } else if (this.selected === 'Тайлан /Суваг/') {
          this.reportCols = [
            { name: 'sales_date', mon: 'Огноо' },
            { name: 'trans', mon: 'Захиалгийн тоо' },
            { name: 'ordered_from', mon: 'Суваг' },
            { name: 'total', mon: 'Нийт дүн' }
          ]
          body = {
            'show_date': '%Y%m%d',
            'show_time': false,
            'show_outlet': false,
            'show_type': false,
            'show_channel': true,
            'show_driver': false,
            'show_pod': false,
            'report_name': 'paytype',
            'start_time': aa,
            'end_time': ab,
            'array_outlets': this.outletNames[this.selectedOutlet]
          }
          console.log(body)
        } else if (this.selected === 'Тайлан /Өдөр/') {
          this.reportCols = [
            { name: 'ordered_from', mon: 'Суваг' },
            { name: 'ordered_type', mon: 'Хүргэлтийн төрөл' },
            { name: 'TA', mon: 'Захиалгийн дундаж' },
            { name: 'trans', mon: 'Захиалгийн тоо' },
            { name: 'delivery_fee', mon: 'Хүргэлтийн төлбөр' },
            { name: 'total', mon: 'Нийт дүн' }
          ]
          body = {
            'show_date': '%Y',
            'show_time': false,
            'show_outlet': false,
            'show_ordertype': true,
            'show_channel': true,
            'show_driver': false,
            'show_pod': false,
            'report_name': 'sales',
            'start_time': aa,
            'end_time': ab,
            'array_outlets': [this.outletNames[this.selectedOutlet]]
          }
        } else if (this.selected === 'Тайлан /KPI/') {
          this.reportCols = [
            { name: 'order_number', mon: 'Захиалгын дугаар' },
            { name: 'driver', mon: 'Жолоочийн нэр' },
            { name: 'amount', mon: 'Дүн' },
            { name: 'confirmed_at', mon: 'Захиалсан' },
            { name: 'driver_out', mon: 'Хүргэлтэд гарсан' },
            { name: 'delivered_at', mon: 'Хүргэж өгсөн' },
            { name: 'standart_time', mon: 'Хүргэж өгөх' },
            { name: 'inStore', mon: 'inStore' },
            { name: 'atDel', mon: 'atDel' },
            { name: 'noComp', mon: 'noComp' },
          ]
          body = {
            'report_name': 'kpidetail',
            'start_time': aa,
            'end_time': ab,
            'array_outlets': [this.outletNames[this.selectedOutlet]]
          }
          console.log(body)
        } else if (this.selected === 'Тайлан /цагаар/') {
          this.reportCols = [
            { name: 'time_range', mon: 'Цаг' },
            { name: 'trans', mon: 'Захиалгын тоо' },
            { name: 'TA', mon: 'Захиалгын дундаж үнэ' },
            { name: 'delivery_fee', mon: 'Хүргэлтийн үнэ' },
            { name: 'menu_total', mon: 'Бүтээгдүүний үнэ' },
            { name: 'total', mon: 'Нийт үнэ' }
          ]
          body = {
            'show_date': '%Y',
            'show_time': true,
            'show_outlet': false,
            'show_type': false,
            'show_channel': false,
            'show_driver': false,
            'show_pod': false,
            'report_name': 'sales',
            'start_time': aa,
            'end_time': ab,
            'array_outlets': this.outletNames[this.selectedOutlet]
          }
          console.log(body)
        } else if (this.selected === 'Тайлан /Шөнө/') {
          this.reportCols = [
            { name: 'ordered_from', mon: 'Суваг' },
            { name: 'ordered_type', mon: 'Хүргэлтийн төрөл' },
            { name: 'TA', mon: 'Захиалгийн дундаж' },
            { name: 'trans', mon: 'Захиалгийн тоо' },
            { name: 'delivery_fee', mon: 'Хүргэлтийн төлбөр' },
            { name: 'total', mon: 'Нийт дүн' }
          ]
          body = {
            'show_date': '%Y',
            'show_time': false,
            'show_outlet': false,
            'show_type': true,
            'show_channel': true,
            'show_driver': false,
            'show_pod': false,
            'report_name': 'salesNight',
            'start_time': startTime,
            'end_time': endTime,
            'array_outlets': [this.outletNames[this.selectedOutlet]]
          }
        } else if (this.selected === 'Тайлан /Шөнө Жолооч/') {
          this.reportCols = [
            { name: 'driver', mon: 'Нэр' },
            { name: 'register', mon: 'РД' },
            { name: 'transportType', mon: 'Т/Х' },
            { name: 'cash', mon: 'Бэлэн' },
            { name: 'coupon', mon: 'Купон' },
            { name: 'credit_card', mon: 'Карт' },
            { name: 'dans', mon: 'Данс' },
            { name: 'online', mon: 'Онлайн' },
            { name: 'trans', mon: 'Тоо' },
            { name: 'count_del_perf', mon: 'Амжилттай хүргэлт' },
            { name: 'delivery_fee', mon: 'Хүргэлт дүн' },
            { name: 'menu_total', mon: 'Захиалга дүн' },
            { name: 'total', mon: 'Дүн' }
          ]
          body = {
            'show_date': '%Y',
            'show_time': false,
            'show_outlet': false,
            'show_type': false,
            'show_channel': false,
            'report_name': 'paytypeNight',
            'show_driver': true,
            'show_pod': false,
            'start_time': startTime,
            'end_time': endTime,
            'array_outlets': [this.outletNames[this.selectedOutlet]]
          }
        } else if (this.selected === 'Тайлан /Pickup/') {
          this.reportCols = [
            { name: 'cash', mon: 'Бэлэн' },
            { name: 'coupon', mon: 'Купон' },
            { name: 'credit_card', mon: 'Карт' },
            { name: 'dans', mon: 'Данс' },
            { name: 'online', mon: 'Онлайн' },
            { name: 'trans', mon: 'Тоо' },
            { name: 'delivery_fee', mon: 'Хүргэлт дүн' },
            { name: 'menu_total', mon: 'Захиалга дүн' },
            { name: 'total', mon: 'Дүн' }
          ]
          body = {
            'show_date': '%Y',
            'show_time': false,
            'show_outlet': false,
            'show_type': false,
            'show_channel': false,
            'report_name': 'salesPickup',
            'show_driver': true,
            'show_pod': false,
            'start_time': aa,
            'end_time': ab,
            'array_outlets': [this.outletNames[this.selectedOutlet]]
          }
        }// else if (this.selected === 'Тайлан /Жолоочийн РД/') {
        //   this.reportCols = [
        //     { name: 'driver', mon: 'Нэр' },
        //     { name: 'register', mon: 'РД' },
        //     { name: 'trans', mon: 'Тоо' },
        //     { name: 'delivery_fee', mon: 'Хүргэлтийн дүн' },
        //     { name: 'menu_total', mon: 'Захиалгын дүн' },
        //     { name: 'total', mon: 'Нийт дүн' }
        //   ]
        //   body = {
        //     'report_name': 'driver1',
        //     'start_time': aa,
        //     'end_time': ab,
        //     'array_outlets': [this.outletNames[this.selectedOutlet]]
        //   }
        //   console.log(body)
        // }
        reportServices.report(body).then(data => {
          this.reportData = data
          this.reportLoad = false
          this.$forceUpdate()
        })
      }
    },
    onexport () {
      var reportXlsx = XLSX.utils.json_to_sheet(this.reportData)
      var wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, reportXlsx, 'report')
      var name = new Date(this.datetime1.toString().split('GMT')[0] + ' UTC').toISOString().slice(0, 10) + ' ' + new Date(this.datetime2.toString().split('GMT')[0] + ' UTC').toISOString().slice(0, 10)
      XLSX.writeFile(wb, this.selected + name + '.xlsx')
    }
  }
}
</script>
<style lang="scss">
  .el-date-editor.el-input, .el-date-editor.el-input__inner {
      width: 100% !important;
  }
  .reportCom {
    .el-tabs__nav-wrap {
      .el-tabs__nav-prev {
        color: #ffffff !important;
        width: 28px !important;
        background: #354555 !important;
        i.el-icon-arrow-left {
          margin-left: 7px !important;
        }
      }
      .el-tabs__nav-next {
        color: #ffffff !important;
        width: 28px !important;
        background: #354555 !important;
        i.el-icon-arrow-right {
          margin-left: 7px !important;
        }
      }
      .el-table__footer-wrapper {
        // margin-top: -50px !important;
      }
    }
    .el-table__footer-wrapper {
      // margin-top: calc(-50px);
    }
    .el-table__body-wrapper {
      height:400px;
      overflow:scroll;
    }

  }
</style>
