<template>
    <div class="login-container">
        <h3>Нууц үг сэргээх</h3>
        <el-alert title="Хэрэглэгчийн нэр эсвэл нууц үг буруу байна!" v-if="error" type="error"></el-alert>
        <el-form v-on:submit.prevent="onSubmit" ref="emailForm" :model="emailForm" :rules="emailRules">
            <el-form-item prop="email" label="И-мэйл" >
                <el-input v-model="emailForm.email"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button v-loading="load" :disabled="load" type="primary" @click="submit()" @keyup.enter="submit()">Илгээх</el-button>
            </el-form-item>
            <el-form-item>
                <el-button v-loading="load" :disabled="load" @click="$router.push('signIn')" type="warning">Буцах</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
import { resetPassword } from '@/helpers/auth.js'
export default {
  name: 'Reset',
  data () {
    return {
      emailForm: {
        email: ''
      },
      emailRules: {
        email: [
          { required: true, message: 'Email is required', trigger: 'blur' },
          { type: 'email', message: 'Please enter a valid email address', trigger: ['blur', 'change'] }
        ]
      },
      load: false,
      valid: false,
      error: false
    }
  },
  methods: {
    submit () {
      this.load = true
      this.$refs.emailForm.validate(valid => {
        if (valid) {
          resetPassword(this.emailForm.email).then((response) => {
            console.log(response, 'reseet????')
            if (response) {
              this.$notify.success({
                title: 'Амжилттай',
                message: 'Сэргээх код имэйл хаягруу илгээлээ, имэйл хаягаа шалгаад оруулна уу!'
              })
              this.$router.push({ name: 'forgot', params: { email: this.emailForm.email } })
            } else {
              this.$notify.error({
                title: 'Алдаа гарлаа',
                message: 'Илгээхэд алдаа гарлаа та хэсэг хугацааны дараа дахин оролдоно уу'
              })
            }
          }).catch(error => {
            console.log(error, 'er')
          })
        } else {
          this.load = false
          this.$notify.error({
            title: 'Алдаа гарлаа',
            message: 'Имэйл хаягаа зөв оруулна уу'
          })
        }
      })
    }
  }
}
</script>
