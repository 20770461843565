<template>
  <div id="app">
    <el-row>
      <el-col :span="19">
        <gmap-map :center="center" :zoom="14" :options="options" :draggable="false" style="width: 98%;  height: 95vh;">
          <gmap-marker :position="points" :title="points.lat + ' ' + points.lng" :draggable="true"
            @position_changed="updatePlaceM1($event)">
          </gmap-marker>
        </gmap-map>
      </el-col>
      <el-col :span="5" style="height: 100vh;overflow-y: scroll;">
        <el-button @click="search" type="success">Хайх</el-button>
        <!-- <el-button @click = "search1" type="success">Хайх1</el-button> -->
        <!-- {{addresses[0]}} -->
        <div v-for="address in addresses" :key="address.id" style="margin-top:10px;">
          <el-row style="text-align: left; margin-top:5px">
            <a style="font-size: 12px; font-weight: bold;">{{ address.full_address }}</a>
          </el-row>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import addressServices from '@/services/addressServices.js'
export default {
  created () {
  },
  data () {
    return {
      addresses: [],
      newPod: false,
      newPodObj: {},
      brands: [],
      brand: '',
      outlets: [],
      outlet: '',
      paths: [],
      selectedPod: {},
      options: {
        scrollwheel: true,
        mapTypeControl: true,
        streetViewControl: false,
        minZoom: 4,
        maxZoom: 20,
        gestureHandling: 'greedy'
      },
      center: {
        lat: 47.9174,
        lng: 106.9137
      },
      points: { lat: 47.9174, lng: 106.9137 },
      optionsPoly: {
        fillColor: '#000000',
        strokeColor: '#FF0000',
        strokeOpacity: 0.9,
        fillOpacity: 0.1
      },
      optionsPoly1: {
        fillColor: '#000000',
        strokeColor: '#00FF00',
        strokeOpacity: 0.9,
        fillOpacity: 0.1
      }
    }
  },
  mounted () { },
  methods: {
    async search () {
      try {
        let response = await addressServices.getAddressNearby(this.points.lat, this.points.lng)
        this.addresses = response.data.data
      } catch (error) {
        this.$notify({
          title: 'Алдаа гарлаа',
          message: 'Алдаа: ' + error.response.data.message,
          type: 'error'
        })
      }

      // addressServices.getAddressNearby(this.points.lat, this.points.lng).then(response => {
      //   if (response.data.success) {
      //   }
      // })
    },
    search1 () {
      addressServices.getAddressNearby(this.points.lat, this.points.lng).then(response => {
        if (response.data.success) {
          this.addresses = response.data.data
        }
      })
    },
    updatePlaceM1 (path) {
      this.points.lng = path.lng()
      this.points.lat = path.lat()
    }
  }
}

</script>
