<template>
<div>
  <el-row style="padding:10px">
    <el-col :span="6">
      <el-input v-model="filter.podcode" style="width:90%;" placeholder="Под код ...."></el-input>
    </el-col>
    <el-col :span="4">
    <el-select v-model="filter.outletname" placeholder="Салбар" :clearable="true">
    <el-option v-for="(item,index) in filter.outletDataSource" :key="index" :label="item.outletname" :value="item.outletname"></el-option>
    </el-select>
    </el-col>
    <el-col :span="4">
    <el-button type="primary" @click="getData">Шүүх</el-button>
    </el-col>
  </el-row>
  <el-row style="padding:20px 10px">
        <el-table :data="data" size="mini" v-loading="loading" element-loading-background="rgba(0, 0, 0, 0.3)" border stripe>
          <el-table-column label="Под код" prop="podcode" :sortable="true"></el-table-column>
          <el-table-column label="Салбар" prop="outletname" :sortable="true"></el-table-column>
          <el-table-column label="Засварлсан огноо" prop="updated_at"></el-table-column>
          <el-table-column label="Төлөв" prop="is_active">
            <template slot-scope="scope">
                  <span style="color:green" v-if="scope.row.is_active==1">Идвэхитэй</span>
                  <span style="color:red" v-if="scope.row.is_active==0">Идвэхигүй</span>
                </template>
          </el-table-column>
          <el-table-column label="Үйлдэл">
            <template slot-scope="scope">
              <div style="display:flex;flex-direction:row;">
              <el-button size="mini"  type="primary" @click="handleEdit(scope.row)" icon="el-icon-edit">Засах</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <pod-edit :getData="getData" ></pod-edit>
  </el-row>
</div>
</template>

<script>
import managerServices from '@/services/managerServices.js'
import PodEdit from '../../components/podSetting/podEdit.vue'

export default {
  data: function () {
    return {
      loading: false,
      data: [],
      filter: {
        podcode: '',
        outletname: '',
        outletDataSource: []
      }
    }
  },
  mounted () {},
  components: { PodEdit },
  created () {
    managerServices.getOutlets().then((res) => {
      if (res.statusCode === 200) {
        this.filter.outletDataSource = res.result
      }
    })
    this.getData()
  },
  computed: {},
  methods: {
    getData () {
      this.loading = true
      managerServices.getPodConfigs(this.filter).then((res) => {
        this.loading = false
        if (res.statusCode === 200) {
          this.data = res.result
        } else {
          this.$notify({ type: 'warning', message: 'Мэдээлэл татхад алдаа гарлаа' })
        }
      })
    },
    handleEdit (row) {
      this.$bus.emit('dialogShowPod', Object.assign({}, row))
    }
  },
  watch: {
    'filter.outletname' (val) {
      this.getData()
    }
  }
}
</script>
