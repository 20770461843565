<template>
  <div id="app">
    <el-row style="margin-bottom: 5px">
      <el-col :span="4">
        <el-row>
          <el-col :span="3">
            <h5>Нэр:</h5>
          </el-col>
          <el-col :span="21">
            <el-select v-model="brand" disabled @change="changeBrand" size="mini">
              <el-option v-for="brand in brands" :key="brand.value" :label="brand.value + '(' + brand.count + ')'"
                :value="brand.value">
              </el-option>
            </el-select>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="4">
        <el-row>
          <el-col :span="3">
            <h5>Салбар:</h5>
          </el-col>
          <el-col :span="21">
            <el-select v-model="outlet" placeholder="Салбараа сонгоно уу?" size="mini" @change="changeOutlet">
              <el-option v-for="outlet in outlets" :key="outlet.value" :label="outlet.value + ' (' + outlet.count + ')'"
                :value="outlet.value">
              </el-option>
            </el-select>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="16">
        &nbsp;
      </el-col>
    </el-row>
    <gmap-map :center="center" :zoom="14" :options="options" :draggable="false"
      style="width:100vw;  height: 70vh; margin-top: 5px;">
      <div v-if="newPod === false">
        <div v-for="(item, index) in paths" :key="index">
          <gmap-polygon v-if="item.podcode === selectedPod.podcode" :paths="item.paths" :options="optionsPoly"
            :editable="true" @paths_changed="updatePolygonPaths(item, $event)"
            @click="focusCenterPlot(item.name, item.path)"> </gmap-polygon>
          <gmap-polygon v-else :paths="item.paths" :options="optionsPoly" :editable="false"
            @click="focusCenterPlot(item)"> </gmap-polygon>
        </div>
      </div>
      <div v-else>
        <gmap-polygon :paths="newPodObj.paths" :options="optionsPoly1" :editable="true"
          @paths_changed="updatePolygonPaths1(newPodObj, $event)"> </gmap-polygon>
      </div>
    </gmap-map>
    <div v-if="newPod === false">
      <el-row style="margin-top: 15px; margin-bottom: 15px">
        <el-col :span="4">
          <el-row>
            <el-col :span="3">
              <h5>Нэр:</h5>
            </el-col>
            <el-col :span="21"><el-input v-model="selectedPod.podcode" style="width:200px;"></el-input></el-col>
          </el-row>
        </el-col>
        <el-col :span="4">
          <el-col :span="3">
            <h5>Брэнд:</h5>
          </el-col>
          <el-col :span="21"><el-input v-model="selectedPod.brand" style="width:200px;"></el-input></el-col>
        </el-col>
        <el-col :span="4">
          <el-col :span="3">
            <h5>Салбар:</h5>
          </el-col>
          <el-col :span="21"><el-input v-model="selectedPod.outlet" style="width:200px;"></el-input></el-col>
        </el-col>
        <el-col :span="12">
          <el-button type="danger" @click="newPodFunction" icon="el-icon-plus">Шинээр под нэмэх</el-button>
          <el-button type="primary" @click="reload" icon="el-icon-refresh-left">Шинэчлэх</el-button>
          <el-button type="success" @click="save" icon="el-icon-check">Өөрчлөлтийг хадгалах</el-button>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="2">
          <h5>Захын цэгүүд:</h5>
        </el-col>
        <el-col :span="22"><el-input type="textarea" v-model="selectedPod.poligon"></el-input></el-col>
      </el-row>
    </div>
    <div v-else>
      <el-row style="margin-top: 15px; margin-bottom: 15px">
        <el-col :span="4">
          <el-row>
            <el-col :span="3">
              <h5>Нэр:</h5>
            </el-col>
            <el-col :span="21"><el-input v-model="newPodObj.podcode" style="width:200px;"></el-input></el-col>
          </el-row>
        </el-col>
        <el-col :span="4">
          <el-col :span="3">
            <h5>Брэнд:</h5>
          </el-col>
          <el-col :span="21"><el-input v-model="newPodObj.brand" style="width:200px;"></el-input></el-col>
        </el-col>
        <el-col :span="4">
          <el-col :span="3">
            <h5>Салбар:</h5>
          </el-col>
          <el-col :span="21"><el-input @keydown.native="change" v-model="newPodObj.outlet"
              style="width:200px;"></el-input></el-col>
        </el-col>
        <el-col :span="12">
          <el-button type="primary" @click="reload" icon="el-icon-refresh-left">Шинэчлэх</el-button>
          <el-button type="success" @click="save1" icon="el-icon-check">Хадгалах</el-button>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="2">
          <h5>Захын цэгүүд:</h5>
        </el-col>
        <el-col :span="22"><el-input type="textarea" v-model="newPodObj.poligon"></el-input></el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import addressServices from '@/services/addressServices.js'
export default {
  created () {
    this.changeBrand()
    addressServices.getPods('*', '*', '*').then(data => {
      for (var i = 0; i < data.length; i++) {
        var temp1 = []
        var poligon = data[i].poligon.split(' ')
        for (var j = 0; j < poligon.length; j = j + 2) {
          var temp = {
            lat: 0,
            lng: 0
          }
          temp.lat = parseFloat(poligon[j])
          temp.lng = parseFloat(poligon[j + 1])
          temp1.push(temp)
        }
        data[i].paths = temp1
      }
      this.paths = data
    })
  },
  data () {
    return {
      newPod: false,
      newPodObj: {},
      brands: [
        {
          value: 'PH',
          count: 64
        }
      ],
      brand: 'PH',
      outlets: [],
      outlet: '',
      paths: [],
      selectedPod: {},
      options: {
        scrollwheel: true,
        mapTypeControl: true,
        streetViewControl: false,
        minZoom: 4,
        maxZoom: 20,
        gestureHandling: 'greedy'
      },
      center: {
        lat: 47.9174,
        lng: 106.9137
      },
      points: [
        { lat: 47.89678628, lng: 106.8961749 },
        { lat: 47.8987788, lng: 106.8962714 },
        { lat: 47.89896942, lng: 106.9082716 },
        { lat: 47.89616765, lng: 106.9081697 },
        { lat: 47.89598062, lng: 106.9018611 },
        { lat: 47.89536917, lng: 106.9018826 }
      ],
      optionsPoly: {
        fillColor: '#000000',
        strokeColor: '#FF0000',
        strokeOpacity: 0.9,
        fillOpacity: 0.1
      },
      optionsPoly1: {
        fillColor: '#000000',
        strokeColor: '#00FF00',
        strokeOpacity: 0.9,
        fillOpacity: 0.1
      }
    }
  },
  // mounted () {},
  methods: {
    save1 () {
      addressServices.createPod(this.newPodObj).then(data => {
        if (data === 'created') {
          this.$notify({
            title: 'Амжилттай.',
            message: 'Подыг амжилттай нэмлээ.',
            type: 'success'
          })
        } else {
          this.$notify({
            title: 'Анхааруулга.',
            message: 'Подыг нэмэх үйлдэл амжилтгүй боллоо.',
            type: 'warning'
          })
        }
      })
    },
    newPodFunction () {
      let newObj1 = {}
      newObj1.paths = [
        { 'lat': 47.9223, 'lng': 106.9019 },
        { 'lat': 47.9257, 'lng': 106.9655 },
        { 'lat': 47.9000, 'lng': 106.9203 }
      ]
      newObj1.poligon = '47.9223 106.9019 47.9257 106.9655 47.9000 106.9203'
      newObj1.brand = this.brand
      newObj1.outlet = this.outlet
      newObj1.podcode = 'NewPOD'
      this.newPodObj = newObj1
      this.newPod = true
    },
    reload () {
      this.selectedPod = {}
      let outlet = this.outlet
      if (outlet === '') {
        outlet = '*'
      }
      let brand = this.brand
      if (brand === '') {
        brand = '*'
      }
      addressServices.getPods(brand, outlet, '*').then(data => {
        for (var i = 0; i < data.length; i++) {
          var temp1 = []
          var poligon = []
          if (data[i].poligon) {
            poligon = data[i].poligon.split(' ')
          }
          for (var j = 0; j < poligon.length; j = j + 2) {
            var temp = {
              lat: 0,
              lng: 0
            }
            temp.lat = parseFloat(poligon[j])
            temp.lng = parseFloat(poligon[j + 1])
            temp1.push(temp)
          }
          data[i].paths = temp1
        }
        this.paths = data
        this.newPod = false
      })
    },
    updatePolygonPaths (pod, paths) {
      this.selectedPods = pod
      var str = ''
      console.log(paths.g[0].g[0].lat())
      for (var i = 0; i < paths.g[0].g.length; i++) {
        str =
          str +
          paths.g[0].g[i].lat() +
          ' ' +
          paths.g[0].g[i].lng() +
          ' '
      }
      this.selectedPods.poligon = str.slice(0, -1)
    },
    updatePolygonPaths1 (pod, paths) {
      var str = ''
      for (var i = 0; i < paths.g[0].g.length; i++) {
        str =
          str +
          paths.g[0].g[i].lat() +
          ' ' +
          paths.g[0].g[i].lng() +
          ' '
      }
      this.newPodObj.poligon = str.slice(0, -1)
      this.$forceUpdate()
      console.log(str)
    },
    save () {
      addressServices.updatePod(this.selectedPod).then(data => {
        if (data === 'updated') {
          this.$notify({
            title: 'Амжилттай.',
            message: 'Подыг амжилттай засварлалаа.',
            type: 'success'
          })
        } else {
          this.$notify({
            title: 'Анхааруулга.',
            message: 'Подыг засварлах үйлдэл амжилтгүй боллоо.',
            type: 'warning'
          })
        }
        this.reload()
      })
    },
    focusCenterPlot (pod) {
      this.selectedPod = pod
    },
    changeOutlet () {
      addressServices.getPods(this.brand, this.outlet, '*').then(data => {
        for (var i = 0; i < data.length; i++) {
          var temp1 = []
          var poligon = []
          if (data[i].poligon) {
            poligon = data[i].poligon.split(' ')
          }
          for (var j = 0; j < poligon.length; j = j + 2) {
            var temp = {
              lat: 0,
              lng: 0
            }
            temp.lat = parseFloat(poligon[j])
            temp.lng = parseFloat(poligon[j + 1])
            temp1.push(temp)
          }
          data[i].paths = temp1
        }
        this.paths = data
      })
    },
    changeBrand () {
      console.log(this.brand)
      addressServices.getOutletsByPods(this.brand).then(data => {
        this.outlets = data
      })
      addressServices.getPods(this.brand, '*', '*').then(data => {
        for (var i = 0; i < data.length; i++) {
          var temp1 = []
          var poligon = []
          if (data[i].poligon) {
            poligon = data[i].poligon.split(' ')
          }
          for (var j = 0; j < poligon.length; j = j + 2) {
            var temp = {
              lat: 0,
              lng: 0
            }
            temp.lat = parseFloat(poligon[j])
            temp.lng = parseFloat(poligon[j + 1])
            temp1.push(temp)
          }
          data[i].paths = temp1
        }
        this.paths = data
      })
    },
    change () {
      this.$nextTick(() => {
        this.$forceUpdate()
      })
    }
  }
}

</script>
