<template>
  <div>
    <el-table v-loading="orders == null"
      :data="orders.filter(data => !search || data.order_number.toString().toLowerCase().includes(search.toString().toLowerCase()) || data.phone.toString().toLowerCase().includes(search.toString().toLowerCase()) || data.menu_total.toString().toLowerCase().includes(search.toString().toLowerCase()))"
      style="width: 100%" size="mini" height="77vh">
      <el-table-column label="№" width="80">
        <template slot-scope="scope">
          <span>{{ scope.row.order_number }}</span>
        </template>
      </el-table-column>
      <el-table-column v-if="outlets.length > 1" label="Салбар" width="160" :filters="outlet_names"
        :filter-method="filterHandlerOutlet">
        <template slot-scope="scope">
          <span>{{ scope.row.outlet_name }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Захиалсан цаг" width="120">
        <template slot-scope="scope">
          <i class="el-icon-time"></i>
          <span style="margin-left: 10px">{{ scope.row.created_at }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Хүргэх цаг" width="120">
        <template slot-scope="scope">
          <i class="el-icon-time"></i>
          <span style="margin-left: 10px">{{ scope.row.promised_time }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Утас" width="100">
        <template slot-scope="scope">
          <span>{{ scope.row.phone }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="pod_name" label="POD" width="100" :filters="pods" :filter-method="filterHandlerPods">
        <template slot-scope="scope">
          <span>{{ scope.row.podcode }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="channel" label="Суваг" width="100" :filters="channels"
        :filter-method="filterHandlerChannels">
        <template slot-scope="scope">
          <span>{{ scope.row.channel }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="order_refer" label="Гүйлгээний утга" width="100" :filters="order_refers"
        :filter-method="filterHandlerOrderRefers">
        <template slot-scope="scope">
          <span>{{ scope.row.order_refer }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="pay_type_text" label="Төлбөрийн төрөл" width="100" :filters="pay_type_texts"
        :filter-method="filterHandlerPayTypeText">
        <template slot-scope="scope">
          <span>{{ scope.row.pay_type_text }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Үнэ" width="90">
        <template slot-scope="scope">
          <span>{{ parseInt(scope.row.pay_total) }}₮</span>
        </template>
      </el-table-column>
      <el-table-column prop="order_status" label="Төлөв" width="130" :filters="status"
        :filter-method="filterHandlerStatus">
        <template slot-scope="scope">
          <el-tag type="success">{{ scope.row.order_status_text }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="order_status" label="Rkeeper дугаар" width="130">
        <template slot-scope="scope">
          <span v-if="scope.row.rkeeper_id !== null" type="success">{{ scope.row.rkeeper_id }}</span>
          <span v-if="scope.row.rkeeper_id === null" type="success">Хоосон</span>
        </template>
      </el-table-column>
      <el-table-column prop="transport_type" label="Т/Х төрөл" width="130" :filters="transport_types"
        :filter-method="filterHandlerTransportType">
        <template slot-scope="scope">
          <span v-if="scope.row.transport_type !== null" type="success">{{ scope.row.transport_type }}</span>
          <span v-if="scope.row.transport_type === null" type="success">хүргэлтэнд гараагүй</span>
        </template>
      </el-table-column>
      <el-table-column prop="driver" label="Жолооч" :filters="drivers" width="160" :filter-method="filterHandlerDriver">
        <template slot-scope="scope">
          <el-tag style="width: 130px" closable @close="unUsignedDriver(scope.row)"
            v-if="scope.row.driver && scope.row.order_status == 'Хүргэлтэнд гарсан'" :type="'danger'">
            {{ scope.row.driver_name }}
          </el-tag>
          <el-tag v-else style="width: 130px" :type="'success'">{{ scope.row.driver_name }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="address" label="Хаяг" class="addressCol">
        <template slot="header" slot-scope="scope">
          <el-input v-model="search" size="mini" :ref="scope" placeholder="Хайх" />
        </template>
        <template slot-scope="scope">
          {{ scope.row.addr_bair }}
        </template>
      </el-table-column>
      <!-- <el-table-column label="" fixed="right" width="200">
        <template slot="header" slot-scope="scope">
          <el-input v-model="search" size="mini" :ref="scope" placeholder="Хайх" />
        </template>
        <template slot-scope="scope">
          <el-button size="mini" type="danger" :disabled="scope.row.order_status!=='Амжилттай'"  @click="showPaymentChange(scope.row)">Засах</el-button>
          <el-button size="mini" type="warning" :disabled="scope.row.order_status==='Амжилттай'" @click="showTimeChange(scope.row)">Шилжүүлэх</el-button>
        </template>
      </el-table-column> -->
      <el-table-column width="80" fixed="right">
        <template slot-scope="scope">
          <el-button icon="el-icon-more" type="success" circle @click="open(scope.row)"></el-button>
        </template>
      </el-table-column>
    </el-table>
    <a style="float: right; margin-right: 20px;">Нийт: {{ orders.length }}</a>
    <el-dialog v-if="dialogVisible" :visible.sync="dialogVisible" width="50%" :before-close="handleClose">
      <div class="dialog-body" v-loading="!load">
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="orderInfo">
              <h3><i class="fas fa-shopping-cart"></i>&nbsp;&nbsp;ЗАХИАЛГА</h3>
              <ul>
                <li>Захиалгын дугаар: {{ order.order_number }}</li>
                <li>Утасны дугаар: {{ order.phone }}</li>
                <li>Захиалсан цаг: {{ order.created_at }}</li>
                <li>Төлбөрийн мэдээлэл: {{ order.is_paid_text }}</li>
                <li>Хүргэж өгөх цаг: {{ order.promised_time }}</li>
                <li>Хүргэлтийн үнэ: {{ order.delivery_charge }}</li>
                <li>Жолооч: {{ order.driver_name }}</li>
                <li>Суваг: {{ order.channel }}</li>
                <li>Салбар: {{ order.outlet_name }} </li>
                <li>Төлөв: {{ order.order_status_text }} </li>
                <li v-if="order.order_status == 100">Хүргэж өгсөн тээврийн хэрэгсэл: {{ order.transport_type }}</li>
              </ul>
              <h3> <i class="fas fa-map-marker-alt"></i>&nbsp;&nbsp;ХАЯГ</h3>
              <ul>
                <li>Хаяг: {{ order.addr_bair }}</li>
                <li>Албан бус хаяг: {{ order.addr_tailbar }}</li>
                <li>Тайлбар: {{ order.addr_orts }}</li>
                <li>Орц: {{ order.addr_note }}</li>
              </ul>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="productInfo">
              <h3><i class="fas fa-shopping-basket"></i>&nbsp;&nbsp;БҮТЭЭГДЭХҮҮН</h3>
              <el-table :summary-method="getSummaries" show-summary :data="tableData" height="400">
                <el-table-column label="Бүтээгдэхүүн" prop="name_mon"></el-table-column>
                <el-table-column label="Тоо" prop="item_count"></el-table-column>
                <el-table-column label="Үнэ" prop="item_price">
                  <template slot-scope="scope">
                    {{ parseInt(scope.row.item_price) * parseInt(scope.row.item_count) }}
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-col>
        </el-row>
      </div>
      <span slot="footer">
        <el-button type="danger" style="float: left" v-if="outlets.length == 1"
          @click="cancelOrderShow()">Цуцлах</el-button>
        <el-button type="success" style="float: left" @click="getOrderChange(order.order_uuid)">Түүх харах</el-button>
        <el-button type="warning" style="float: left" v-if="order.order_status == 50"
          @click="unUsignedDriver(order.order_uuid)">Жолооч цуцлах</el-button>
        <el-button type="info" @click="handleClose">Гарах</el-button>
      </span>
    </el-dialog>
    <el-dialog title="Захиалгийн түүх" :visible.sync="innerVisible">
      <el-table :data="innerData" size="mini">
        <el-table-column property="oldStatus" label="Хуучин төлөв" width="180"></el-table-column>
        <el-table-column property="newStatus" label="Шинэ төлөв" width="180"></el-table-column>
        <el-table-column property="reason" label="Тайлбар" width="200"></el-table-column>
        <el-table-column property="description" label="Дэлгэрэнгүй" width="120"></el-table-column>
        <el-table-column property="user_name" label="Хэрэглэгч" width="120"></el-table-column>
        <el-table-column property="created_at" label="Огноо" width="85"></el-table-column>
      </el-table>
    </el-dialog>
    <el-dialog :visible.sync="dialogVisible1" width="450px" :before-close="handleClose1">
      <div class="dialog-body">
        <div class="productInfo">
          <h3><i class="fas fa-ban"></i>&nbsp;&nbsp;Захиалга цуцлах</h3>
          <el-row style="margin-top: 25px">
            <el-col :span="10">
              <h3>Захиалгын дугаар:</h3>
            </el-col>
            <el-col :span="14"><el-input v-model="order.order_number" :disabled="true"></el-input></el-col>
          </el-row>
          <el-row style="margin-top: 25px">
            <el-col :span="10">
              <h3>Захиалгын үнэ:</h3>
            </el-col>
            <el-col :span="14"><el-input v-model="order.pay_total" :disabled="true"></el-input></el-col>
          </el-row>
          <el-row style="margin-top: 25px">
            <el-col :span="10">
              <h3>Шалтгаан:</h3>
            </el-col>
            <el-select v-model="payload.reason">
              <el-option v-for="(item, index) in reasons" :key="index" :value="item" :label="item"></el-option>
            </el-select>
          </el-row>
          <el-row style="margin-top: 25px">
            <el-col :span="10">
              <h3>Тайлбар:</h3>
            </el-col>
            <el-col :span="14"><el-input v-model="payload.desc" type="textarea"></el-input></el-col>
          </el-row>
        </div>
      </div>
      <span slot="footer">
        <el-button type="danger" style="float: left" @click="cancelOrder()">Цуцлах</el-button>
        <el-button type="info" @click="handleClose1">Гарах</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import orderServices from '@/services/orderServices.js'
import driverServices from '@/services/driverServices.js'
import { getGroups, getUserName } from '@/utils/auth.js'
export default {
  components: {
  },
  props: ['orders'],
  created () {
    getUserName().then(data => {
      this.user = data.username.split('@')[0]
    })
    getGroups().then(data => {
      const index = data.indexOf('AddressEditor')
      if (index > -1) {
        data.splice(index, 1)
      }
      const index1 = data.indexOf('MenuEditor')
      if (index1 > -1) {
        data.splice(index1, 1)
      }
      this.outlets = data
    })
    this.drivers = []
    this.status = []
    this.outlet_names = []
    this.pods = []
    this.channels = []
    this.order_refers = []
    this.pay_type_texts = []
    this.transport_types = []
    this.orders.forEach(element => {
      let found = false
      let founds = false
      let foundp = false
      let found12 = false
      let foundc = false
      let foundor = false
      let foundptt = false
      let foundtt = false
      this.outlet_names.forEach(x => {
        if (x.value === element.outlet_name) {
          found12 = true
        }
      })
      this.drivers.forEach(x => {
        if (x.value === element.driver_name) {
          found = true
        }
      })
      this.status.forEach(x => {
        if (x.value === element.order_status_text) {
          founds = true
        }
      })
      this.pods.forEach(x => {
        if (x.value === element.podcode) {
          foundp = true
        }
      })
      this.channels.forEach(x => {
        if (x.value === element.channel) {
          foundc = true
        }
      })
      this.order_refers.forEach(x => {
        if (x.value === element.order_refer) {
          foundor = true
        }
      })
      this.pay_type_texts.forEach(x => {
        if (x.value === element.pay_type_text) {
          foundptt = true
        }
      })
      this.transport_types.forEach(x => {
        if (x.value === element.transport_type) {
          foundtt = true
        }
      })
      if (!found12) {
        let temp1 = {}
        temp1.text = element.outlet_name
        temp1.value = element.outlet_name
        this.outlet_names.push(temp1)
      }
      if (!found) {
        let temp1 = {}
        temp1.text = element.driver_name
        temp1.value = element.driver_name
        this.drivers.push(temp1)
      }
      if (!foundp) {
        let temp3 = {}
        temp3.text = element.podcode
        temp3.value = element.podcode
        this.pods.push(temp3)
      }
      if (!founds) {
        let temp2 = {}
        temp2.text = element.order_status_text
        temp2.value = element.order_status_text
        this.status.push(temp2)
      }
      if (!foundc) {
        let temp2 = {}
        temp2.text = element.channel
        temp2.value = element.channel
        this.channels.push(temp2)
      }
      if (!foundor) {
        let temp2 = {}
        temp2.text = element.order_refer
        temp2.value = element.order_refer
        this.order_refers.push(temp2)
      }
      if (!foundptt) {
        let temp2 = {}
        temp2.text = element.pay_type_text
        temp2.value = element.pay_type_text
        this.pay_type_texts.push(temp2)
      }
      if (!foundtt) {
        let temp2 = {}
        temp2.text = element.transport_type
        temp2.value = element.transport_type
        this.transport_types.push(temp2)
      }
    })
  },
  data () {
    return {
      user: '',
      innerVisible: false,
      innerData: [],
      outlets: [],
      load: false,
      dialogVisible: false,
      dialogVisible1: false,
      payload: {},
      order: {},
      reasons: ['Хоцорч ирсэн', 'Хуурамч дуудлага', 'Хэрэглэгч байхгүй', 'Хаягаа өөрчилсөн', 'Бүтээгдэхүүн буруу', 'Бүтээгдэхүүн өөрчилсөн'],
      tableData: [],
      full_address: '',
      addr_desc: '',
      drivers: [],
      pods: [],
      status: [],
      outlet_names: [],
      search: '',
      channels: [],
      order_refers: [],
      pay_type_texts: [],
      transport_types: []
    }
  },
  methods: {
    getOrderChange (id) {
      let body = {
        id: id
      }
      orderServices.getOrderChanges(body).then(data => {
        if (data.statusCode === 200) {
          data.result.forEach(element => {
            switch (element.old_status) {
              case 14:
                element.oldStatus = 'Веб төлбөр шалгах /урьдчилсан захиалга/'
                break
              case 15:
                element.oldStatus = 'Веб төлбөр шалгах'
                break
              case 20:
                element.oldStatus = 'Баталгаажуулсан'
                break
              case 30:
                element.oldStatus = 'Гал тогоо хүлээж авсан'
                break
              case 50:
                element.oldStatus = 'Хүргэлтэд гарсан'
                break
              case 91:
                element.oldStatus = 'Цуцалсан'
                break
              case 100:
                element.oldStatus = 'Амжилттай'
                break
              default:
                element.oldStatus = 'Буруу утга' + element.old_status
                break
            }
            switch (element.new_status) {
              case 14:
                element.oldStatus = 'Веб төлбөр шалгах /урьдчилсан захиалга/'
                break
              case 15:
                element.oldStatus = 'Веб төлбөр шалгах'
                break
              case 20:
                element.newStatus = 'Баталгаажуулсан'
                break
              case 30:
                element.newStatus = 'Гал тогоо хүлээж авсан'
                break
              case 50:
                element.newStatus = 'Хүргэлтэд гарсан'
                break
              case 91:
                element.newStatus = 'Цуцалсан'
                break
              case 100:
                element.newStatus = 'Амжилттай'
                break
              default:
                element.newStatus = 'Буруу утга' + element.old_status
                break
            }
          })
          this.innerData = data.result
          this.innerVisible = true
        }
        // console.log('s')
      })
    },
    getSummaries (param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = 'Нийт:'
          return
        } else if (data.length === 0) {
          sums[index] = '0'
        }
        let values = data.map(item => item[column.property])
        if (column.property === 'item_price') {
          values = data.map(item => parseInt(item[column.property]) * parseInt(item['item_count']))
        }
        // buteegdehuun tooloh bol neene //

        // if (column.property === 'item_count') {
        //   values = data.map(item => item['parent'] === 0 ? parseInt(item['item_count']) : 0)
        // }

        sums[index] = values.reduce((prev, curr) => {
          const value = parseInt(curr)
          return prev + value
        }, 0)
      })
      return sums
    },
    unUsignedDriver (id) {
      let self = this
      this.$confirm('Уг захиалгын жолоочийг өөрчлөх гэж байна уу?', 'Анхааруулга', {
        confirmButtonText: 'Өөрчлөх',
        cancelButtonText: 'Гарах',
        type: 'warning'
      }).then(() => {
        let body = {
          id: id,
          user_name: self.user
        }
        driverServices.unUsignedDriver(body).then(data => {
          if (data.statusCode === 200) {
            this.$message({
              type: 'success',
              message: 'Захиалгад оноосон жолоочийг цуцаллаа!'
            })
          } else {
            this.$message({
              type: 'warning',
              message: 'Алдаа гарлаа!'
            })
          }
        })
      })
    },
    showPaymentChange (refer) {
      this.$bus.emit('dialogShowPaymentChange', refer)
    },
    showTimeChange (refer) {
      this.$bus.emit('dialogShowTimeChange', refer)
    },
    handleClose () {
      this.dialogVisible = false
    },
    handleClose1 () {
      this.payload = {}
      this.dialogVisible1 = false
    },
    cancelOrder () {
      this.payload.order_uuid = this.order.order_uuid
      this.payload.user_name = this.user
      // console.log(this.payload)
      orderServices.cancelOrder(this.payload).then(data => {
        if (data.statusCode) {
          this.payload = {}
          this.dialogVisible1 = false
          this.$notify({
            title: data.message,
            data: data.message,
            type: 'success'
          })
        }
      })
    },
    cancelOrderShow () {
      this.dialogVisible1 = true
    },
    open (order) {
      let payload = {
        order_uuid: order.order_uuid
      }
      orderServices.getOrderByUuid(payload).then(data => {
        if (data.statusCode) {
          this.load = false
          this.dialogVisible = true
          this.order = data.result
          this.tableData = data.result.food_details
          this.load = true
        }
      })
    },
    filterHandlerDriver (value, row) {
      return row.driver_name === value
    },
    filterHandlerPods (value, row) {
      return row.podcode === value
    },
    filterHandlerStatus (value, row) {
      return row.order_status_text === value
    },
    filterHandlerOutlet (value, row) {
      return row.outlet_name === value
    },
    filterHandlerChannels (value, row) {
      return row.channel === value
    },
    filterHandlerOrderRefers (value, row) {
      return row.order_refer === value
    },
    filterHandlerPayTypeText (value, row) {
      return row.pay_type_text === value
    },
    filterHandlerTransportType (value, row) {
      return row.transport_type === value
    }
  }
}

</script>
