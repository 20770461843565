
<template>
    <div class="container">
        <div class="header">
            <div class="search">
                <el-input placeholder="Утасны дугаар хайх" type="number" v-model="search"></el-input>
                <el-button type="success" @click="() => { handleSearch() }">
                    Хайх
                </el-button>
            </div>
            <el-button type="success" @click="() => modalOpen = true">
                Нэмэх
            </el-button>
        </div>
        <div class="content">
            <el-table v-loading="isLoading" size="mini" :data="list" stripe style="width: 100%">
                <el-table-column prop="phone" label="Утасны дугаар"></el-table-column>
                <el-table-column prop="reason" label="Шалтгаан"></el-table-column>
                <el-table-column prop="emp" label="Оператор"></el-table-column>
                <el-table-column prop="created_at" label="Үүссэн"></el-table-column>
                <el-table-column label="" width="200">
                    <template slot-scope="scope">
                        <div class="action">
                            <el-button type="danger" size="mini" @click="handleDelete(scope.row.id)">Устгах</el-button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination class="pagination" background :current-page="pagination.pageNumber"
                @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-size="pagination.pageSize"
                :page-sizes="[10, 20, 50, 100]" :total="total" layout="total, sizes, prev, pager, next, jumper" />
        </div>

        <el-dialog title="Жагсаалтанд нэмэх" :visible.sync="modalOpen" width="480px">
            <el-form ref="form" :model="form" label-width="120px" :rules="rules">
                <el-form-item label="Утасны дугаар" prop="phone">
                    <el-input type="number" v-model="form.phone"></el-input>

                </el-form-item>
                <el-form-item label="Шалтгаан" label-width="120px" prop="reason">
                    <el-input maxlength="255" type="textarea" v-model="form.reason"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="() => modalOpen = false">Хаах</el-button>
                    <el-button type="success" @click="submitForm('form')">Хадгалах</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>

    </div>
</template>

<script>
import managerServices from '@/services/managerServices.js'
export default {
    created () {
        this.getBlackList()
    },
    data () {
        return {
            list: [],
            isLoading: false,
            pagination: {
                pageNumber: 1,
                pageSize: 10,
            },
            total: 0,
            search: '',
            form: {
                phone: '',
                reason: '',
            },
            modalOpen: false,
            rules: {
                phone: [
                    { required: true, message: 'Утасны дугаар оруулна уу', trigger: 'change' }
                ],
                reason: [
                    { required: true, message: 'Шалтгаан оруулна уу', trigger: 'change' }
                ],
            },
        }
    },
    methods: {
        handleSizeChange (value) {
            this.pagination = { pageNumber: 1, pageSize: value }
            this.getBlackList()

        },
        handleCurrentChange (value) {
            this.pagination = { ...this.pagination, pageNumber: value }
            this.getBlackList()
        },
        handleClose () {
            this.modalOpen = false;
            this.isLoading = false;
            this.form = {
                phone: '',
                reason: '',
            }
        },
        getBlackList () {
            this.isLoading = true;
            const body = {
                ...this.pagination,
                ...(this.search ? { phone: this.search } : {})
            }
            try {
                managerServices.getBlackList(body).then(response => {
                    this.isLoading = false;
                    if (response.success) {
                        this.list = response.result.rows
                        this.total = response.result.count
                    } else {
                        this.$notify({
                            title: 'Алдаа гарлаа',
                            message: response.message,
                            type: 'error'
                        })
                    }

                })
            } catch (error) {
                this.isLoading = false;
            }


        },
        create (data) {
            this.isLoading = true;
            managerServices.createBlacklist(data).then(response => {
                this.handleClose()
                if (response.success) {
                    this.$notify({
                        title: 'Амжилттай.',
                        message: 'Жагсаалтанд нэмэгдлээ.',
                        type: 'success'
                    })
                    this.getBlackList()
                } else {
                    this.$notify({
                        title: 'Алдаа гарлаа',
                        message: response.message,
                        type: 'warning'
                    })
                }

            })
        },
        submitForm (formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.create(this.form)
                }
            })
        },
        handleDelete (id) {
            this.$confirm('Энэ мэдээлэлийг бүр устгах гэж байна Үнэхээр итгэлтэй байна уу?', 'Warning', {
                confirmButtonText: 'Тийм',
                cancelButtonText: 'Болих',
                type: 'warning'
            }).then(() => {
                this.isLoading = true;
                managerServices.removeBlacklist(id).then(response => {
                    this.handleClose()
                    if (response.success) {
                        this.$notify({
                            title: 'Амжилттай.',
                            message: 'Жагсаалтнаас амжилттай устгалаа.',
                            type: 'success'
                        })
                        this.getBlackList()
                    } else {
                        this.$notify({
                            title: 'Алдаа гарлаа',
                            message: response.message,
                            type: 'warning'
                        })
                    }

                })
            }).catch((e) => {
                // this.$message({
                //     type: 'info',
                //     message: 'Delete canceled'
                // })
            })
        },
        handleSearch () {
            this.getBlackList()
        }
    }
}
</script>

<style scoped lang="scss">
.container {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .header {
        display: flex;
        justify-content: space-between;

        .search {
            display: flex;
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .pagination {
            float: right;
            text-align: right;
        }
    }
}
</style>
//hasComp