<template>
  <div class="deliveryOrderLayout">
    <el-row>
      <el-col :span="5">
        <el-date-picker v-model="datetime1" type="datetime" placeholder="Эхлэх огноо" >
          </el-date-picker>
      </el-col>
      <el-col :span="5">
        <el-date-picker v-model="datetime2" type="datetime" placeholder="Дуусах огноо" >
          </el-date-picker>
      </el-col>
      <el-col :span="4">
        <el-select v-model="selectedChannel" placeholder="Сувагаа сонгоно уу">
          <el-option
            v-for="item in channels"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="2">
        <el-button type="primary" @click="reloadOrders()" >Боловсруулах</el-button>
      </el-col>
    </el-row>
    <el-tabs v-model="activeTab" type="border-card" @tab-click="tabClick(activeTab)" style="margin-top:20px; height: 81wh;padding-bottom: 0;">
      <el-tab-pane label="Хүргэлтийн захиалгууд" name="Хүргэлтийн захиалгууд" >
        <div v-if="activeTab == 'Хүргэлтийн захиалгууд'" v-loading="orderLoad">
          <div style="height:76vh;">
            <food-orders :orders="orders" v-if="!orderLoad"></food-orders>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="Урьдчилсан захиалгууд" name="Урьдчилсан захиалгууд">
        <div v-if="activeTab == 'Урьдчилсан захиалгууд'" v-loading="orderLoad">
          <div style="height:76vh;">
            <food-orders :orders="orders" v-if="!orderLoad"></food-orders>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="Ирж авах захиалгууд" name="Ирж авах захиалгууд">
        <div v-if="activeTab == 'Ирж авах захиалгууд'" v-loading="orderLoad">
          <div style="height:76vh;">
            <food-orders :orders="orders" v-if="!orderLoad"></food-orders>
          </div>
        </div>
      </el-tab-pane>
      <div class="showMore">
        <el-button style="width:100%" size="mini">Дэлгэрэнгүй</el-button>
      </div>
    </el-tabs>
    <driver-add></driver-add>
    <day-balance></day-balance>
  </div>
</template>
<script>
import FoodOrders from './foodOrders'
import orderServices from '@/services/orderServices.js'
import DriverAdd from './driverAdd'
import DayBalance from './dayBalance'
import { getGroups } from '@/utils/auth.js'
export default {
  components: { FoodOrders, DriverAdd, DayBalance },
  created () {
    getGroups().then(data => {
      const index = data.indexOf('AddressEditor')
      if (index > -1) {
        data.splice(index, 1)
      }
      const index1 = data.indexOf('MenuEditor')
      if (index1 > -1) {
        data.splice(index1, 1)
      }
      this.outlets = data
      let hours = (new Date()).getHours()
      if (hours < 8) {
        this.datetime1 = new Date(new Date((new Date()).getFullYear(), (new Date()).getMonth(), (new Date()).getDate() - 1, 0, 0, 0).setHours(8))
        this.datetime2 = new Date(new Date((new Date()).getFullYear(), (new Date()).getMonth(), (new Date()).getDate(), 0, 59, 59).setHours(hours))
      } else {
        this.datetime1 = new Date(new Date((new Date()).getFullYear(), (new Date()).getMonth(), (new Date()).getDate(), 0, 0, 0).setHours(8))
        this.datetime2 = new Date(new Date((new Date()).getFullYear(), (new Date()).getMonth(), (new Date()).getDate() + 1, 0, 0, 0).setHours(8))
      }
      this.reloadOrders()
    })
  },
  data () {
    return {
      outlets: [],
      orderLoad: true,
      orders: [],
      activeTab: 'Хүргэлтийн захиалгууд',
      datetime1: new Date(new Date((new Date()).getFullYear(), (new Date()).getMonth(), (new Date()).getDate(), 0, 0,
        0).setHours(10)),
      datetime2: new Date(new Date((new Date()).getFullYear(), (new Date()).getMonth(), (new Date()).getDate(), 0, 0,
        0).setHours(23)),
      pickerOptions1: {
        disabledDate (time) {
          return time.getTime() > Date.now()
        }
      },
      pickerOptions2: {
        disabledDate (time) {
          return time.getTime() > Date.now()
        }
      },
      dialogVisible: false,
      tableData2: [],
      selectedChannel: 'all',
      channels: [{
        value: 'all',
        label: 'Бүгд'
      }, {
        value: 'new CC',
        label: 'new CC'
      }, {
        value: 'web',
        label: 'web'
      }, {
        value: 'chatbot',
        label: 'chatbot'
      }]
    }
  },
  methods: {
    tabClick (data) {
      this.reloadOrders()
    },
    reloadOrders () {
      if ((this.datetime1 === null || this.datetime2 === null) || (this.datetime1 > this.datetime2)) {
        this.$notify({
          title: 'Анхааруулга',
          message: 'Таны сонгосон өдрөөр захиалгыг харуулах боломжгүй байна.',
          type: 'warning'
        })
      } else {
        this.orderLoad = true
        var aa = new Date(this.datetime1.toString().split('GMT')[0] + ' UTC').toISOString().slice(0, 19).replace('T', ' ')
        var ab = new Date(this.datetime2.toString().split('GMT')[0] + ' UTC').toISOString().slice(0, 19).replace('T', ' ')
        if (this.activeTab === 'Хүргэлтийн захиалгууд') {
          orderServices.getFoodOrderTimeRange(this.outlets, 20, 100, 500, 1, aa, ab, this.selectedChannel).then(data => {
            if (data.statusCode === 200) {
              this.orders = data.result
              this.orderLoad = false
            }
          })
        } else if (this.activeTab === 'Ирж авах захиалгууд') {
          orderServices.getFoodOrderTimeRange(this.outlets, 18, 100, 500, 2, aa, ab, this.selectedChannel).then(data => {
            if (data.statusCode === 200) {
              this.orders = data.result
              this.orderLoad = false
            }
          })
        } else {
          orderServices.getFoodOrderTimeRange(this.outlets, 19, 19, 500, 1, aa, ab, this.selectedChannel).then(data => {
            if (data.statusCode === 200) {
              this.orders = data.result
              this.orderLoad = false
            }
          })
        }
      }
    }
  }
}
</script>
<style lang="scss">
 .showMore {
   padding: 5px;
   border-radius: 7px;
   display: block;
 }
</style>
