<template>
  <div class="stats">
    <el-dialog v-if="dialogVisible" :visible.sync="dialogVisible" width="50%" :before-close="handleClose">
      <div class="dialog-body" v-loading="!load">
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="orderInfo">
              <h3><i class="fas fa-shopping-cart"></i>&nbsp;&nbsp;ЗАХИАЛГА</h3>
              <ul>
                <li>Захиалгын дугаар: {{order.order_number}}</li>
                <li>Утасны дугаар: {{order.phone}}</li>
                <li>Захиалсан цаг: {{order.created_at}}</li>
                <li>Төлбөрийн мэдээлэл: {{order.is_paid_text}}</li>
                <li>Хүргэж өгөх цаг: {{order.promised_time}}</li>
                <li>Жолооч: {{order.driver_name}}</li>
              </ul>
              <h3> <i class="fas fa-map-marker-alt"></i>&nbsp;&nbsp;ХАЯГ</h3>
              <ul>
                <li>Хаяг: {{order.addr_bair}}</li>
                <li>Албан бус хаяг: {{order.addr_tailbar}}</li>
              </ul>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="productInfo">
              <h3><i class="fas fa-shopping-basket"></i>&nbsp;&nbsp;БҮТЭЭГДЭХҮҮН</h3>
              <el-table show-summary :data="tableData" height="400">
                <el-table-column label="Бүтээгдэхүүн" prop="name_mon"></el-table-column>
                <el-table-column label="Тоо" prop="item_count"></el-table-column>
                <el-table-column label="Үнэ" prop="item_price"></el-table-column>
              </el-table>
            </div>
          </el-col>
        </el-row>
      </div>
      <span slot="footer">
        <el-button type="danger" style="float: left" @click="cancelOrderShow()">Цуцлах</el-button>
        <el-button type="info" @click="handleClose">Гарах</el-button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="dialogVisible1" width="450px" :before-close="handleClose1">
      <div class="dialog-body">
        <div class="productInfo">
          <h3><i class="fas fa-ban"></i>&nbsp;&nbsp;Захиалга цуцлах</h3>
          <el-row style="margin-top: 25px">
            <el-col :span="10"><h3>Захиалгын дугаар:</h3></el-col>
            <el-col :span="14"><el-input v-model="order.order_number" :disabled="true"></el-input></el-col>
          </el-row>
          <el-row style="margin-top: 25px">
            <el-col :span="10"><h3>Захиалгын үнэ:</h3></el-col>
            <el-col :span="14"><el-input v-model="order.pay_total" :disabled="true"></el-input></el-col>
          </el-row>
          <el-row style="margin-top: 25px">
            <el-col :span="10"><h3>Шалтгаан:</h3></el-col>
            <el-select v-model="payload.reason">
              <el-option v-for="(item, index) in reasons" :key="index" :value="item" :label="item"></el-option>
            </el-select>
          </el-row>
          <el-row style="margin-top: 25px">
            <el-col :span="10"><h3>Тайлбар:</h3></el-col>
            <el-col :span="14"><el-input v-model="payload.desc" type="textarea"></el-input></el-col>
          </el-row>
        </div>
      </div>
      <span slot="footer">
        <el-button type="danger" style="float: left" @click="cancelOrder()">Цуцлах</el-button>
        <el-button type="info" @click="handleClose1">Гарах</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import orderServices from '@/services/orderServices.js'
export default {
  created () {
    let self = this
    this.$bus.$on('dialogShow', function (orderUuid) {
      self.show(orderUuid)
    })
    this.$bus.$on('dialogShowMap', function (orderUuid) {
      self.show(orderUuid)
    })
    this.$root.$on('dialogShowFoodOrder', function (orderUuid) {
      self.show(orderUuid)
    })
  },
  data () {
    return {
      load: false,
      dialogVisible: false,
      dialogVisible1: false,
      payload: {},
      order: {},
      reasons: ['Хоцорч ирсэн', 'Хуурамч дуудлага', 'Хэрэглэгч байхгүй', 'Хаягаа өөрчилсөн', 'Бүтээгдэхүүн буруу', 'Бүтээгдэхүүн өөрчилсөн'],
      tableData: [],
      full_address: '',
      addr_desc: ''
    }
  },
  methods: {
    show (param) {
      // console.log('sdfsdf')
      this.load = false
      this.dialogVisible = true
      let payload = {
        order_uuid: param
      }
      orderServices.getOrderByUuid(payload).then(data => {
        if (data.statusCode === 200) {
          this.order = data.result
          console.log(this.order)
          // if (this.order.custAdd) {
          //   this.full_address = this.order.full_address
          //   this.addr_desc = this.order.custAdd.addr_desc
          // } else {
          //   this.full_address = ''
          //   this.addr_desc = ''
          // }
          this.tableData = data.result.food_details
          this.load = true
        }
      })
    },
    handleClose () {
      this.dialogVisible = false
    },
    handleClose1 () {
      this.payload = {}
      this.dialogVisible1 = false
    },
    cancelOrder () {
      this.payload.order_uuid = this.order.order_uuid
      this.payload.user_name = this.user
      orderServices.cancelOrder(this.payload).then(data => {
        if (data.statusCode === 200) {
          this.payload = {}
          this.dialogVisible1 = false
          this.$notify({
            title: data.message,
            data: data.message,
            type: 'success'
          })
        }
      })
    },
    cancelOrderShow () {
      this.dialogVisible1 = true
    }
  }
}
</script>
<style>
  .orderInfo .el-table__header-wrapper {
    display: none;
  }

  .el-dialog__body {
    padding: 30px 35px !important
  }

  .stats h3 {
    color: rgb(59, 59, 59);
    font-size: 18px;
    font-weight: 900 !important;
  }
  .el-dialog {
    margin-top: 10vh;
  }
  .el-dialog__body {
    height: 90%;
  }
  .orderInfo ul li {
    line-height: 25px;
  }
</style>
