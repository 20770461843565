import config from '../config'
import axios from 'axios'
import { getAccessToken, signOut } from '../utils/auth'
import { getToken } from '@/utils/auth.js'
export const mainRequests = {
  logout,
  get,
  post,
  getManager,
  postManager,
  getAddress,
  postAddress,
  getDriver,
  postDriver,
  getOrder,
  postOrder,
  getReport,
  postReport,
  postGateway,
  postMenu
}

function logout () {
  localStorage.removeItem('user')
  localStorage.removeItem('token')
  localStorage.removeItem('outlet')
  localStorage.removeItem('role')
}

async function get (url) {
  let promise = new Promise((resolve, reject) => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache, private, proxy-revalidate',
        Authorization: 'Bearer '
      }
    }
    getToken().then((token) => {
      if (!token) {
        signOut()
        resolve([])
      } else {
        requestOptions.headers.Authorization = 'Bearer ' + token
        axios
          .get(`${config.BASE_PATH}` + url, { headers: requestOptions.headers })
          .then((data) => {
            resolve(data.data)
          })
      }
    })
  })
  let result = await promise
  return result
}

async function post (url, payload) {
  let promise = new Promise((resolve, reject) => {
    // console.log(auth.auth.getSignInUserSession().getAccessToken().jwtToken)
    // let headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json', 'Cache-Control': 'no-cache, private, proxy-revalidate', 'Authorization': 'Bearer ' + auth.auth.getSignInUserSession().getAccessToken().jwtToken },

    const requestOptions = {
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache, private, proxy-revalidate',
        Authorization: 'Bearer '
      }
    }
    getToken().then((token) => {
      if (!token) {
        signOut()
        resolve([])
      } else {
        requestOptions.headers.Authorization = 'Bearer ' + token
        axios
          .post(`${config.BASE_SERVICE_URL}` + url, payload, {
            headers: requestOptions.headers
          })
          .then((data) => {
            resolve(data.data)
          })
      }
    })
  })
  let result = await promise
  return result
}

async function getManager (url) {
  let promise = new Promise((resolve, reject) => {
    const requestOptions = {
      // method: 'GET',
      headers: {
        // 'Content-Type': 'application/json',
        // 'Cache-Control': 'no-cache, private, proxy-revalidate',
        Authorization: 'Bearer '
      }
    }
    getAccessToken().then((token) => {
      if (!token) {
        signOut()
        resolve([])
      } else {
        requestOptions.headers.Authorization = 'Bearer ' + token
        axios
          .get(`${config.MANAGER_SERVICE_URL}` + url, {
            headers: requestOptions.headers
          })
          .then((data) => {
            resolve(data.data)
          })
      }
    })
  })
  let result = await promise
  return result
}

async function postManager (url, payload, header) {
  let promise = new Promise((resolve, reject) => {
    const requestOptions = {
      headers: {
        ...(header ? header :
          {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache, private, proxy-revalidate',
          }),
        Authorization: 'Bearer '
      }
    }
    getAccessToken().then((token) => {
      if (!token) {
        signOut()
        resolve([])
      } else {
        requestOptions.headers.Authorization = 'Bearer ' + token
        axios
          .post(`${config.MANAGER_SERVICE_URL}` + url, payload, {
            headers: requestOptions.headers
          })
          .then((data) => {
            resolve(data.data)
          })
      }
    })
  })
  let result = await promise
  return result
}

// function handleResponse (response) {
//   return response
//     .text()
//     .then((text) => {
//       const data = text && JSON.parse(text)
//       if (!response.ok) {
//         if (response.status === 401) {
//           logout()
//           location.reload(true)
//         }

//         const error = (data && data.message) || response.statusText
//         return Promise.reject(error)
//       }

//       return data
//     })
//     .catch((err) => {
//       return Promise.reject(err)
//     })
// }
async function getAddress (url) {
  const token = await getAccessToken()
  let promise = new Promise((resolve, reject) => {
    const requestOptions = {
      headers: {
        // 'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    }
    axios
      .get(`${config.ADDRESS_SERVICE_URL}` + url, {
        headers: requestOptions.headers
      })
      // .then(handleResponse)
      .then((data) => {
        resolve(data)
      })
      .catch((err) => {
        reject(err)
      })
  })
  let result = await promise
  return result
}
async function postAddress (url, payload) {
  const token = await getAccessToken()
  let promise = await new Promise((resolve, reject) => {
    const requestOptions = {
      headers: {
        // 'Content-Type': 'application/json',
        // 'Cache-Control': 'no-cache, private, proxy-revalidate',
        Authorization: `Bearer ${token}`
      }
    }
    axios
      .post(`${config.ADDRESS_SERVICE_URL}` + url, payload, {
        headers: requestOptions.headers
      })
      // .then(handleResponse)
      .then((data) => {
        resolve(data)
      })
      .catch((error) => {
        reject(error)
      })
  })
  // let result = await promise
  return promise
}

async function getOrder (url) {
  let promise = new Promise((resolve, reject) => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache, private, proxy-revalidate',
        Authorization: 'Bearer '
      }
    }
    getAccessToken().then((token) => {
      if (!token) {
        signOut()
        resolve([])
      } else {
        requestOptions.headers.Authorization = 'Bearer ' + token
        axios
          .get(`${config.ORDER_SERVICE_URL}` + url, {
            headers: requestOptions.headers
          })
          .then((data) => {
            resolve(data.data)
          })
      }
    })
  })
  let result = await promise
  return result
}

async function postOrder (url, payload) {
  let promise = new Promise((resolve, reject) => {
    const requestOptions = {
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache, private, proxy-revalidate',
        Authorization: 'Bearer '
      }
    }
    getAccessToken().then((token) => {
      if (!token) {
        signOut()
        resolve([])
      } else {
        requestOptions.headers.Authorization = 'Bearer ' + token
        axios
          .post(`${config.ORDER_SERVICE_URL}` + url, payload, {
            headers: requestOptions.headers
          })
          .then((data) => {
            resolve(data.data)
          })
      }
    })
  })
  let result = await promise
  return result
}

async function getDriver (url) {
  let promise = new Promise((resolve, reject) => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache, private, proxy-revalidate',
        Authorization: 'Bearer '
      }
    }
    getAccessToken().then((token) => {
      if (!token) {
        signOut()
        resolve([])
      } else {
        requestOptions.headers.Authorization = 'Bearer ' + token
        axios
          .get(`${config.DRIVER_SERVICE_URL}` + url, {
            headers: requestOptions.headers
          })
          .then((data) => {
            resolve(data.data)
          })
      }
    })
  })
  let result = await promise
  return result
}

async function postDriver (url, payload) {
  let promise = new Promise((resolve, reject) => {
    const requestOptions = {
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache, private, proxy-revalidate',
        Authorization: 'Bearer '
      }
    }
    getAccessToken().then((token) => {
      if (!token) {
        signOut()
        resolve([])
      } else {
        requestOptions.headers.Authorization = 'Bearer ' + token
        axios
          .post(`${config.DRIVER_SERVICE_URL}` + url, payload, {
            headers: requestOptions.headers
          })
          .then((data) => {
            resolve(data.data)
          })
      }
    })
  })
  let result = await promise
  return result
}

async function postReport (url, payload) {
  let promise = new Promise((resolve, reject) => {
    const requestOptions = {
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache, private, proxy-revalidate',
        Authorization: 'Bearer '
      }
    }
    getAccessToken().then((token) => {
      if (!token) {
        signOut()
        resolve([])
      } else {
        requestOptions.headers.Authorization = 'Bearer ' + token
        axios
          .post(`${config.REPORT_SERVICE_URL}` + url, payload, {
            headers: requestOptions.headers
          })
          .then((data) => {
            resolve(data.data)
          })
      }
    })
  })
  let result = await promise
  return result
}

async function getReport (url) {
  let promise = new Promise((resolve, reject) => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache, private, proxy-revalidate',
        Authorization: 'Bearer '
      }
    }
    getAccessToken().then((token) => {
      if (!token) {
        signOut()
        resolve([])
      } else {
        requestOptions.headers.Authorization = 'Bearer ' + token
        axios
          .get(`${config.REPORT_SERVICE_URL}` + url, {
            headers: requestOptions.headers
          })
          .then((data) => {
            resolve(data.data)
          })
      }
    })
  })
  let result = await promise
  return result
}

async function postGateway (url, payload) {
  let promise = new Promise((resolve, reject) => {
    // console.log(auth.auth.getSignInUserSession().getAccessToken().jwtToken)
    // let headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json', 'Cache-Control': 'no-cache, private, proxy-revalidate', 'Authorization': 'Bearer ' + auth.auth.getSignInUserSession().getAccessToken().jwtToken },

    const requestOptions = {
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache, private, proxy-revalidate',
        Authorization: 'Bearer '
      }
    }
    getToken().then((token) => {
      if (!token) {
        signOut()
        resolve([])
      } else {
        requestOptions.headers.Authorization = 'Bearer ' + token
        axios
          .post(`${config.BASE_PATH_GATEWAY}` + url, payload, {
            headers: requestOptions.headers
          })
          .then((data) => {
            resolve(data.data)
          })
      }
    })
  })
  let result = await promise
  return result
}

async function postMenu (url, payload) {
  let promise = new Promise((resolve, reject) => {
    const requestOptions = {
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache, private, proxy-revalidate',
        Authorization: 'Bearer '
      }
    }
    getAccessToken().then((token) => {
      if (!token) {
        signOut()
        resolve([])
      } else {
        requestOptions.headers.Authorization = 'Bearer ' + token
        axios
          .post(`${config.MENU_SERVICE_URL}` + url, payload, {
            headers: requestOptions.headers
          })
          .then((data) => {
            resolve(data.data)
          })
      }
    })
  })
  let result = await promise
  return result
}
