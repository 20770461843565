<template>
  <el-row :gutter="20">
    <el-col v-if="outlets.length > 0" :xs="24" :sm="12" :md="12" :lg="6" :xl="6" class="singleChart">
      <el-card class="box-card graph-card" v-loading="salesByWeeklyBool">
        <div slot="header" class="clearfix">
          <h3>Борлуулалтын тоо</h3>
        </div>
        <horizontal-bar v-if="!salesByWeeklyBool" :chart-data="salesWeekly"></horizontal-bar>
        <!-- <el-button size="mini" class="chartBtn" @click="$router.push('reportSalbar')" round>Дэлгэрэнгүй</el-button> -->
      </el-card>
    </el-col>
    <!-- <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6" class="singleChart">
      <el-card class="box-card graph-card" v-loading="kpiGraphWeekBool">
        <div slot="header" class="clearfix">
          <h3>KPI график</h3>
        </div>
        <bar-chart v-if="!kpiGraphWeekBool" :chart-data="kpiWeekly"></bar-chart>
        <el-button size="mini" class="chartBtn" @click="$router.push('reportSalbar')" round>Дэлгэрэнгүй</el-button>
      </el-card>
    </el-col> -->
    <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6" class="singleChart">
      <el-card class="box-card graph-card" v-loading="kpiGraphWeekBool">
        <div slot="header" class="clearfix">
          <h3>KPI график</h3>
        </div>
        <bar-chart v-if="!kpiGraphWeekBool" :chart-data="kpiWeeklyMaster"></bar-chart>
        <!-- <el-button size="mini" class="chartBtn" @click="$router.push('reportSalbar')" round>Дэлгэрэнгүй</el-button> -->
      </el-card>
    </el-col>
    <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6" class="singleChart">
      <el-card class="box-card graph-card" v-loading="salesGraphBool">
        <div slot="header" class="clearfix">
          <h3>Санал гомдлын тоон график</h3>
        </div>
        <line-chart v-if="!salesGraphBool" :chart-data="totalToday"></line-chart>
        <!-- <el-button size="mini" class="chartBtn" @click="$router.push('reportSalbar')" round>Дэлгэрэнгүй</el-button> -->
      </el-card>
    </el-col>
    <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6" class="singleChart">
      <el-card class="box-card graph-card" v-loading="salesByHoursBool">
        <div slot="header" class="clearfix">
          <h3>Цагийн график</h3>
        </div>
        <line-chart v-if="!salesByHoursBool" :chart-data="salesByHours"></line-chart>
        <!-- <el-button size="mini" class="chartBtn" @click="$router.push('reportSalbar')" round>Дэлгэрэнгүй</el-button> -->
      </el-card>
    </el-col>
  </el-row>
</template>
<script>
import BarChart from '../charts/bar.js'
import HorizontalBar from '../charts/horizBar.js'
import LineChart from '../charts/line.js'
import managerServices from '@/services/managerServices.js'
import { getGroups } from '@/utils/auth.js'
export default {
  components: {
    BarChart,
    HorizontalBar,
    LineChart
  },
  created () {
    getGroups().then(data => {
      const index = data.indexOf('AddressEditor')
      if (index > -1) {
        data.splice(index, 1)
      }
      const index1 = data.indexOf('MenuEditor')
      if (index1 > -1) {
        data.splice(index1, 1)
      }
      this.outlets = data
      if (this.outlets.length > 0) {
        managerServices.getKpiCompare(this.outlets).then(data => {
          if (data.statusCode === 200) {
            data.result.today.forEach(element => {
              this.kpiWeeklyMaster.labels.push(element.outlet_name)
              this.kpiWeeklyMaster.datasets[0].data.push(parseInt(element.AD))
              this.kpiWeeklyMaster.datasets[1].data.push(parseInt(element.InStore))
            })
            data.result.old.forEach(element => {
              this.kpiWeeklyMaster.datasets[2].data.push(parseInt(element.AD))
              this.kpiWeeklyMaster.datasets[3].data.push(parseInt(element.InStore))
            })
            this.kpiGraphWeekBool = false
          }
        })
        managerServices.getComplaintChart(this.outlets).then(data => {
          if (data.statusCode === 200) {
            for (var i = 8; i <= 25; i++) {
              var j = i % 23
              var count = 0
              var count1 = 0
              data.result.today.forEach(element => {
                if (element.name === j) {
                  count = parseFloat(element.count)
                }
              })
              data.result.old.forEach(element => {
                if (element.name === j) {
                  count1 = parseFloat(element.count)
                }
              })
              this.totalToday.labels.push(j + ':00')
              this.totalToday.datasets[0].data.push(count)
              this.totalToday.datasets[1].data.push(count1)
            }
            this.salesGraphBool = false
          }
        })
        managerServices.getSalesHours(this.outlets).then(data => {
          if (data.statusCode === 200) {
            for (var i = 8; i <= 25; i++) {
              var j = i % 23
              var count = 0
              var count1 = 0
              data.result.today.forEach(element => {
                if (element.name === j) {
                  count = element.count
                }
              })
              data.result.old.forEach(element => {
                if (element.name === j) {
                  count1 = element.count
                }
              })
              this.salesByHours.labels.push(j + ':00')
              this.salesByHours.datasets[0].data.push(count)
              this.salesByHours.datasets[1].data.push(count1)
            }
            this.salesByHoursBool = false
          }
        })
        managerServices.getSalesWeek(this.outlets).then(data => {
          if (data.statusCode === 200) {
            data.result.forEach(element => {
              switch (element.name) {
                case 'Saturday':
                  this.salesWeekly.labels.push('Бям')
                  break
                case 'Sunday':
                  this.salesWeekly.labels.push('Ням')
                  break
                case 'Monday':
                  this.salesWeekly.labels.push('Дав')
                  break
                case 'Tuesday':
                  this.salesWeekly.labels.push('Мяг')
                  break
                case 'Wednesday':
                  this.salesWeekly.labels.push('Лха')
                  break
                case 'Thursday':
                  this.salesWeekly.labels.push('Пүр')
                  break
                default:
                  this.salesWeekly.labels.push('Баа')
                  break
              }
              this.salesWeekly.datasets[0].data.push(element.count)
              // this.salesWeekly.datasets[0].label = element.name
            })
            this.salesWeekly.datasets[0].data.push(5)
            this.salesByWeeklyBool = false
          }
        })
      }
    })
  },
  data () {
    return {
      outlets: [],
      kpiGraphWeekBool: true,
      salesByHoursBool: true,
      salesGraphBool: true,
      salesByWeeklyBool: true,
      kpiWeekly: {
        labels: [],
        datasets: [{
          label: 'In_Store',
          backgroundColor: [
            '#005b9f',
            '#005b9f',
            '#005b9f',
            '#005b9f',
            '#005b9f',
            '#005b9f',
            '#005b9f'
          ],
          borderWidth: 3,
          data: []
        }, {
          label: 'AD',
          backgroundColor: [
            '#4a0072',
            '#4a0072',
            '#4a0072',
            '#4a0072',
            '#4a0072',
            '#4a0072',
            '#4a0072'
          ],
          borderWidth: 3,
          data: []
        }]
      },
      salesWeekly: {
        labels: [],
        datasets: [{
          label: '',
          backgroundColor: [
            '#8c0032',
            '#4a0072',
            '#140078',
            '#001970',
            '#004ba0',
            '#005b9f',
            '#006978'
          ],
          borderWidth: 3,
          data: []
        }]
      },
      kpiWeeklyMaster: {
        labels: [],
        datasets: [{
          label: 'Өнөөдөр AD',
          backgroundColor: '#58508d',
          borderWidth: 3,
          data: []
        },
        {
          label: 'Өнөөдөр InStore',
          backgroundColor: '#bc5090',
          borderWidth: 3,
          data: []
        },
        {
          label: '7 хоногийн өмнө AD',
          backgroundColor: '#ff6361',
          borderWidth: 3,
          data: []
        },
        {
          label: '7 хоногийн өмнө InStore',
          backgroundColor: '#ffa600',
          borderWidth: 3,
          data: []
        }
        ]
      },
      salesByHours: {
        labels: [],
        datasets: [{
          label: 'Өнөөдөр',
          borderColor: '#48a848',
          borderWidth: 3,
          backgroundColor: this.gradient,
          data: []
        }, {
          label: '7 хоногийн өмнө',
          borderColor: '#f04848',
          borderWidth: 3,
          backgroundColor: this.gradient,
          data: []
        }]
      },
      totalToday: {
        labels: [],
        datasets: [{
          label: 'Өнөөдөр',
          borderColor: '#ffa600',
          borderWidth: 3,
          backgroundColor: this.gradient,
          data: []
        }, {
          label: '7 хоногийн дундаж',
          borderColor: '#2f24ff',
          borderWidth: 3,
          backgroundColor: this.gradient,
          data: []
        }]
      }
    }
  }
}
</script>
<style lang="scss">
  .el-card__body {
    padding-bottom: 5px !important;
    .chartBtn {
      display: block !important;
      margin-left: auto;
      margin-right: auto;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
</style>
