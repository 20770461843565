<template>
  <div class="stats">
    <el-table :data="tableData" height="250" v-loading="!load" size='mini'>
      <el-table-column prop="outletname" label="Салбар" width="120px"></el-table-column>
      <el-table-column prop="created_at" label="Бүртгэсэн" width="100px"></el-table-column>
      <el-table-column prop="note" label="Тэмдэглэл"></el-table-column>
      <el-table-column prop="gadaad_baidal" label="Гадаад байдал"  width="250px"></el-table-column>
      <el-table-column prop="phone" label="Утас"  width="100px"></el-table-column>
      <el-table-column label="Төлөв"  width="120px">
         <template slot-scope="scope" width="80px">
            <el-tag v-if="scope.row.result==0" type="warning"  width="80px" style="text-align: left; font-size: 11px;">
              Бүртгэгдсэн</el-tag>
            <el-tag v-else type='success' width="80px" style="text-align: left; font-size: 11px">Шийдвэрлэгдсэн</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="complaint_key" label="Төрөл" width="150"></el-table-column>
      <el-table-column width="100px">
        <template slot-scope="scope">
            <el-button icon="el-icon-more" circle @click="show1(scope.row)"></el-button>
        </template>
      </el-table-column>
    </el-table>
    <complaint-modal></complaint-modal>
  </div>
</template>
<script>
import managerServices from '@/services/managerServices.js'
import { getGroups } from '@/utils/auth.js'
import ComplaintModal from './complaintModal'
export default {
  props: ['type'],
  components: { ComplaintModal },
  created () {
    getGroups().then(data => {
      const index = data.indexOf('AddressEditor')
      if (index > -1) {
        data.splice(index, 1)
      }

      const index1 = data.indexOf('MenuEditor')
      if (index1 > -1) {
        data.splice(index1, 1)
      }
      this.outlets = data
      this.load = false
      managerServices.getComplaints(this.outlets, this.type).then(data => {
        if (data.statusCode === 200) {
          this.tableData = data.result
          this.load = true
        }
      })
    })
  },
  data () {
    return {
      outlets: [],
      load: false,
      tableData: []
    }
  },
  methods: {
    show1 (comp) {
      this.$bus.emit('dialogShowComp', comp)
    }
  }
}
</script>
