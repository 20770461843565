<template>
  <div class="stats dailyOrdersDialog">
    <el-dialog title="Тайлан" v-if="dialogVisible" :visible.sync="dialogVisible" width="850px" :before-close="handleClose">
      <div class="dialog-body" v-loading="load">
        <div class="briefNews">
          <h3>Товч мэдээ</h3>
          <el-row><el-col :span="3"><b>Нийт:</b></el-col>
          <el-col :span="3"><b>Амжилттай:</b></el-col>
          <el-col :span="3"><b>Цуцалсан:</b></el-col>
          <el-col :span="3"><b>Шилжүүлсэн:</b></el-col>
          <el-col :span="4"><b>Дуусгаагүй:</b></el-col>
          <el-col :span="4"><b>Дүн:</b></el-col>
          <el-col :span="4"><b>Cuscapi дүн:</b></el-col>
          </el-row>
          <el-row>
          <el-col :span="3">{{all}}ш</el-col>
          <el-col :span="3">{{success}}ш</el-col>
          <el-col :span="3">{{cancelCount}}ш</el-col>
          <el-col :span="3">0ш</el-col>
          <el-col :span="4">{{failed}}ш</el-col>
          <el-col :span="4">{{money}}₮</el-col>
          <el-col :span="4">{{cuscapi}}₮</el-col></el-row>
        </div>
        <div class="IndivDriver">
           <el-table size="mini" :data="drivers" style="width: 100%" :row-class-name="tableRowClassName" height="300" show-summary>
              <el-table-column prop="name" label="Жолоочид"></el-table-column>
              <el-table-column prop="belen" label="Бэлэн" width="70"></el-table-column>
              <el-table-column prop="card" label="Карт" width="70"></el-table-column>
              <el-table-column prop="kupon" label="Купон" width="70"></el-table-column>
              <el-table-column prop="dans" label="Данс" width="70"></el-table-column>
              <el-table-column prop="niit" label="Нийт"  width="100"></el-table-column>
              <!-- <el-table-column prop="too" label="Тоо"></el-table-column> -->
              <el-table-column prop="tushaasan" label="Тушаасан"  width="100"></el-table-column>
              <el-table-column prop="uldegdel" label="Үлдэгдэл"  width="120">
                <template slot-scope="scope">
                  <el-tag style="width: 70px;"
                    :type="scope.row.uldegdel === 0 ? 'success' : 'warning'"
                    disable-transitions>{{scope.row.uldegdel}}₮</el-tag>
                </template>
              </el-table-column>
            </el-table>
        </div>
      </div>
      <span slot="footer">
        <el-button type="info" @click="handleClose">Хаах</el-button>
        <el-button type="primary" @click="print" v-loading="load">Хэвлэх</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
pdfMake.vfs = pdfFonts.pdfMake.vfs
export default {
  created () {
  },
  data () {
    return {
      value: '',
      load: true,
      dialogVisible: false,
      newDate: new Date(new Date().setHours(8)),
      count: 0,
      colums: ['name', 'belen', 'card', 'kupon', 'dans', 'tushaasan', 'uldegdel', 'niit'],
      all: 0,
      cancelCount: 0,
      outletName: '',
      failed: 0,
      money: 0,
      success: 0,
      cuscapi: 0,
      drivers: [],
      tableData: []
    }
  },
  methods: {
    print () {
      let printT = []
      let titles = []
      let temp11 = {
        text: 'Нэр',
        style: 'header',
        alignment: 'center'
      }
      let temp12 = {
        text: 'Бэлэн',
        style: 'header',
        alignment: 'center'
      }
      let temp13 = {
        text: 'Карт',
        style: 'header',
        alignment: 'center'
      }
      let temp14 = {
        text: 'Купон',
        style: 'header',
        alignment: 'center'
      }
      let temp15 = {
        text: 'Данс',
        style: 'header',
        alignment: 'center'
      }
      let temp16 = {
        text: 'Тушаасан',
        style: 'header',
        alignment: 'center'
      }
      let temp17 = {
        text: 'Үлдэгдэл',
        style: 'header',
        alignment: 'center'
      }
      let temp18 = {
        text: 'Нийт',
        style: 'header',
        alignment: 'center'
      }
      titles.push(temp11)
      titles.push(temp12)
      titles.push(temp13)
      titles.push(temp14)
      titles.push(temp15)
      titles.push(temp16)
      titles.push(temp17)
      titles.push(temp18)
      printT.push(titles)
      this.drivers.forEach(element => {
        let temp = []
        this.colums.forEach(element1 => {
          let temp123 = {
            text: element[element1].toString()
          }
          temp.push(temp123)
        })
        printT.push(temp)
      })
      // console.log(printT1)
      var docDefinition = {
        content: [
          { style: 'title', text: 'Өдрийн тайлан - ' + this.outletName },
          { style: 'date', text: 'Нийт: ' + this.all + 'ш    Амжилттай: ' + this.success + 'ш    Цуцалсан: ' + this.cancelCount + 'ш    Дуусгаагүй: ' + this.failed + 'ш    Шилжүүлсэн: 0ш' },
          { style: 'date', text: 'Нийт дүн: ' + this.money + 'төг    Cuscapi: ' + this.cuscapi + 'төг' },
          {
            style: 'tableExample',
            table: {
              headerRows: 1,
              widths: [100, 50, 45, 45, 45, 60, 55, 50],
              body: printT
            }
          },
          // { style: 'date', text: 'Эхлэх огноо: ' + (new Date(this.datetime1.toString().split('GMT')[0] + ' UTC').toISOString().slice(0, 19).replace('T', ' ')) + '    Дуусах огноо: ' + (new Date(this.datetime2.toString().split('GMT')[0] + ' UTC').toISOString().slice(0, 19).replace('T', ' ')) },
          { style: 'date', text: 'Баталгаажуулсан: /............................................/' },
          { style: 'date', text: 'Хэвлэсэн огноо: ' + (new Date((new Date()).toString().split('GMT')[0] + ' UTC').toISOString().slice(0, 19).replace('T', ' ')) }
        ],
        styles: {
          date: {
            fontSize: 12,
            margin: [0, 0, 5, 15]
          },
          title: {
            fontSize: 12,
            bold: true,
            margin: [0, 0, 5, 15]
          },
          header: {
            fontSize: 11,
            bold: true,
            margin: [0, 0, 5, 0]
          },
          tableExample: {
            fontSize: 10,
            margin: [0, 5, 0, 15]
          },
          tableHeader: {
            bold: true,
            fontSize: 13,
            color: 'black'
          }
        },
        defaultStyle: {
          alignment: 'justify'
        }
      }
      pdfMake.createPdf(docDefinition).print()
    },
    calc (status) {
      if (status === 50) {
        return 'Хүргэлтэнд гарсан'
      } else {
        return 'Хүргэлтэнд гараагүй'
      }
    },
    tableRowClassName ({ row, rowIndex }) {
      if (row.uldegdel === 0) {
        return 'success-row'
      } else {
        return 'warning-row'
      }
    },
    check () {
      let totalUldegdel = 0
      this.drivers.forEach(element => {
        totalUldegdel = totalUldegdel + element.uldegdel
      })
      if (totalUldegdel !== 0 && this.failed !== 0) { return true } else { return false }
    },
    handleClose () {
      this.dialogVisible = false
    }
  }
}
</script>
<style lang="scss">
  .dailyOrdersDialog {
    div.el-dialog {
      margin-top: 9vh !important;
    }
    .briefNews {
      background: #cee6ff;
      padding: 10px;
      border-radius: 5px;
    }
    span.el-dialog__title {
      color: #fff;
      background: #a7c9ee;
      padding: 5px 17px;
      border-radius: 3px;
    }
    h3 {
      text-align: left;
      color: #3a8ee6 !important;
      margin-top: 0 !important;
      margin-bottom: 10px !important
    }
  .el-dialog__body {
     padding: 6px 35px !important;
     .IndivDriver {
          margin-top: 20px;
          background: #f5f5f5;
          // height: 111px;
          padding: 10px;
          border-radius: 5px;
        .el-table {
          margin-top: 10px;
        }
        .el-tag {
          margin-left:10px;
        }
        .el-table .warning-row {
          background: oldlace;
        }

        .el-table .success-row {
          background: #f0f9eb;
        }
     }
    }
  }
</style>
