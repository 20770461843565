<template>
<div>
  <el-row style="padding:10px">
    <el-col :span="4">
    <el-select v-model="filter.outletname" placeholder="Салбар" :clearable="true">
    <el-option v-for="(item,index) in filter.outletDataSource" :key="index" :label="item.outletname" :value="item.outletname"></el-option>
    </el-select>
    </el-col>
    <el-col :span="4">
    <el-button type="primary" @click="handleEdit" icon="el-icon-edit" :disabled="data.length==0">Засах</el-button>
    </el-col>
  </el-row>
  <el-row style="padding:20px 10px">
        <el-table :data="data" style ="width: 100%" v-loading="loading" element-loading-background="rgba(0, 0, 0, 0.3)" border stripe>
          <el-table-column fixed width="160" label="Салбар" prop="outletname" :sortable="true"/>
          <el-table-column fixed width="100" label="Гариг" prop="weekdayname_mn" :sortable="true">
          </el-table-column>
          <el-table-column label="Эхлэх цаг" width="100" prop="starttime"></el-table-column>
          <el-table-column label="Дуусах цаг" prop="endtime" width="100"></el-table-column>
          <el-table-column label="Дараа өдөрт/шил" width="100" >
            <template slot-scope="scope">
              <span>
                {{ scope.row.shift === 1 ? 'Тийм' : 'Үгүй' }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="Очиж авах эхлэх цаг" width="100" prop="pickupstarttime"></el-table-column>
          <el-table-column label="Очиж авах дуусах цаг" width="100"  prop="pickupendtime"></el-table-column>
          <el-table-column label="Дараа өдөрт шилжих" width="110">
            <template slot-scope="scope">
              <span>
                {{ scope.row.pickup_shift === 1 ? 'Тийм' : 'Үгүй' }}
              </span>
            </template>
          </el-table-column>
        </el-table>
        <outlet-schedule-edit :days="dayList" :getData="getData"></outlet-schedule-edit>
  </el-row>
</div>
</template>

<script>
import managerServices from '@/services/managerServices.js'
import dateFormat from 'dateformat'
import OutletScheduleEdit from '@/components/outletScheduleSettings/outletScheduleEdit.vue'

export default {
  data: function () {
    return {
      loading: false,
      data: [],
      filter: {
        outletname: '',
        outletDataSource: [],
        days: { Monday: 'Даваа',
          Tuesday: 'Мягмар',
          Wednesday: 'Лхагва',
          Thursday: 'Пүрэв',
          Friday: 'Баасан',
          Saturday: 'Бямба',
          Sunday: 'Ням'
        }
      }
    }
  },
  mounted () {},
  components: { OutletScheduleEdit },
  created () {
    managerServices.getOutlets().then((res) => {
      if (res.statusCode === 200) {
        this.filter.outletDataSource = res.result
      }
    })
    this.getData()
  },
  computed: {
    dayList () {
      return Object.keys(this.filter.days).map(key => ({ value: key, label: this.filter.days[key] }))
    }
  },
  methods: {
    getData () {
      if (this.filter.outletname) {
        this.loading = true
        managerServices.getScheduleConfigs(this.filter.outletname).then((res) => {
          this.loading = false
          if (res.statusCode === 200) {
            this.data = res.result.map(s => ({ ...s, weekdayname_mn: this.filter.days[s.weekdayname] }))
            console.log(this.data, 'data')
          } else {
            this.$notify({ type: 'warning', message: 'Мэдээлэл татхад алдаа гарлаа' })
          }
        })
      } else {
        this.data = []
      }
    },
    handleEdit () {
      const schedules = this.data.filter(s => s.outletname === this.filter.outletname)
      let editData = { outletname: this.filter.outletname }
      const date = dateFormat(new Date(), 'yyyy-mm-dd')
      schedules.map((s) => {
        editData[s.weekdayname] = { starttime: date + ' ' + s.starttime, endtime: date + ' ' + s.endtime, shift: s.shift, pickupstarttime: date + ' ' + s.pickupstarttime, pickupendtime: date + ' ' + s.pickupendtime, pickup_shift: s.pickup_shift, id: s.id }
      })
      console.log('editData', editData)
      this.$bus.emit('dialogShowSchedule', editData)
    }
  },
  watch: {
    'filter.outletname' (val) {
      this.getData()
    }
  }
}
</script>
