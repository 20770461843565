<template>
  <div>
    <el-tabs v-model="activeTab" type="border-card" @tab-click="tabClick()"
      style="margin-top:20px; height: 81wh;padding-bottom: 0;">
      <el-tab-pane label="Сул машинууд" name="Сул машинууд">
        <div v-if="activeTab == 'Сул машинууд'" v-loading="isLoading">
          <el-table
            height="100%"
            class="__table_class"
            :data="distCarListData.filter(dataC => !searchCar || dataC.number.toLowerCase().includes(searchCar.toLowerCase()))"
            style="width: 100%">
            <el-table-column
              prop="number"
              label="Дугаар"
              width="140">
            </el-table-column>
            <el-table-column
              prop="outletname"
              label="Салбар">
            </el-table-column>
            <el-table-column
              prop="transport_type"
              label="Төрөл">
            </el-table-column>
            <el-table-column
              align="right">
              <template slot="header" slot-scope="scope">
                <el-input
                  v-model="searchCar"
                  size="mini"
                  placeholder="Машин хайх"
                  @change="scope"/>
              </template>
              <template slot-scope="scope">
                <el-button @click="openDistributeDriverDialog(scope.row)" type="text" size="small">Хуваарьлах</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-tab-pane>
      <el-tab-pane label="Сул жолоочид" name="Сул жолоочид">
        <div v-if="activeTab == 'Сул жолоочид'" v-loading="isLoading">
          <el-table
            height="100%"
            class="__table_class"
            :data="drivers.filter(dataD => !searchDriver || dataD.name.toLowerCase().includes(searchDriver.toLowerCase()))"
            style="width: 100%">
            <el-table-column
              prop="name"
              label="Нэр">
            </el-table-column>
            <el-table-column
              prop="outlet"
              label="Салбар">
            </el-table-column>
            <el-table-column
              prop="phone"
              label="Дугаар">
            </el-table-column>
            <el-table-column
              label="Төлөв">
              <template slot="header" slot-scope="scope">
                <el-input
                  v-model="searchDriver"
                  size="mini"
                  placeholder="Жолооч хайх"
                  @change="scope"/>
              </template>
              <template slot-scope="scope">
                <el-tag v-if="scope.row.status === 0" type="success">Хүргэлтэд гараагүй</el-tag>
                <el-tag v-if="scope.row.status === 1" type="danger">Хүргэлтэд гарсан</el-tag>
                <el-tag v-if="scope.row.status === 2" type="info">Идэвхигүй</el-tag>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-tab-pane>
      <el-tab-pane label="Хуваарьлагдсан жагсаалт" name="Хуваарьлагдсан жагсаалт">
        <div v-if="activeTab == 'Хуваарьлагдсан жагсаалт'" v-loading="isLoading">
          <el-table
            height="100%"
            class="__table_class"
            :data="distedCarListData.filter(dataDc => !searchDistribution || dataDc.source.number.toLowerCase().includes(searchDistribution.toLowerCase()))"
            style="width: 100%">
            <el-table-column
              prop="source.number"
              label="Дугаар">
            </el-table-column>
            <el-table-column
              label="Жолооч">
              <template slot-scope="scope">
                <el-tag v-for="(driver, driverIndex) in scope.row.distedSelectedDriverList" :key="driverIndex">
                  <span v-if="driver && driver.hasOwnProperty('name')">
                    {{driver.name}}
                  </span>
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column
              prop="source.outletname"
              label="Салбар">
            </el-table-column>
            <el-table-column
              prop="source.transport_type"
              label="Төрөл">
            </el-table-column>
            <el-table-column
              align="right">
              <template slot="header" slot-scope="scope">
                <el-input
                  v-model="searchDistribution"
                  size="mini"
                  placeholder="Машин хайх"
                  @change="scope"/>
              </template>
              <template slot-scope="scope">
                <el-button @click="unDistributeDriver(scope.row)" type="text" size="small">Хуваарьлалт салгах</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-tab-pane>
      </el-tabs>
    <!-- <el-row :gutter="10" class="__driver_row">
      <el-col :span="8">
        <el-card class="box-card">
          <div slot="header">
            <span>Сул машинууд</span>
          </div>
    
        </el-card>
      </el-col>
      <el-col :span="8">
        <el-card class="box-card">
          <div slot="header">
            <span>Сул жолоочид</span>
          </div>
      
        </el-card>
      </el-col>
      <el-col :span="8">
        <el-card class="box-card">
          <div slot="header">
            <span>Хуваарьлагдсан жагсаалт</span>
          </div>

        </el-card>
      </el-col>
    </el-row> -->
    <el-dialog
      title="Жолоочоо сонгоно уу"
      :visible.sync="disrtributeDialog"
      width="30%"
      :before-close="handleClose">
      <p style="margin-bottom:10px;">{{ selectedCar ? selectedCar.number : ''}}</p>
      <el-select v-model="selectedDriverId" filterable placeholder="Сонгох" class="__select_driver">
        <el-option
          v-for="(driver, driverIndex) in drivers"
          :key="driverIndex"
          :label="driver.name + ' ' +'(' + driver.outlet + ')'"
          :value="driver.id">
        </el-option>
      </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="distributeDriver">Жолооч сонгох</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import driverServices from '@/services/driverServices.js'
import {
  getGroups,
  getUser
} from '@/utils/auth.js'
export default {
  components: {
  },
  computed: {
    dragOptions () {
      return {
        search: '',
        searchDriver: '',
        animation: 0,
        group: 'description',
        disabled: !this.editable,
        ghostClass: 'ghost'
      }
    }
  },
  created () {
    this.getUser()
    this.getActiveDriverList()
    this.distGetCarsList()
    getGroups().then(data => {
      const index = data.indexOf('AddressEditor')
      if (index > -1) {
        data.splice(index, 1)
      }
      const index1 = data.indexOf('MenuEditor')
      if (index1 > -1) {
        data.splice(index1, 1)
      }
      this.outlets = data
    })
  },
  data () {
    return {
      activeTab: 'Сул машинууд',
      userEmail: null,
      search: '',
      distCarListData: [],
      carListData: [],
      disconnectDriverList: [],
      drivers: [],
      carData: {
        car_id: null,
        driver_id: null,
        distributed_old_status: '',
        distributed_new_status: '',
        user: ''
      },
      disConnectCarData: {
        car_id: null,
        driver_id: null,
        distributed_old_status: '',
        distributed_new_status: '',
        user: ''
      },
      activeName: 'first',
      editable: true,
      isDragging: false,
      delayedDragging: false,
      distedCarListData: [],
      selectedCar: null,
      disrtributeDialog: false,
      selectedDriverId: null,
      searchCar: '',
      searchDistribution: '',
      searchDriver: ''
    }
  },
  methods: {
    getUser () {
      getUser().then((user) => {
        this.userEmail = user.attributes.email
      })
    },
    distGetCarsList () {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      let self = this
      getGroups().then(data => {
        driverServices.getActiveUnDistributionCar({ outlets: data })
          .then(function (response) {
            let carArray = []
            response.result.forEach(element => {
              let carProp = {
                selectedDriverList: []
              }
              carProp = element
              carProp.selectedDriverList = []
              carArray.push(carProp)
            })
            self.distCarListData = carArray
            loading.close()
          })
          .catch(function (error) {
            console.log(error)
          })
      })
    },
    distributedGetCarsList () {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      let self = this
      getGroups().then(data => {
        driverServices.getActiveDistributionCar({ outlets: data })
          .then(function (response) {
            let carArray = []
            response.result.forEach(element => {
              let carProp = {
                source: {},
                distedSelectedDriverList: []
              }
              carProp.source = element
              carProp.distedSelectedDriverList.push(self.drivers.find(d => d.id === parseInt(carProp.source
                .driver_id)))
              carArray.push(carProp)
            })
            self.distedCarListData = carArray
            loading.close()
          })
          .catch(function (error) {
            console.log(error)
          })
      })
    },
    getActiveDriverList () {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      let self = this
      getGroups().then(data => {
        this.outlets = data
        driverServices.getReadyDriversByOutlet(this.outlets, 'all').then(data => {
          self.drivers = data.result
          self.distributedGetCarsList()
        })
      })
      loading.close()
    },
    openDistributeDriverDialog (param) {
      this.disrtributeDialog = true
      this.selectedCar = param
      this.selectedDriverId = null
      this.getActiveDriverList()
    },
    distributeDriver () {
      let self = this
      let payload = {}
      if (this.selectedCar === null) {
        this.$message({
          type: 'warning',
          message: 'Машин сонгоогүй байна'
        })
        return
      }
      if (this.selectedDriverId === null) {
        this.$message({
          type: 'warning',
          message: 'Жолооч сонгоогүй байна'
        })
        return
      }
      console.log('param distribute', this.selectedCar, this.selectedDriverId)
      payload.car_id = this.selectedCar.id
      payload.driver_id = this.selectedDriverId
      payload.distributed_old_status = 0
      payload.distributed_new_status = 1
      // this.distCarListData.forEach(element => {
      //   if (element.id === this.selectedCar.id) {
      //     if (element.selectedDriverList.length === 0) {
      //       payload.distributed_old_status = 1
      //       payload.distributed_new_status = 0
      //       payload.driver_id = null
      //     } else if (element.selectedDriverList.length === 1) {
      //       payload.distributed_old_status = 0
      //       payload.distributed_new_status = 1
      //     }
      //   }
      // })
      console.log('payload', payload)
      driverServices.logDisributedCar(payload)
        .then(function (response) {
          self.$message({
            message: 'Амжилттай, Амжилттай хувиарлалаа.',
            type: 'success'
          })
          self.disrtributeDialog = false
          self.selectedCar = null
          self.selectedDriverId = null
          self.distGetCarsList()
          self.distributedGetCarsList()
          self.getActiveDriverList()
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    unDistributeDriver (param) {
      let self = this
      this.$confirm('Хуваарьлалтыг цуцлахдаа иттгэлтэй байна уу?', 'Warning', {
        confirmButtonText: 'Тийм',
        cancelButtonText: 'Үгүй',
        type: 'warning'
      }).then(() => {
        let payload = {}
        payload.car_id = param.source.id
        payload.driver_id = null
        payload.distributed_old_status = 1
        payload.distributed_new_status = 0
        console.log('param undistribute', param, payload)
        driverServices.logDisributedCar(payload)
          .then(function (response) {
            self.$message({
              message: 'Амжилттай, Амжилттай цуцлагдлаа.',
              type: 'success'
            })
            self.distGetCarsList()
            self.distributedGetCarsList()
            self.getActiveDriverList()
          })
          .catch(function (error) {
            console.log(error)
          })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: 'Цуцлахыг болиуллаа'
        })
      })
    },
    handleClose (done) {
      this.$confirm('Цонхыг хаахдаа итгэлтэй байна уу?')
        .then(_ => {
          done()
        })
        .catch(_ => {})
    }
  }
}

</script>
<style scoped>
  .__driver_distribution {
    height: calc(100vh - 40px);
  }
  .__table_class {
    height: calc(100vh - 200px) !important;
  }
  /* .el-table__body {
    height: calc(100vh - 200px) !important;
  } */
  .__driver_row {
    height: calc(100vh - 150px) !important;
  }
  .__select_driver {
    width: 100%;
  }
</style>
