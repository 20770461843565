<template>
  <div>
    <el-row>
      <el-col :span="5">
        <el-date-picker v-model="datetime1" type="datetime" placeholder="Эхлэх огноо" >
          </el-date-picker>
      </el-col>
      <el-col :span="5">
        <el-date-picker v-model="datetime2" type="datetime" placeholder="Дуусах огноо" >
          </el-date-picker>
      </el-col>
      <el-col :span="2">
        <el-button type="primary" @click="calc()" plain>Боловсруулах</el-button>
      </el-col>
    </el-row>
    <div style="height:84vh; width:100%" v-loading="loadMap" >
      <gmap-map :center="center" :zoom="13" :options="options1" :draggable="false" style="width:100%;  height: 100%; margin-top: 15px;">
        <div v-for="marker1 in outletsPos" :key="marker1.id1">
          <gmap-marker :position="marker1.pos" :title="marker1.name" :draggable="false" icon="https://i.imgur.com/AQ4P5tH.png">
          </gmap-marker>
        </div>
        <div v-for="marker1 in deliveryS" :key="marker1.id1">
          <gmap-marker :position="marker1.pos" :title="marker1.name" @click="showOrder(marker1)" :draggable="false"
            icon="https://i.imgur.com/PyVqOWI.png">
          </gmap-marker>
        </div>
        <div v-for="marker1 in delivery" :key="marker1.id1">
          <gmap-marker :position="marker1.pos" :title="marker1.name" @click="showOrder(marker1)" :draggable="false"
            icon="https://i.imgur.com/ij464aX.png">
          </gmap-marker>
        </div>
        <div v-for="marker1 in deliveryC" :key="marker1.id1">
          <gmap-marker :position="marker1.pos" :title="marker1.name" @click="showOrder(marker1)" :draggable="false"
            icon="https://i.imgur.com/CE3otJ2.png">
          </gmap-marker>
        </div>
      </gmap-map>
    </div>
    <el-row :gutter="20" class="inforow">
      <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6">
        <div class="grid-content bg-purple">
          <img src="https://i.imgur.com/CusCOtj.png" alt="">&nbsp;&nbsp;&nbsp;<h4>Салбарын байршлууд</h4>
        </div>
        </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6">
        <div class="grid-content bg-purple">
          <img src="https://i.imgur.com/8Q5dUN2.png" alt="">&nbsp;&nbsp;&nbsp;<h4>Амжилттай захиалгууд</h4>
        </div>
        </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6">
        <div class="grid-content bg-purple">
          <img src="https://i.imgur.com/rhsLoyT.png" alt="">&nbsp;&nbsp;&nbsp;<h4>Хүлээгдэж буй захиалгууд</h4>
        </div>
        </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6">
        <div class="grid-content bg-purple">
          <img src="https://i.imgur.com/4JIBN0y.png" alt="">&nbsp;&nbsp;&nbsp;<h4>Цуцлагдсан захиалгууд</h4>
        </div>
        </el-col>
    </el-row>
    <order-modal></order-modal>
  </div>
</template>

<script>
import orderService from '@/services/orderServices.js'
import OrderModal from '@/components/orderModal'
import { getGroups } from '@/utils/auth.js'
export default {
  components: {
    OrderModal
  },
  created () {
    getGroups().then(data => {
      const index = data.indexOf('AddressEditor')
      if (index > -1) {
        data.splice(index, 1)
      }
      const index1 = data.indexOf('MenuEditor')
      if (index1 > -1) {
        data.splice(index1, 1)
      }
      this.outlets = data
      this.calc()
    })
    this.outletsPos = []
    setTimeout(this.loadMap = false, 10000)
  },
  data () {
    return {
      loadMap: true,
      drivers: [{
        id: 'all',
        name: 'Бүгд'
      }],
      driver: 'all',
      datetime1: new Date(new Date((new Date()).getFullYear(), (new Date()).getMonth(), (new Date()).getDate(), 0, 0,
        0).setHours(10)),
      datetime2: new Date(new Date((new Date()).getFullYear(), (new Date()).getMonth(), (new Date()).getDate(), 0, 0,
        0).setHours(23)),
      pickerOptions1: {
        disabledDate (time) {
          return time.getTime() > Date.now()
        }
      },
      pickerOptions2: {
        disabledDate (time) {
          return time.getTime() > Date.now()
        }
      },
      outletIcon: {
        url: '../assets/outletIcon.png',
        scaledSize: {
          width: 60,
          height: 60,
          f: 'px',
          b: 'px'
        }
      },
      options1: {
        scrollwheel: true,
        mapTypeControl: true,
        streetViewControl: false,
        minZoom: 1,
        maxZoom: 32,
        gestureHandling: 'greedy'
      },
      center: {
        lat: 47.9174,
        lng: 106.9137
      },
      delivery: [],
      deliveryS: [],
      deliveryC: [],
      outletsPos: [{
        name: 'TOKTOK1',
        pos: {
          lat: 47.89783510159701,
          lng: 106.8882978635711
        }
      },
      {
        name: 'TOKTOK2',
        pos: {
          lat: 47.89878459503514,
          lng: 106.89599043902581
        }
      }
      ]
    }
  },
  methods: {
    calc () {
      this.loadMap = true
      orderService.getFoodOrdersLoc(this.outlets, new Date(this.datetime1.toString().split('GMT')[0] +
          ' UTC').toISOString().slice(0, 19).replace('T', ' '), new Date(this.datetime2.toString().split('GMT')[0] +
          ' UTC').toISOString().slice(0, 19).replace('T', ' ')).then(data => {
        if (data.statusCode === 200) {
          this.delivery = []
          this.deliveryS = []
          this.deliveryC = []
          data.result.forEach(element => {
            let temp = {
              orderUuid: element.order_uuid,
              name: element.order_number,
              phone: element.phone,
              pos: {
                lat: element.latitude * 1,
                lng: element.longitude * 1
              }
            }
            if (element.order_status === 100) {
              this.deliveryS.push(temp)
            } else if (element.order_status === 91) {
              this.deliveryC.push(temp)
            } else {
              this.delivery.push(temp)
            }
          })
          setTimeout(this.loadMap = false, 10000)
        }
      })
    },
    showOrder (obj) {
      this.center = obj.pos
      console.log(obj)
      this.$bus.emit('dialogShowMap', obj.orderUuid)
    }
  }
}
</script>
<style lang="scss">
  .inforow {
    margin-top: 15px;
    h4 {
      width: fit-content;
      display: inline-block;
      margin: 0;
      position: absolute;
      margin-top: 8px;
      text-transform: uppercase;
      color: #232323;
    }
    img {
      width: 30px;
    }
  }
  .el-col {
    border-radius: 4px;
  }
  .bg-purple {
    padding: 3px;
  }
  .bg-purple-light {
    background: #e5e9f2;
  }
  .grid-content {
    border-radius: 4px;
    min-height: 36px;
  }
  .row-bg {
    padding: 10px 0;
    background-color: #f9fafc;
  }
</style>
