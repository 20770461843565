<template>
    <el-dialog title="Анхаарууллага" :visible="dialogVisible" width="600px" append-to-body>
          <el-form ref="form" :model="pod" label-width="140px" >
            <span style="font-size:20p;padding:8px;">{{title}}</span>
            <el-form-item v-for="(change,i) in changes" :label="change.label+':'" :key="i" style="padding:8px">
              <el-row>
                <el-col :span="11">
                  <el-input  v-model="change.oldValue" :readonly="true" class="old-data"></el-input>
                </el-col>
                <el-col :span="1">
                 =>
                </el-col>
                <el-col :span="11">
                <el-input  v-model="change.newValue" :readonly="true"></el-input>
                </el-col>
              </el-row>
            </el-form-item>
          </el-form>
          <el-row style="margin-top:20px;">
              <el-col :span="12">
                <el-button type="primary" @click="hide">Буцах</el-button>
              </el-col>
              <el-col :span="12" style="display:flex;justify-content:end;">
                <el-button type="success" @click="onSubmit">Хадгалах</el-button>
              </el-col>
          </el-row>
      </el-dialog>
</template>
<script>

export default {
  components: { },
  created () {
    let self = this
    this.$bus.$on('dialogShowConfirmChanges', function (row) {
      self.show(row)
    })
  },
  data () {
    return {
      dialogVisible: false,
      load: false,
      title: '',
      changes: [],
      pod: {}
    }
  },
  computed: {
  },
  methods: {
    show ({ changes, title }) {
      this.load = false
      this.dialogVisible = true
      this.changes = changes
      this.title = title
      this.load = true
    },
    hide () {
      this.dialogVisible = false
    },
    handleYes () {
    }
  }
}
</script>

<style >
.old-data > .el-input__inner {
  color: red!important;
  text-decoration: line-through;
}
</style>
