<template>
  <div class="driversInfo">
    <el-row :gutter="20" class="driversInfo-wrap">
      <el-col :span="12" v-for="(driverDat, index) in driverData" :key="index">
        <el-card class="box-card"  style="width:100%;">
          <div @click="detail(driverDat)">
            <el-row :gutter="20">
              <el-col :span="10">
                <ul class="driverInfoList">
                    <li>
                        <div v-if="driverDat.status===0" class="RoundStatus" style="background:#46BD1A"></div>
                        <div v-if="driverDat.status===1" class="RoundStatus" style="background:#DCCC0E"></div>
                        <div v-if="driverDat.status===2" class="RoundStatus" style="background:#B6B6B6"></div>
                    </li>
                  </ul>
                <img src="../../public/img/profile.png" alt="" width="25%" style="border-radius:100%;">
                <h4>{{driverDat.name}}</h4>
              </el-col>
              <el-col :span="14">
                <ul class="driverInfoList">
                  <li>
                    <strong>Орлого</strong><span>{{orlogo(driverDat)}}₮</span>
                  </li>
                  <li>
                    <strong>Бэлэн</strong><span>{{belen(driverDat)}}₮</span>
                  </li>
                  <li>
                    <strong>Бэлэн бус</strong><span>{{belenbus(driverDat)}}₮</span>
                  </li>
                  <li>
                    <strong>Тушаасан</strong><span>{{tushaasan(driverDat)}}₮</span>
                  </li>
                  <li>
                    <strong>Үлдэгдэл</strong><span>{{uldegdel(driverDat)}}₮</span>
                  </li>
                </ul>
              </el-col>
            </el-row>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-dialog :title="selDriver.name" :visible.sync="showModal1" width="50%" :before-close="handleClose">
      <!-- <div class="block" style="margin-bottom: 10px;">
        <span style="margin-right: 10px;">Хугацаа сонгох:</span>
        <el-date-picker
          v-model="dates"
          type="datetimerange"
          :picker-options="pickerOptions"
          range-separator="-с"
          start-placeholder="Эхлэх цаг"
          end-placeholder="Дуусах цаг"
          @change="changeDate"
          align="right">
        </el-date-picker>
        <el-button style="margin-left: 10px;" type="success" @click="calC">Боловсруулах</el-button>
      </div> -->
      <el-row :gutter="20">
        <el-table :data="selDriver.payments" style="width: 100%" size="mini" height="300" border>
          <el-table-column prop="pay_note" label="Тайлбар"></el-table-column>
          <el-table-column prop="cash" label="Бэлэн" width="100"></el-table-column>
          <el-table-column prop="coupon" label="Купон" width="100"></el-table-column>
          <el-table-column prop="credit_card" label="Карт" width="100"></el-table-column>
          <el-table-column prop="dans" label="Данс" width="100"></el-table-column>
          <el-table-column prop="online" label="Онлайн" width="120"></el-table-column>
          <el-table-column prop="created_at" width="120" label="Огноо"></el-table-column>
        </el-table>
        <el-col :span="16" v-if="cal">
          <div style="margin-top:20px">
            <el-popover
              placement="right"
              width="250"
              v-model="popOver1"
              trigger="click">
                <el-row>
                  <el-col :span="24">
                    <el-input :disabled="true" v-model="selDriver.name"></el-input>
                  </el-col>
                </el-row><br>
                <el-row>
                  <el-col :span="24">
                    <el-input-number v-model="ret" :min="0" :max="500000" :step="1000" style="width:100%"></el-input-number>
                  </el-col>
                </el-row>
                <el-row style="margin-top:15px;">
                  <el-button size="small" type="info" @click="popOver1=false">Гарах</el-button>
                  <el-button size="small" type="success" style="float:right" @click="returnMoneyFunc">Тушаах</el-button>
                </el-row>
              <el-button type="danger" slot="reference" :disabled="ret==0">Мөнгө тушаах</el-button>
            </el-popover>
            <el-popover
              placement="left"
              width="250"
              v-model="popOver2"
              trigger="click">
                <el-row>
                  <el-col :span="24">
                    <el-switch v-model="value1" active-text="Ажил дуусгахыг зөвшөөрөх"></el-switch>
                  </el-col>
                </el-row>
                <el-row style="margin-top:15px;">
                  <el-button size="small" @click="popOver2=false; value1= false" type="info">Гарах</el-button>
                  <el-button size="small" type="success" style="float:right" :disabled="!value1" @click="stopDriver">Ажил дуусгах</el-button>
                </el-row>
              <el-button style="float:right" type="success" :disabled="ret!=0" slot="reference">Ажил дуусгах</el-button>
            </el-popover>
          </div>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button type="info" @click="handleClose()">Гарах</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import driverServices from '@/services/driverServices.js'
export default {
  props: ['driverData'],
  computed: {
  },
  created () {
  },
  data () {
    return {
      pickerOptions: {
        shortcuts: [{
          text: '7 хоног',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            start.setHours(8)
            start.setMinutes(0)
            start.setSeconds(0)
            end.setHours(8)
            end.setMinutes(0)
            end.setSeconds(0)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '3 хоног',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 3)
            start.setHours(8)
            start.setMinutes(0)
            start.setSeconds(0)
            end.setHours(8)
            end.setMinutes(0)
            end.setSeconds(0)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: 'Өчигдөр',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 1)
            start.setHours(8)
            start.setMinutes(0)
            start.setSeconds(0)
            end.setHours(8)
            end.setMinutes(0)
            end.setSeconds(0)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      dates: [new Date(new Date((new Date()).getFullYear(), (new Date()).getMonth(), (new Date()).getDate(), 8, 0, 0)), new Date(new Date((new Date()).getFullYear(), (new Date()).getMonth(), (new Date()).getDate() + 1, 0, 0, 0).setHours(8))],
      showModal1: false,
      popOver1: false,
      popOver2: false,
      value1: false,
      ret: 0,
      cal: true,
      selDriver: {}
    }
  },
  methods: {
    calC () {
      // console.log(this.dates)
    },
    changeDate () {
      if (Number(this.dates[0]) === Number(new Date(new Date((new Date()).getFullYear(), (new Date()).getMonth(), (new Date()).getDate(), 8, 0, 0))) && Number(this.dates[1]) === Number(new Date(new Date((new Date()).getFullYear(), (new Date()).getMonth(), (new Date()).getDate() + 1, 8, 0, 0)))) {
        this.cal = true
      } else {
        this.cal = false
      }
    },
    stopDriver () {
      let body = {
        driver_name: this.selDriver.name,
        money: 0,
        user: 'User Name'
      }
      driverServices.stopDriver(body).then(data => {
        this.popOver2 = false
        this.showModal1 = false
        this.$emit('reload', 'reload')
        this.$notify({
          title: data.message,
          message: data.data,
          type: 'success'
        })
        // console.log(data)
      })
    },
    returnMoneyFunc () {
      let body = {
        driver_name: this.selDriver.name,
        money: (this.ret * -1),
        user: 'User Name'
      }
      driverServices.returnMoney(body).then(data => {
        this.ret = 0
      })
    },
    handleClose () {
      this.selDriver = {}
      this.ret = 0
      this.showModal1 = false
    },
    detail (driver) {
      this.selDriver = driver
      this.ret = this.uldegdel(driver)
      // console.log(this.ret)
      this.showModal1 = true
    },
    orlogo (order) {
      let orlogo = 0
      order.payments.forEach(element => {
        orlogo = parseInt(orlogo) + parseInt(element.cash) + parseInt(element.coupon) + parseInt(element.credit_card) + parseInt(element.dans) + parseInt(element.online)
      })
      return orlogo
    },
    belenbus (order) {
      let orlogo = 0
      order.payments.forEach(element => {
        orlogo = parseInt(orlogo) + parseInt(element.coupon) + parseInt(element.credit_card) + parseInt(element.dans) + parseInt(element.online)
      })
      return orlogo
    },
    belen (order) {
      let orlogo = 0
      order.payments.forEach(element => {
        orlogo = parseInt(orlogo) + parseInt(element.cash)
      })
      order.money.forEach(element => {
        orlogo = orlogo + element.money
      })
      return orlogo
    },
    tushaasan (order) {
      let orlogo = 0
      order.money.forEach(element => {
        if (element.money < 0) {
          orlogo = orlogo + element.money
        }
      })
      return -1 * orlogo
    },
    uldegdel (order) {
      let orlogo = 0
      order.payments.forEach(element => {
        orlogo = parseInt(orlogo) + parseInt(element.cash)
      })
      order.money.forEach(element => {
        orlogo = orlogo + element.money
      })
      return orlogo
    },
    showModal (driver) {
      // console.log(driver)
      this.$bus.emit('showDriverModal', driver)
    }
  }
}
</script>
<style lang="scss">
.driversInfo {
  height: 100%;
  .driversInfo-wrap{
    height: 100%;
    overflow-y: scroll;
  }
  .box-card {
    cursor: pointer;
  }
  ul.driverInfoList {
    list-style-type: none;
    line-height: 25px;
    padding: 0;
    margin: 0;
    margin-top: 8px;
    margin-right: 8px;
    font-size: 16px;
    li {
      strong {
        text-align: left;
      }
      span {
        float: right;
      }
      div.RoundStatus {
        width: 15px;
        height: 15px;
        border-radius: 10px;
        margin-bottom: 4px;
      }
    }
  }
}
</style>
