<template>
  <div class="vehicle">
    <div class="page-header panel">
      <h3 class="title">
        Машин удирдлага
      </h3>
      <div class="action-section">
        <el-button type="warning" size="mini" icon="el-icon-plus" @click="addCarForm()">Машин нэмэх</el-button>
      </div>
    </div>
    <el-row>
      <el-col class="table-row m-t-20" :span="8">
        <div class="flex" style="display:flex; margin-bottom:10px;" align="left">
          <el-input prefix-icon="el-icon-search" clearable placeholder="Хайх тээврийн хэрэгслийн дугаар" v-model="search"></el-input>
            <el-button type="success" @click="() => { handleSearch() }">
                    Хайх
                </el-button>
        </div>
      </el-col>
    </el-row>
    <div class="vehicle-body">
      <el-row>
           <el-table v-loading="isLoading" size="mini" :data="filteredCarList" stripe style="width: 100%">
                <el-table-column prop="number" label="Улсын дугаар"></el-table-column>
                <el-table-column prop="mark" label="Машины марк"></el-table-column>
                <el-table-column prop="color" label="Машины өнгө"></el-table-column>
                <el-table-column prop="transport_type" label="Төрөл"></el-table-column>
                <el-table-column prop="status" label="Төлөв"></el-table-column>
                <el-table-column prop="outletname" label="Салбар"></el-table-column>
                <el-table-column label="Бүртгэсэн огноо">
                  <template slot-scope="scope">
                    {{moment(scope.row.created_at).format("YYYY-MM-DD, HH:mm")}}
                </template>
                </el-table-column>
                <el-table-column label="" width="200">
                    <template slot-scope="scope">
                        <div class="action">
                            <el-button type="danger" size="mini" @click="carEditForm(scope.row.id)">Засах</el-button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        <!-- <el-col v-for="(car,index) in carListData" :key="index" :offset="0" :xs="22" :sm="10" :md="10" :lg="7" :xl="7" class="column-style">
          <el-card  shadow="always" class="box-card">
            <div slot="header" class="clearfix">
              <span class="car-number">{{car.number}}</span>
              <el-button
                style="float: right; padding: 3px 0; color:#5b6f8f; font-weight:bold;  border-bottom: 4px solid rgb(230, 162, 60);"
                @click="carEditForm(car)" type="text">Засварлах</el-button>
            </div>
            <span class="driver_date">Бүртгэсэн огноо: {{moment(car.created_at).format("YYYY-MM-DD")}}</span>
            <div class="driver-info m-b-20"><i class="fa fa-car"></i> <span>Машины марк: {{car.mark}}</span></div>
            <div class="driver-info m-b-20"><i class="fa fa-car"></i> <span>Машины өнгө: {{car.color}}</span></div>
            <div class="driver-info m-b-20"><i class="fa fa-car"></i> <span>Салбар: {{car.outletname}}</span></div>
            <el-row class="m-b-20">
              <el-col v-if="car.status === 'active'" :span="8" style="float:right">
                <i style="color:#66cd00" class="fa fa-circle" aria-hidden="true"></i> Идэвхтэй
              </el-col>
                <el-col v-if="car.status === 'maintenance'" :span="8" style="float:right">
                  <i style="color:#cd0000" class="fa fa-circle" aria-hidden="true"></i> Засвартай
              </el-col>
               <el-col v-if="car.status === 'inactive'" :span="8" style="float:right">
                <i style="color:#999999" class="fa fa-circle" aria-hidden="true"></i> Идэвхгүй
              </el-col>
            </el-row>
          </el-card>
        </el-col> -->
      </el-row>
    </div>
    <el-dialog
      title="Машин бүртгэх"
      :visible.sync="addCarDialog"
      width="30%"
      :before-close="handleCloseAddDialog">
      <el-form :model="carFormData" :rules="rules" ref="carFormData" label-width="120px" class="demo-ruleForm" label-position="top">
        <el-form-item label="Улсын дугаар" prop="number">
          <el-input v-model="carFormData.number" placeholder="Улсын дугаар оруулна уу"></el-input>
        </el-form-item>
          <el-form-item label="Машины марк" prop="mark">
          <el-input v-model="carFormData.mark" placeholder="Машины марк"></el-input>
        </el-form-item>
          <el-form-item label="Машины өнгө" prop="color">
          <el-input v-model="carFormData.color" placeholder="Машины өнгө"></el-input>
        </el-form-item>
         <el-form-item label="Төлөв" prop="status">
           <el-select v-model="carFormData.status" placeholder="Select">
            <el-option
              v-for="(item, index) in status"
              :key="index"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Төрөл" prop="transport_type">
           <el-select v-model="carFormData.transport_type" placeholder="Select">
            <el-option
              v-for="(item, index) in transport_types"
              :key="index"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Салбар" prop="name">
          <el-select v-model="carFormData.outletname" placeholder="Select">
            <el-option
              v-for="item in outlets"
              :key="item"
              :label="item"
              :value="item">
            </el-option>
          </el-select>
          </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleCloseAddDialog()">Буцах</el-button>
      <el-button type="primary" @click="addNewCar('carFormData')">Нэмэх</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="Машин засах"
      :visible.sync="editCarDialog"
      width="30%"
      :before-close="handleClose">
      <el-form :model="carEditFormData" :rules="rules" ref="carEditFormData" label-width="120px" class="demo-ruleForm" label-position="top">
        <el-form-item label="Улсын дугаар" prop="number">
          <el-input disabled v-model="carEditFormData.number"></el-input>
        </el-form-item>
          <el-form-item label="Машины марк" prop="mark">
          <el-input v-model="carEditFormData.mark"></el-input>
        </el-form-item>
          <el-form-item label="Машины өнгө" prop="color">
          <el-input v-model="carEditFormData.color"></el-input>
        </el-form-item>
         <el-form-item label="Төлөв" prop="status">
           <el-select v-model="carEditFormData.status" placeholder="Select">
            <el-option
             v-for="(item, index) in status"
              :key="index"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Төрөл" prop="transport_type">
           <el-select v-model="carEditFormData.transport_type" placeholder="Select">
            <el-option
              v-for="(item, index) in transport_types"
              :key="index"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Салбар" prop="name">
          <el-select v-model="carEditFormData.outletname" placeholder="Select">
            <el-option
              v-for="(item, index) in outlets"
              :key="index"
              :label="item"
              :value="item">
            </el-option>
          </el-select>
          </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose()">Буцах</el-button>
      <el-button type="primary" @click="updateCar()">Хадгалах</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import driverServices from '@/services/driverServices.js'
import { getGroups, getUser } from '@/utils/auth.js'

export default {
  created () {
    this.isLoading = true
    this.getCarsList()
    this.getUser()
    getGroups().then(data => {
      // const index = data.indexOf('AddressEditor')
      // if (index > -1) {
      //   data.splice(index, 1)
      // }
      // const index1 = data.indexOf('MenuEditor')
      // if (index1 > -1) {
      //   data.splice(index1, 1)
      // }
      this.outlets = data
    })
  },
  data () {
    return {
      search: '',
      carListData: [],
      filteredCarList: [],
      isLoading: true,
      outlets: [],
      addCarDialog: false,
      editCarDialog: false,
      carFormData: {
        number: '',
        mark: '',
        color: '',
        created_user: '',
        outlet_name: '',
        outletname: ''
      },
      carEditFormData: {
        number: '',
        mark: '',
        color: '',
        updated_user: '',
        outlet_name: '',
        outletname: ''
      },
      rules: {
        number: [
          { required: true, message: 'Машины улсын дугаар оруулна уу!', trigger: 'blur' }
        ],
        mark: [
          { required: true, message: 'Машины марк оруулна уу!', trigger: 'blur' }
        ],
        color: [
          { required: true, message: 'Машины өнгө оруулна уу!', trigger: 'blur' }
        ],
        status: [
          { required: true, message: 'Машины төлөв сонгоно уу!', trigger: 'blur' }
        ],
        transport_type: [
          { required: true, message: 'Машины төрөл сонгоно уу!', trigger: 'blur' }
        ]
      },
      status: [{
        value: 'active',
        label: 'Идэвхтэй'
      }, {
        value: 'maintenance',
        label: 'Засвартай'
      },
      {
        value: 'inactive',
        label: 'Идэвхгүй'
      }],
      transport_types: [
        {value: 'Дугуй', label: 'Дугуй'},
        {value: 'Машин /бензин/', label: 'Машин /бензин/'},
        {value: 'Машин /цахилгаан/', label: 'Машин /цахилгаан/'},
        {value: 'Мопед', label: 'Мопед'},
        {value: 'Скүүтер', label: 'Скүүтер'},
        {value: 'Явган', label: 'Явган'},
      ]
    }
  },
  methods: {
    getUser () {
      getUser().then((user) => {
        this.carFormData.created_user = user.attributes.email
        this.user = user.attributes.email
      })
    },
    getCarsList () {
      let self = this
      driverServices.getCarsList()
        .then(function (response) {
          self.carListData = response.result
          self.filteredCarList = response.result
        })
        .catch(function (error) {
          console.log(error)
        })
        this.isLoading = false
    },
    addCarForm () {
      this.addCarDialog = true
    },
    addNewCar () {
      this.$refs.carFormData.validate((valid) => {
        if (valid) {
          driverServices.addNewCar(this.carFormData)
            .then(response => {
              if (response.statusCode === 200 && response.success) {
                this.$message({
                  type: 'success',
                  message: 'Машины мэдээллийг амжилттай үүсгэлээ'
                })
                this.addCarDialog = false
                this.getCarsList()
              } else {
                this.$message.error('Уучлаарай, машин нэмэхэд алдаа гарлаа.')
              }
            })
            .catch(function (error) {
              console.log(error)
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    carEditForm (id) {
      const car = this.carListData.find(item=> item.id === id);
      this.carEditFormData = {...car}
      this.editCarDialog = true
    },
    updateCar () {
      this.$refs.carEditFormData.validate((valid) => {
        if (valid) {
          driverServices.updateCar(this.carEditFormData)
            .then(response => {
              console.log(response, 'response data')
              if (response.statusCode === 200 && response.success) {
                this.$message({
                  type: 'success',
                  message: 'Машины мэдээллийг амжилттай заслаа'
                })
                this.editCarDialog = false
                this.getCarsList()
              } else {
                this.$message.error('Уучлаарай, машины мэдээлэл засахад алдаа гарлаа.')
              }
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    handleCloseAddDialog () {
      this.$refs.carFormData.resetFields()
      this.carFormData = {}
      this.addCarDialog = false
    },
    handleClose () {
      this.carEditFormData = {}
      this.editCarDialog = false
    },
    handleSearch () {
      this.filteredCarList = this.carListData.filter(item => item.number.includes(this.search));
    }
  }
}
</script>
