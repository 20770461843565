<template>
  <el-card class="box-card" shadow="hover">
    <div slot="header" class="clearfix">
      <span>{{data.name}}</span>
    </div>
    <div class="text item">
      {{'Цуцалсан: ' + data.data.cancel }}
    </div>
    <div class="text item">
      {{'Баталгаажуулсан: ' + data.data.cc_confirm }}
    </div>
    <div class="text item">
      {{'Урьдчилсан: ' + data.data.committed }}
    </div>
    <div class="text item">
      {{'Гал тогоонд: ' + data.data.out_kitchen }}
    </div>
    <div class="text item">
      {{'Гал тогооноос гарсан: ' + data.data.in_kitchen }}
    </div>
    <div class="text item">
      {{'Хүргэлтэд гарсан: ' + data.data.out_driver }}
    </div>
    <div class="text item">
      {{'Амжилттай: ' + data.data.success }}
    </div>
    <div class="text item">
      {{'Нийт: ' + (parseInt(data.data.cancel) + parseInt(data.data.cc_confirm) + parseInt(data.data.committed) + parseInt(data.data.out_kitchen) + parseInt(data.data.in_kitchen) + parseInt(data.data.out_driver) + parseInt(data.data.success))}}
    </div>
  </el-card>
</template>
<script>
export default {
  props: ['data'],
  created () {
  }
}
</script>
<style>
  .text {
    font-size: 14px;
  }

  .item {
    margin-bottom: 18px;
  }

  .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }
  .clearfix:after {
    clear: both
  }

  .box-card {
    /* width: 350px; */
    margin-bottom: 20px;
  }
</style>
