import { Auth } from 'aws-amplify'
import { AmplifyEventBus } from 'aws-amplify-vue'
import { Message } from 'element-ui'

function getUser () {
  return Auth.currentAuthenticatedUser().then((user) => {
    if (user && user.signInUserSession) {
      return user
    } else {
      return null
    }
  }).catch(err => {
    console.log(err)
    return null
  })
}

function getGroups () {
  return Auth.currentAuthenticatedUser().then((user) => {
    if (user && user.signInUserSession) {
      return user.signInUserSession.accessToken.payload['cognito:groups']
    } else {
      return null
    }
  }).catch(err => {
    if (err === 'not authenticated') {
      signOut()
    }
    return null
  })
}

function getUserName () {
  return Auth.currentAuthenticatedUser().then((user) => {
    if (user && user.signInUserSession) {
      return user
    } else {
      return null
    }
  }).catch(err => {
    if (err === 'not authenticated') {
      signOut()
    }
    return null
  })
}

async function getToken () {
  /* eslint-disable */
  return await Auth.currentSession().then((session) => {
    if (session) {
      // return session.getAccessToken().getJwtToken()
      return session.idToken.jwtToken

    } else {
      return null
    }
  }).catch(err => {
    if (err === 'not authenticated') {
      signOut()
    }
    return null
  })
  /* eslint-enable */
}

async function getAccessToken () {
  /* eslint-disable */
  return await Auth.currentSession().then((session) => {
	  if (session) {
	    return session.accessToken.jwtToken
	  } else {
	    return null
	  }
    }).catch(err => {
	  if (err === 'not authenticated') {
	    signOut()
	  }
	return null
  })
  /* eslint-enable */
}

function signUp (username, password) {
  return Auth.signUp({
    username,
    password,
    attributes: {
      email: username
    }
  })
    .then(data => {
      AmplifyEventBus.$emit('localUser', data.user)
      if (data.userConfirmed === false) {
        AmplifyEventBus.$emit('authState', 'confirmSignUp')
      } else {
        AmplifyEventBus.$emit('authState', 'signIn')
      }
      return data
    })
    .catch(err => {
      console.log(err)
    })
}

function confirmSignUp (username, code) {
  return Auth.confirmSignUp(username, code).then(data => {
    AmplifyEventBus.$emit('authState', 'signIn')
    return data // 'SUCCESS'
  })
    .catch(err => {
      // console.log(err)
      throw err
    })
}

function resendSignUp (username) {
  return Auth.resendSignUp(username).then(() => {
    return 'SUCCESS'
  }).catch(err => {
    // console.log(err)
    return err
  })
}

async function signIn (username, password) {
  try {
    const user = await Auth.signIn(username, password)
    if (user) {
      AmplifyEventBus.$emit('authState', 'signedIn')
    }
  } catch (err) {
    if (err.code === 'UserNotConfirmedException') {
      Message({
        message: 'Хэрэглэгчийг баталгаажуулаагүй байна.!',
        duration: 2500,
        type: 'error',
        showClose: 1
      })
      setTimeout(function () { location.reload() }, 3000)
      // The error happens if the user didn't finish the confirmation step when signing up
      // In this case you need to resend the code and confirm the user
      // About how to resend the code and confirm the user, please check the signUp part
    } else if (err.code === 'PasswordResetRequiredException') {
      Message({
        message: 'Хэрэглэгчийн нууц үг reset хийх шаардлагатай.!',
        duration: 2500,
        type: 'error',
        showClose: 1
      })
      setTimeout(function () { location.reload() }, 3000)
      // The error happens when the password is reset in the Cognito console
      // In this case you need to call forgotPassword to reset the password
      // Please check the Forgot Password part.
    } else if (err.code === 'NotAuthorizedException') {
      Message({
        message: 'Хэрэглэгчийн имайл эсвэл нууц үг буруу.!',
        duration: 2500,
        type: 'error',
        showClose: 1
      })
      setTimeout(function () { location.reload() }, 3000)
      // The error happens when the incorrect password is provided
    } else if (err.code === 'UserNotFoundException') {
      Message({
        message: 'Хэрэглэгчийн имайл эсвэл нууц үг буруу.!',
        duration: 2500,
        type: 'error',
        showClose: 1
      })
      setTimeout(function () { location.reload() }, 3000)
      // The error happens when the supplied username/email does not exist in the Cognito user pool
    } else {
      // console.log(err)
    }
  }
}

function signOut () {
  return Auth.signOut()
    .then(data => {
      AmplifyEventBus.$emit('authState', 'signedOut')
      return data
    })
    .catch(err => {
      // console.log(err)
      return err
    })
}

export { getUser, signUp, confirmSignUp, resendSignUp, signIn, signOut, getToken, getGroups, getUserName, getAccessToken }
