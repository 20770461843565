<template>
  <div class="main-layout">
    <el-header>
      <el-menu class="menu-section-mobile" @open="handleOpen" @close="handleClose" :collapse="isCollapse">
        <el-menu-item v-for="(item, index) in menus" :key="index" :index="item.name" @click="clickMenu(item.name)">
          <i :class="item.icon"></i>
        </el-menu-item>
        <el-menu-item @click="clickMenu('Тайлан')" index="salbar6">
          <i class="fas fa-file-alt menuicon"></i>
        </el-menu-item>
        <el-menu-item @click="clickMenu('Гарах')" class="exitBtn" index="salbar5">
          <i class="fas fa-sign-out-alt menuicon"></i>
        </el-menu-item>
      </el-menu>
    </el-header>
    <el-container>
      <el-aside :class="[isCollapse ? activeClass : 'sidebar', 'sidebar1']">
        <div class="logo-section" v-show="!isCollapse">
          <img src="../../assets/kfc.png" alt="" @click="clickMenu('Самбар')">
          <h2 v-if="user!==''">{{user}}</h2>
        </div>
        <el-menu :default-active="activeIndex" class="menu-section" @open="handleOpen" @close="handleClose"
          :collapse="isCollapse">
          <div v-if="outlets.length>1">
            <el-menu-item v-for="(item, index) in menus" :key="index" :index="item.name" @click="clickMenu(item.name)">
              <i :class="item.icon"></i>&nbsp;&nbsp;&nbsp;
              <span slot="title">{{item.name}}</span>
            </el-menu-item>
          </div>
          <div v-else>
            <el-menu-item v-for="(item, index) in menus1" :key="index" :index="item.name" @click="clickMenu(item.name)">
              <i :class="item.icon"></i>&nbsp;&nbsp;&nbsp;
              <span slot="title">{{item.name}}</span>
            </el-menu-item>
          </div>
          <el-submenu v-if="outlets.find(x => x === 'MenuEditor')" class="submenuItem" index="settings">
            <el-menu-item slot="title" style="height: 100%;" index="setting">
              <i class="fas fa-file-alt menuicon"></i>&nbsp;&nbsp;&nbsp;
              <span>Тохиргоо</span>
            </el-menu-item>
            <el-menu-item-group>
              <el-menu-item v-if="outlets.find(x => x === 'MenuEditor')" @click="clickMenu('СалбарынТохиргоо')" index="subsettings1">Салбарын тохиргоо</el-menu-item>
              <el-menu-item v-if="outlets.find(x => x === 'MenuEditor')" @click="clickMenu('ПодТохиргоо')" index="subsettings2">Под тохиргоо</el-menu-item>
              <el-menu-item v-if="outlets.find(x => x === 'MenuEditor')" @click="clickMenu('ХуваарьТохиргоо')" index="subsettings3">Хуваарь тохиргоо</el-menu-item>
              <el-menu-item v-if="outlets.find(x => x === 'MenuEditor')" @click="clickMenu('СалбарЛог')" index="subsettings5">Салбар захиалга хаалт</el-menu-item>
              <el-menu-item v-if="outlets.find(x => x === 'MenuEditor')" @click="clickMenu('RKeeperMenu')" index="subsettings4">RKeeper цэс тохиргоо</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu class="submenuItem" index="salbar4">
            <el-menu-item slot="title" style="height: 100%;" index="tailan">
              <i class="fas fa-file-alt menuicon"></i>&nbsp;&nbsp;&nbsp;
              <span>Тайлан</span>
            </el-menu-item>
            <el-menu-item-group>
              <el-menu-item @click="clickMenu('ТайланСалбар')" index="salbar">Салбар</el-menu-item>
              <el-menu-item @click="clickMenu('ТайланБорлуулалт')" index="salbar1">Борлуулалт</el-menu-item>
              <el-menu-item @click="clickMenu('ТайланХүргэлт')" index="salbar2">Хүргэлт</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu class="submenuItem" index="driverManage">
            <el-menu-item slot="title" style="height: 100%;" index="driver">
              <i class="fas fa-car"></i>&nbsp;&nbsp;&nbsp;
              <span>Тээврийн хэрэгсэл</span>
            </el-menu-item>
            <el-menu-item-group>
              <!-- <el-menu-item v-if="outlets.find(x => x === 'MenuEditor')" @click="clickMenu('ЖолоочЖагсаалт')" index="driverList">Жолооч удирдлага</el-menu-item> -->
              <el-menu-item @click="clickMenu('МашинЖагсаалт')" index="carList">Машин удирдлага</el-menu-item>
              <el-menu-item @click="clickMenu('ЖолоочХуваарилах')" index="driverDistribution">Хуваарилах</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu v-if="outlets.find(x => x === 'AddressEditor')" class="submenuItem" index="salbar5">
            <el-menu-item slot="title" style="height: 100%;" index="hayg">
              <i class="fas fa-map-marker-alt"></i>&nbsp;&nbsp;&nbsp;
              <span>Хаяг</span>
            </el-menu-item>
            <el-menu-item-group>
              <el-menu-item @click="clickMenu('ХаягЗасах')" index="salbar">Хаяг засах</el-menu-item>
              <el-menu-item @click="clickMenu('БайршилЗасах')" index="salbar2">Байршил засах</el-menu-item>
              <el-menu-item @click="clickMenu('БайршилаарХайх')" index="salbar2">Байршилаар хайх</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu v-if="outlets.find(x => x === 'MenuEditor')" class="submenuItem" index="salbar15">
            <el-menu-item slot="title" style="height: 100%;" index="web">
              <i class="el-icon-s-platform"></i>&nbsp;&nbsp;&nbsp;
              <span>Веб</span>
            </el-menu-item>
            <el-menu-item-group>
              <el-menu-item v-if="outlets.find(x => x === 'MenuEditor')" @click="clickMenu('Баннер')" index="banner">
                <span slot="title">Баннер</span>
              </el-menu-item>
              <el-menu-item @click="clickMenu('Салбарынмэдээлэл')" index="salbar2">Салбарын мэдээлэл</el-menu-item>
              <el-menu-item @click="clickMenu('Хуудас')" index="salbar2">Хуудас</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-menu-item @click="clickMenu('Гарах')" class="exitBtn" index="salbar3">
            <i class="fas fa-sign-out-alt menuicon"></i>&nbsp;&nbsp;&nbsp;
            <span slot="title">Гарах</span>
          </el-menu-item>
        </el-menu>
      </el-aside>
      <el-container class="main-container">
        <el-main>
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>
<script>
import {
  signOut,
  getGroups,
  getUserName
} from '@/utils/auth.js'
export default {
  created () {
    getGroups().then(data => {
      this.outlets = data
    })
    getUserName().then(data => {
      this.user = data.username.split('@')[0]
    })
    this.hide = true
    if (this.$router.currentRoute.fullPath === '/drivers') {
      this.activeIndex = 'Жолоочид'
    } else if (this.$router.currentRoute.fullPath === '/orders') {
      this.activeIndex = 'Захиалгууд'
    } else if (this.$router.currentRoute.fullPath === '/dashboard') {
      this.activeIndex = 'Самбар'
    } else if (this.$router.currentRoute.fullPath === '/map') {
      this.activeIndex = 'Хүргэлтийн бүс'
    }
    console.log(this.$router.currentRoute.fullPath)
  },
  data () {
    return {
      outlets: [],
      user: '',
      hide: false,
      activeIndex: '',
      activeClass: '',
      isCollapse: false,
      menus: [{
        name: 'Самбар',
        icon: 'fas fa-tachometer-alt menuicon'
      },
      {
        name: 'Захиалгууд',
        icon: 'fas fa-list-alt menuicon'
      },
      {
        name: 'Жолоочид',
        icon: 'fas fa-car'
      },
      {
        name: 'Хүргэлтийн бүс',
        icon: 'fas fa-map-marker-alt menuicon'
      },
      {
        name: 'Купон',
        icon: 'fas fa-tag menuicon'
      },
      {
        name: 'Хар жагсаалт',
        icon: 'fas fa-user-times menuicon'
      }
      ],
      menus1: [{
        name: 'Самбар',
        icon: 'fas fa-tachometer-alt menuicon'
      },
      {
        name: 'Захиалгууд',
        icon: 'fas fa-list-alt menuicon'
      },
      {
        name: 'Жолоочид',
        icon: 'fas fa-users menuicon'
      },
      {
        name: 'Хүргэлтийн бүс',
        icon: 'fas fa-map-marker-alt menuicon'
      },
      {
        name: 'Хар жагсаалт',
        icon: 'fas fa-user-times menuicon'
      }
      ]
    }
  },
  methods: {
    signOut () {
      signOut().then((data) => {
        console.log('DONE', data)
      }).catch((err) => {
        console.log('SIGN OUT ERR', err)
      })
    },
    clickMenu (item) {
      switch (item) {
        case 'Самбар':
          this.$router.push('/dashboard')
          break
        case 'Тайлан':
          this.$router.push('/report')
          break
        case 'ТайланСалбар':
          this.$router.push('/reportSalbar')
          break
        case 'ТайланБорлуулалт':
          this.$router.push('/reportSales')
          break
        case 'ТайланХүргэлт':
          this.$router.push('/reportDelivery')
          break
        case 'Захиалгууд':
          this.$router.push('/orders')
          break
        case 'Жолоочид':
          this.$router.push('/drivers')
          break
        case 'Хяналтын дэлгэц':
          this.$router.push('/dash')
          break
        case 'Тохиргоо':
          this.$router.push('/settings')
          break
        case 'Хүргэлтийн бүс':
          this.$router.push('/map')
          break
        case 'ХаягЗасах':
          this.$router.push('/editAddress')
          break
        case 'ПодЗасах':
          this.$router.push('/pod')
          break
        case 'БайршилЗасах':
          this.$router.push('/address')
          break
        case 'Баннер':
          this.$router.push('/banner')
          break
        case 'БайршилаарХайх':
          this.$router.push('/search')
          break
        case 'Салбарынмэдээлэл':
          this.$router.push('/branchDetails')
          break
        case 'Хуудас':
          this.$router.push('/careerPage')
          break
        case 'МашинЖагсаалт':
          this.$router.push('/vehicle')
          break
        case 'ЖолоочХуваарилах':
          this.$router.push('/driverDistribution')
          break
        case 'more':
          window.open('http://18.136.124.231:8081', '_blank')
          break
        case 'Гарах':
          this.signOut()
          break
        case 'СалбарынТохиргоо':
          this.$router.push('/outlet-settings')
          break
        case 'ПодТохиргоо':
          this.$router.push('/pod-settings')
          break
        case 'ХуваарьТохиргоо':
          this.$router.push('/schedule-settings')
          break
        case 'СалбарЛог':
          this.$router.push('/outlet-log')
          break
        case 'RKeeperMenu':
          this.$router.push('/rkeepermenu-settings')
          break
        case 'Купон':
          this.$router.push('/coupon')
          break
        case 'Хар жагсаалт':
          this.$router.push('/blacklist')
          break
        default:
          break
      }
    },
    handleOpen (key, keyPath) {},
    handleClose (key, keyPath) {},
    logout () {
      this.$store.dispatch('authentication/logout')
    }
  }
}

</script>
<style lang="scss">
  .el-radio-button:first-child .el-radio-button__inner {
    border-radius: 0 4px 4px 0 !important;
  }

  .submenuItem {
    .el-submenu__title {
      padding: 0 !important;
      font-size: 20px;
      color: #fff;

      span {
        font-size: 17px;
      }

      i {
        color: #ffffff;
      }
    }

    :hover {
      background: transparent !important
    }

    .el-menu {
      background: transparent !important;

      .el-menu-item-group {
        margin-bottom: 14px;

        .el-menu-item-group__title {
          display: none;
        }
      }

      .el-menu-item {
        min-width: auto;

        :hover {
          background: none !important
        }
      }
    }
  }

</style>
