import { mainRequests } from '@/helpers/custom'
export default {
  getReportData (conf, id) {
    return new Promise(function (resolve, reject) {
      let temp = {}
      temp.conf = conf
      temp.id = id
      mainRequests.postReport('/getReportData1', temp).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  report (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests.post('/reportexec', payload).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  }
}
