<template>
    <el-dialog title="Под засах" :visible="dialogVisible" width="460px" :before-close="hide">
          <el-form ref="form" :model="data" label-width="140px" :rules="rules">
            <el-form-item label="Под код:">
              <el-input  v-model="data.podcode" :readonly="true"></el-input>
            </el-form-item>
              <el-form-item label="Салбар:" prop="outletname">
              <el-select  v-model="data.outletname" :clearable="true">
                <el-option v-for="(item,index) in outlets" :label="item.outletname" :key="index" :value="item.outletname"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <el-row style="margin-top:20px;">
              <el-col :span="12">
                <el-button type="primary" @click="hide">Буцах</el-button>
              </el-col>
              <el-col :span="12" style="display:flex;justify-content:end;">
                <el-button type="success" @click="onSubmit" :loading="load">Хадгалах</el-button>
              </el-col>
          </el-row>
          <confirm-changes></confirm-changes>
      </el-dialog>
</template>
<script>

import managerServices from '@/services/managerServices'
import ConfirmChanges from '@/components/outletSettings/confirmChanges.vue'
import { getUserName } from '@/utils/auth.js'

export default {
  components: { ConfirmChanges },
  props: ['getData'],
  created () {
    let self = this
    this.$bus.$on('dialogShowPod', function (row) {
      console.log(row, 'pod row')
      self.show(row)
    })
    managerServices.getOutlets().then((res) => {
      if (res.statusCode === 200) {
        this.outlets = res.result
      }
    })
    getUserName().then(data => {
      this.user = data.username.split('@')[0]
    })
  },
  data () {
    return {
      dialogVisible: false,
      load: false,
      data: {},
      user: '',
      oldData: {},
      outlets: [],
      rules: {
        outletname: [{ required: true, message: 'Салбар заавал сонгоно уу !!!', trigger: 'change' }]
      }
    }
  },
  computed: {
  },
  methods: {
    show (row) {
      this.dialogVisible = true
      this.data = row
      this.oldData = Object.assign({}, row)
    },
    hide () {
      this.dialogVisible = false
    },
    confirmSave () {
      if (this.oldData.outletname !== this.data.outletname) {
        let changes = [{ label: 'Салбар', oldValue: this.oldData.outletname, newValue: this.data.outletname }]
        this.$bus.emit('dialogShowConfirmChanges',
          { title: `${this.data.podcode} под-ын мэдээлэлд доорхи өөрчлөлт оруулахад итгэлтэй байна уу ?`,
            changes: changes })
      }
    },
    onSubmit () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$confirm('Өөрчлөлт хийхдээ итгэлтэй байна уу ?', 'Анхаарууллага', {
            confirmButtonText: 'Тийм',
            cancelButtonText: 'Үгүй',
            type: 'warning'
          }).then(() => {
            const payload = { podcode: this.data.podcode, outletname: this.data.outletname, user: this.user }
            this.load = true
            managerServices.savePodConfig(payload).then((res) => {
              this.load = false
              if (res.statusCode === 'failed') {
                if (res.type === 'validation') {
                  const errorMessage = Object.keys(res.err).map(key => res.err[key][0]).join(', ')
                  this.$message({
                    type: 'warning',
                    message: errorMessage
                  })
                } else {
                  this.$message({
                    type: 'warning',
                    message: res.message
                  })
                }
              } else if (res.statusCode === 200) {
                this.$message({
                  type: 'success',
                  message: res.message
                })
                this.getData()
                this.hide()
              }
            }).catch(() => {
              this.load = false
              this.$message({
                type: 'error',
                message: 'Тохиргоо хадгалах явцад алдаа гарлаа'
              })
            })
          })
        } else {
          this.$message({
            type: 'warning',
            message: 'Хадгалах мэдээлэл алдаатай байна !!!'
          })
        }
      })
    }
  }
}
</script>
