// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  'aws_project_region': 'ap-southeast-1',
  'aws_cognito_identity_pool_id': 'ap-southeast-1:ac005d2d-0202-4212-8602-61015f6b2dd6',
  // 'aws_cognito_identity_pool_id': 'ap-southeast-1:66fe0205-c2b5-4685-a193-9cc9484533fb',
  'aws_cognito_region': 'ap-southeast-1',
  'aws_user_pools_id': 'ap-southeast-1_QGs0keGTk',
  // 'aws_user_pools_id': 'ap-southeast-1_1HaGleylw',
  'aws_user_pools_web_client_id': '2elqkhn3tb6s1uu0nuevf2043l',
  // 'aws_user_pools_web_client_id': '5re8ivdqto71ua690hokrmkbv4',
  'oauth': {}
}
export default awsmobile
