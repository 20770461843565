import Vue from 'vue'
import Router from 'vue-router'
import DashboardLayout from '../pages/layout/mainLayout.vue'
import Dashboard from '../pages/dashboard'
import Report from '../pages/report'
import Coupon from '../pages/coupon'
import ReportSalbar from '../pages/reportSalbar'
import ReportSales from '../pages/reportSales'
import ReportDelivery from '../pages/reportDelivery'
import Profile from '../pages/profile'
import NotFound from '../pages/404'
import Login from '../pages/login'
import Maps from '../pages/map'
import Orders from '../pages/orders'
import Dash from '../pages/dash'
import Driver from '../pages/driverSettings'
import { AmplifyEventBus } from 'aws-amplify-vue'
import { getUser } from '@/utils/auth.js'
import About from '@/views/About.vue'
import SignUp from '@/views/SignUp.vue'
import SignUpConfirm from '@/views/SignUpConfirm.vue'
import EditAddress from '../components/editAddress.vue'
import MapPod from '../components/map.vue'
import Address from '../components/address.vue'
import Search from '../components/search.vue'
import Vehicle from '../components/vehicle.vue'
import DriverDistribution from '../components/driverDistribution.vue'
import Banner from '../components/banner.vue'
import BranchDetails from '../components/branchDetails/branchDetails.vue'
import CareerPage from '../components/careerPage/careerPage.vue'

import outletSettings from '../pages/settings/outletSettings.vue'
import podSettings from '../pages/settings/podSettings.vue'
import outletScheduleSettings from '../pages/settings/outletScheduleSettings.vue'
import rKeeperSettings from '../pages/settings/rKeeperSettings.vue'
import Reset from '../pages/reset.vue'
import Forgot from '../pages/forgot.vue'
import Blacklist from '../pages/blacklist'
import outletLog from '../pages/settings/outletLog.vue'

Vue.use(Router)
export const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: '/dashboard',
      meta: { requiresAuth: true }
    },
    {
      path: '/about',
      name: 'about',
      component: About
    },
    {
      path: '/signUp',
      name: 'signUp',
      component: SignUp,
      meta: { requiresAuth: false }
    },
    {
      path: '/signUpConfirm',
      name: 'signUpConfirm',
      component: SignUpConfirm,
      meta: { requiresAuth: false }
    },
    {
      path: '/signIn',
      name: 'signIn',
      component: Login,
      meta: { requiresAuth: false }
    },
    { path: '/main',
      component: DashboardLayout,
      redirect: '/dashboard',
      children: [
        {
          path: '/dashboard',
          name: 'dashboard',
          component: Dashboard
        },
        {
          path: '/report',
          name: 'report',
          component: Report
        },
        {
          path: '/reportSalbar',
          name: 'reportSalbar',
          component: ReportSalbar
        },
        {
          path: '/reportSales',
          name: 'reportSales',
          component: ReportSales
        },
        {
          path: '/reportDelivery',
          name: 'reportDelivery',
          component: ReportDelivery
        },
        {
          path: '/orders',
          name: 'orders',
          component: Orders
        },
        {
          path: '/map',
          name: 'map',
          component: Maps
        },
        {
          path: '/profile',
          name: 'profile',
          component: Profile
        },
        {
          path: '/drivers',
          name: 'drivers',
          component: Driver
        },
        {
          path: '/dash',
          name: 'dash',
          component: Dash
        },
        {
          path: '/editAddress',
          name: 'editAddress',
          component: EditAddress
        },
        {
          path: '/pod',
          name: 'mapPod',
          component: MapPod
        },
        {
          path: '/address',
          name: 'address',
          component: Address
        },
        {
          path: '/search',
          name: 'search',
          component: Search
        },
        {
          path: '/banner',
          name: 'Banner',
          component: Banner
        },
        {
          path: '/vehicle',
          name: 'Vehicle',
          component: Vehicle
        },
        {
          path: '/driverDistribution',
          name: 'DriverDistribution',
          component: DriverDistribution
        },
        {
          path: '/branchDetails',
          name: 'BranchDetails',
          component: BranchDetails
        },
        {
          path: '/careerPage',
          name: 'CareerPage',
          component: CareerPage
        },
        {
          path: '/outlet-settings',
          name: 'outlet-settings',
          component: outletSettings
        },
        {
          path: '/pod-settings',
          name: 'pod-settings',
          component: podSettings
        },
        {
          path: '/schedule-settings',
          name: 'schedule-settings',
          component: outletScheduleSettings
        },
        {
          path: '/outlet-log',
          name: 'outlet-log',
          component: outletLog
        },
        {
          path: '/coupon',
          name: 'coupon',
          component: Coupon
        },
        {
          path: '/rkeepermenu-settings',
          name: 'rkeepermenu',
          component: rKeeperSettings
        },
        {
          path: '/blacklist',
          name: 'blacklist',
          component: Blacklist
        }]
    },
    { path: '/login', component: Login },
    { path: '*', component: NotFound },
    { path: '/reset', name: 'reset', component: Reset },
    { path: '/forgot/:email', name: 'forgot', props: true, component: Forgot, meta: { requiresAuth: false } }
  ]
})

AmplifyEventBus.$on('authState', async (state) => {
  const pushPathes = {
    signedOut: () => {
      router.push({ path: '/signIn' })
    },
    signUp: () => {
      router.push({ path: '/signUp' })
    },
    confirmSignUp: () => {
      router.push({ path: '/signUpConfirm' })
    },
    signIn: () => {
      router.push({ path: '/signIn' })
    },
    signedIn: () => {
      router.push({ path: '/' })
    }
  }
  if (typeof pushPathes[state] === 'function') {
    pushPathes[state]()
  }
})

router.beforeResolve(async (to, from, next) => {
  const user = await getUser()
  if (!user) {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      return next({
        path: '/signIn'
      })
    }
  } else {
    if (to.matched.some((record) => typeof (record.meta.requiresAuth) === 'boolean' && !record.meta.requiresAuth)) {
      console.log(to)
      return next()
    }
  }
  return next()
})

export default router
