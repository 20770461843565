export default {
  BASE_SERVICE_URL: 'https://w6q9xumm16.execute-api.ap-southeast-1.amazonaws.com/production',
  MANAGER_SERVICE_URL: ' https://w6q9xumm16.execute-api.ap-southeast-1.amazonaws.com/production',
  // MANAGER_SERVICE_URL: 'http://10.10.228.209:8082',
  DRIVER_SERVICE_URL: ' https://w6q9xumm16.execute-api.ap-southeast-1.amazonaws.com/production',
  // DRIVER_SERVICE_URL: 'http://localhost:8085',
  ORDER_SERVICE_URL: ' https://w6q9xumm16.execute-api.ap-southeast-1.amazonaws.com/production',
  REPORT_SERVICE_URL: ' https://w6q9xumm16.execute-api.ap-southeast-1.amazonaws.com/production',

  ADDRESS_SERVICE_URL_OLD: ' https://sx01ev83mc.execute-api.ap-southeast-1.amazonaws.com/production',

  // ADDRESS_SERVICE_URL: 'http://192.168.230.160:8088',
  ADDRESS_SERVICE_URL: 'https://z4ryw4kny0.execute-api.ap-southeast-1.amazonaws.com/production',
  // ADDRESS_SERVICE_URL: 'http://13.215.104.238:8088',
  // ADDRESS_SERVICE_URL_LOCAL: 'http://192.168.230.160:8088',
  BASE_PATH_GATEWAY: 'https://v9wi3kj6f6.execute-api.ap-southeast-1.amazonaws.com/prod',
  MENU_SERVICE_URL: 'https://w6q9xumm16.execute-api.ap-southeast-1.amazonaws.com/production'
}
