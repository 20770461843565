<template>
  <el-row style="padding:10px 0">
    <span style="font-weight:500;font-size:18px;color:#4f4f4f;">{{ title }}</span>
    <el-divider ></el-divider>
  </el-row>
</template>
<script>

export default {
  props: ['title']
}
</script>
