<template>
  <div>
    <el-card>
        <div slot="header" class="clearfix">
            <span style="float: left"><b>Салбарын мэдээлэл засах</b></span>
        </div>
        <el-table :data="outlets" size="mini" v-loading="loading">
            <el-table-column label="Нэр" prop="name_mn"></el-table-column>
            <el-table-column label="Address" prop="address"></el-table-column>
            <el-table-column label="Нээх цаг" prop="start_time"></el-table-column>
            <el-table-column label="Хаах цаг" prop="end_time"></el-table-column>
            <el-table-column label="have_delivery" prop="have_delivery">
            <template slot-scope="scope">
                <el-tag type="success" size="mini" v-if="scope.row.have_delivery === 1">Тийм</el-tag>
                <el-tag type="info" size="mini" v-else>Үгүй</el-tag>
            </template>
            </el-table-column>
            <el-table-column label="have_pickup" prop="have_pickup">
            <template slot-scope="scope">
                <el-tag type="success" size="mini" v-if="scope.row.have_pickup === 1">Тийм</el-tag>
                <el-tag type="info" size="mini" v-else>Үгүй</el-tag>
            </template>
            </el-table-column>
            <el-table-column>
            <template #default="scope">
                <el-button @click="() => handleShowDialog(scope.row)" style="border:none;" circle icon="el-icon-s-tools"></el-button>
            </template>
            </el-table-column>
        </el-table>
    </el-card>
    <branch-dialog v-if="dialogVisible" :currentData="dataForDialog" :visible="dialogVisible" :handleHide="handleHideDialog" :refetch="getOutlets"></branch-dialog>
  </div>
</template>

<script>
import managerServices from '@/services/managerServices.js'
import BranchDialog from './branchDialog.vue'
export default {
  components: { BranchDialog },
  data () {
    return {
      outlets: [],
      dialogVisible: false,
      dataForDialog: null,
      loading: false
    }
  },
  beforeMount () {
    this.getOutlets()
  },
  methods: {
    console (msg, info) {
      console.log(msg, info)
    },
    handleHideDialog () {
      this.dialogVisible = false
    },
    handleShowDialog (data) {
      this.dataForDialog = data
      this.dialogVisible = true
    },
    getOutlets () {
      this.loading = true
      managerServices.getOutlets().then((res) => {
        if (res.statusCode === 200) {
          this.outlets = res.result
          this.loading = false
        }
      }).catch(err => {
        console.log(err)
        this.outlets = []
      })
    }
  }
}
</script>
