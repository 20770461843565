<template>
  <div class="without-call">
    <h4 class="text-uppercase mt0">Купоны мэдээлэл:</h4>
    <el-row :gutter="12" style="margin: 15px 0px">
      <el-col
        v-loading="callLogIndexesLoading"
        :span="11"
      >
        <el-card shadow="always">
          <h5 class="mt0 mb0 p0 text-uppercase">
            Ашигласан:
          </h5>
          <div class="stat-container">
            <li v-for="(item, index) in usedCoupon" :key="index" style="font-size: 9pt; list-style: none; text-align: left; padding: 7px" >
              {{ moment(item.start_distribution_date).format('YYYY/MM/DD HH:mm:ss') }} - {{ moment(item.end_distribution_date).format('YYYY/MM/DD HH:mm:ss') }} - {{ item.coupon_count }} ш
            </li>
          </div>
        </el-card>
      </el-col>
      <el-col
        v-loading="callLogIndexesLoading"
        :span="11"
      >
      <el-card shadow="always">
          <h5 class="mt0 mb0 p0 text-uppercase">
            Ашиглаагүй:
          </h5>
          <div class="stat-container">
            <li v-for="(item, index) in notUsedCoupon" :key="index" style="font-size: 9pt; list-style: none; text-align: left; padding: 7px" >
               {{ moment(item.start_distribution_date).format('YYYY/MM/DD HH:mm:ss') }} - {{ moment(item.end_distribution_date).format('YYYY/MM/DD HH:mm:ss') }} - {{ item.coupon_count }} ш
            </li>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import couponServices from '@/services/orderServices'
export default {
  props: ['dialogType'],
  data: function () {
    return {
      totalUsed: 0,
      totalUnUsed: 0,
      usedCoupon: [],
      notUsedCoupon: [],
      totalCompletedOrders: 0,
      callLogIndexesLoading: false,
      referLogIndexesLoading: false
    }
  },
  created () {
    this.callLogIndexesLoading = false
    this.referLogIndexesLoading = false
    this.getCouponDashboard()
  },
  methods: {
    getCouponDashboard () {
      this.callLogIndexesLoading = true
      couponServices.getDashboard().then((data) => {
        console.log(data, '?dashboard')
        this.notUsedCoupon = data.result.filter(coupon => {
          return coupon.is_used < 1
        })

        this.notUsedCoupon.map((item) => {
          this.totalUnUsed = this.totalUnUsed + item.coupon_count
        })

        this.usedCoupon = data.result.filter(coupon => {
          return coupon.is_used > 0
        })
        this.usedCoupon.map((item) => {
          this.totalUsed = this.totalUsed + item.coupon_count
        })
        this.callLogIndexesLoading = false
      }).catch((err) => {
        this.err = err
        this.callLogIndexesLoading = false
      })
    }
  }
}
</script>
