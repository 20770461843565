<template>
  <div id="app">
    <el-row style="margin-bottom: 20px">
      <!-- <el-col :span="5">
        <el-row>
          <el-col :span="21">
            <span style='margin-top: 7px; font-size:14px'>Нэр : </span>
            <el-select v-model="brand" placeholder="Брэндээ сонгоно уу?" @change="changeBrand" size="mini">
              <el-option v-for="brand in brands" :key="brand.value" :label="brand.value + '(' + brand.count + ')'"
                :value="brand.value">
              </el-option>
            </el-select>
          </el-col>
        </el-row>
      </el-col> -->
      <el-col :span="4">
        <!-- <el-form-item label="a"> -->
        <span class="select-field">Дүүрэг : </span>
        <el-select clearable v-model="district" placeholder="Дүүргээ сонгоно уу?" @change="changeDisrict" size="mini">
          <el-option v-for="district in districts" :key="district.key"
            :label="district.key + '(' + district.doc_count + ')'" :value="district.key">
          </el-option>
        </el-select>
        <!-- </el-form-item> -->
      </el-col>
      <el-col :span="4">
        <span class="select-field">Хороо : </span>

        <el-select :disabled="horoos.length === 0" clearable v-model="horoo" placeholder="Хороогоо сонгоно уу?"
          size="mini">
          <el-option v-for="horoo in horoos" :key="horoo.key" :label="horoo.key + ' (' + horoo.doc_count + ')'"
            :value="horoo.key">
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="4">
        <span class="select-field">ПОД : </span>

        <el-select :disabled="pods.length === 0" clearable v-model="pod" placeholder="ПОД сонгоно уу?" size="mini">
          {{ pod }}
          <el-option v-for="pod in pods" :key="pod.key" :label="pod.key" :value="pod.key">
          </el-option>
        </el-select>
      </el-col>

      <el-col :span="5">
        <el-input placeholder="Хайх хаягаа оруулна уу?" size="mini" @change="search" v-model="value">
          <i slot="prefix" class="el-input__icon el-icon-search"></i>

          <template #append>
            <el-button @click="search">Хайх</el-button>
          </template>

        </el-input>
      </el-col>
      <!-- <el-col :span="5">
        <el-row>
          <el-col :span="21">
            <span style='margin-top: 7px; font-size:14px'>Салбар : </span>
            <el-select v-model="outlet" placeholder="Салбараа сонгоно уу?" size="mini" @change="changeOutlet">
              <el-option v-for="outlet in outlets" :key="outlet.value" :label="outlet.value + ' (' + outlet.count + ')'"
                :value="outlet.value">
              </el-option>
            </el-select>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="8">
        <el-row>
          <span style='margin-top: 7px; font-size:14px'>Нийт хаягаас хайх : </span>
          <el-input style="width: 200px" placeholder="Хайх хаягаа оруулна уу?" size="mini" @change="search"
            v-model="value">
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
          </el-input>
        </el-row>
      </el-col> -->
    </el-row>

    <gmap-map :center="center" :zoom="14" :options="options" :draggable="false"
      style="width:100%; height: 70vh; margin-top: 5px;">
      <div>
        <div v-for="(item, index) in paths" :key="index">
          <gmap-polygon v-if="item.podcode === selectedPod.podcode" :paths="item.paths" :options="optionsPoly1"
            :editable="false" @click="focusCenterPlot(item)"> </gmap-polygon>
          <gmap-polygon v-else :paths="item.paths" :options="optionsPoly" :editable="false"
            @click="focusCenterPlot(item)"> </gmap-polygon>

        </div>
        <gmap-marker clickable v-for="marker in addresses" :key="marker.id" :position="marker.pos"
          :title="marker.full_address + ' ' + marker.bairnote" :draggable="true"
          @dragend="handleMarkerDragEnd(marker, $event)" @click="() => { handleMarkerClick(marker) }"
          @position_changed="updatePlaceM1(marker, $event)">
        </gmap-marker>
        <gmap-info-window v-if="clickedMarker" :position="clickedMarker.pos" :opened.sync="infoWindowVisible"
          @closeclick="infoWindowVisible = false">
          <div>
            <h4>{{ clickedMarker.full_address + ' ' + clickedMarker.bairnote }}</h4>
          </div>
        </gmap-info-window>
        <!-- <gmap-info-window v-else>
          <div>
          </div>
        </gmap-info-window> -->

      </div>
    </gmap-map>
    <!-- {{addresses[5]}} -->
    <el-button type="danger" style="float: right; margin-top: 15px; margin-right: 15px;"
      @click="updateLoc">Хадгалах</el-button>
    <!-- <div v-if="newPod===false">
      <el-row style = "margin-top: 15px; margin-bottom: 15px">
        <el-col :span="4">
          <el-row>
            <el-col :span="3"><h5>Нэр:</h5></el-col>
            <el-col :span="21"><el-input v-model="selectedPod.podcode" style="width:200px;"></el-input></el-col>
          </el-row>
        </el-col>
        <el-col :span="4">
          <el-col :span="3"><h5>Брэнд:</h5></el-col>
          <el-col :span="21"><el-input v-model="selectedPod.brand" style="width:200px;"></el-input></el-col>
        </el-col>
        <el-col :span="4">
          <el-col :span="3"><h5>Салбар:</h5></el-col>
          <el-col :span="21"><el-input v-model="selectedPod.outlet" style="width:200px;"></el-input></el-col>
        </el-col>
        <el-col :span="12">
          <el-button type="danger" @click="newPodFunction" icon="el-icon-plus">Шинээр под нэмэх</el-button>
          <el-button type="primary" @click="reload" icon="el-icon-refresh-left">Шинэчлэх</el-button>
          <el-button type="success" @click="save" icon="el-icon-check">Өөрчлөлтийг хадгалах</el-button>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="2"><h5>Захын цэгүүд:</h5></el-col>
        <el-col :span="22"><el-input type="textarea" v-model="selectedPod.poligon" ></el-input></el-col>
      </el-row>
    </div>
    <div v-else>
      <el-row style = "margin-top: 15px; margin-bottom: 15px">
        <el-col :span="4">
          <el-row>
            <el-col :span="3"><h5>Нэр:</h5></el-col>
            <el-col :span="21"><el-input v-model="newPodObj.podcode" style="width:200px;"></el-input></el-col>
          </el-row>
        </el-col>
        <el-col :span="4">
          <el-col :span="3"><h5>Брэнд:</h5></el-col>
          <el-col :span="21"><el-input  v-model="newPodObj.brand" style="width:200px;"></el-input></el-col>
        </el-col>
        <el-col :span="4">
          <el-col :span="3"><h5>Салбар:</h5></el-col>
          <el-col :span="21"><el-input @keydown.native="change" v-model="newPodObj.outlet" style="width:200px;"></el-input></el-col>
        </el-col>
        <el-col :span="12">
          <el-button type="primary" @click="reload" icon="el-icon-refresh-left">Шинэчлэх</el-button>
          <el-button type="success" @click="save1" icon="el-icon-check">Хадгалах</el-button>
        </el-col>
      </el-row>
      lat: 47.90869317997723
lon: 106.91643099999999
      <el-row>
        <el-col :span="2"><h5>Захын цэгүүд:</h5></el-col>
        <el-col :span="22"><el-input type="textarea" v-model="newPodObj.poligon" ></el-input></el-col>
      </el-row>
    </div> -->
  </div>
</template>
<style scoped>
.select-field {
  font-size: 14px;
}
</style>
<script>
import addressServices from '@/services/addressServices.js'
import { getUserName } from '@/utils/auth.js'
export default {
  created () {
    addressServices.getDistricts().then(response => {
      if (response.data.success) {
        this.districts = response.data.data
      }
    })
    addressServices.getPodList().then(response => {
      if (response.data.success) {
        this.pods = response.data.data
      }
    })

    // this.changeBrand()
    // addressServices.getPods('*', '*', '*').then(data => {
    //   for (var i = 0; i < data.length; i++) {
    //     var temp1 = []
    //     var poligon = data[i].poligon.split(' ')
    //     for (var j = 0; j < poligon.length; j = j + 2) {
    //       var temp = {
    //         lat: 0,
    //         lng: 0
    //       }
    //       temp.lat = parseFloat(poligon[j])
    //       temp.lng = parseFloat(poligon[j + 1])
    //       temp1.push(temp)
    //     }
    //     data[i].paths = temp1
    //   }
    //   this.paths = data
    // })
    // addressServices.getAddressByPod('*', '*').then(data => {
    //   data.forEach(element => {
    //     element.pos = {
    //       lat: parseFloat(element.lat),
    //       lng: parseFloat(element.lon)
    //     }
    //   })
    //   this.addresses = data
    // })
    getUserName().then(data => {
      this.user = data.username.split('@')[0]
    })
  },
  data () {
    return {
      user: '',
      value: '',
      infoWindowVisible: true,
      clickedMarker: null,
      updatedLocations: [],
      addresses: [],

      districts: [],
      district: '',
      horoos: [],
      horoo: '',

      pods: [],
      pod: '',
      limit: 10,
      from: 0,

      newPod: false,
      newPodObj: {},
      brands: [],
      brand: 'KFC',
      outlets: [],
      outlet: '',
      paths: [],
      selectedPod: {},
      options: {
        scrollwheel: true,
        mapTypeControl: true,
        streetViewControl: false,
        minZoom: 4,
        maxZoom: 20,
        gestureHandling: 'greedy'
      },
      center: {
        lat: 47.9174,
        lng: 106.9137
      },
      points: [
        { lat: 47.89678628, lng: 106.8961749 },
        { lat: 47.8987788, lng: 106.8962714 },
        { lat: 47.89896942, lng: 106.9082716 },
        { lat: 47.89616765, lng: 106.9081697 },
        { lat: 47.89598062, lng: 106.9018611 },
        { lat: 47.89536917, lng: 106.9018826 }
      ],
      optionsPoly: {
        fillColor: '#000000',
        strokeColor: '#FF0000',
        strokeOpacity: 0.9,
        fillOpacity: 0.1
      },
      optionsPoly1: {
        fillColor: '#000000',
        strokeColor: '#00FF00',
        strokeOpacity: 0.9,
        fillOpacity: 0.1
      }
    }
  },
  // mounted () {},
  methods: {
    changeDisrict () {
      this.horoo = []
      addressServices.getHoroos(this.district).then(response => {
        if (response.data.success) {
          this.horoos = response.data.data
        }
      })
    },
    search () {
      console.log(this.value, this.district, this.horoo, this.pod)
      addressServices.getAddress(this.district, this.horoo, this.pod, this.value, this.limit, this.from).then(response => {
        console.log(response)
        if (response.data.success) {
          response.data.data.forEach(element => {
            element.pos = {
              lat: parseFloat(element.latitude),
              lng: parseFloat(element.longitude)
            }
          })
          this.addresses = response.data.data
        }
      })
    },
    updateLoc () {
      console.log(this.updatedLocations)
      try {
        Promise.all(
          this.updatedLocations.map(async (item) => {
            const data = {
              _id: item._id,
              district: item.district,
              bairname: item.bairname === '' ? ' ' : item.bairname,
              bairnote: item.bairnote === '' ? ' ' : item.bairnote,
              city: item.city,
              horoo: item.horoo,
              latitude: `${item.latitude}`,
              longitude: `${item.longitude}`,
              podkfc: item.podkfc
            }
            await addressServices.updateAddressLoc(data)
          })
        )
        this.$notify({
          title: 'Амжилттай',
          message: 'Амжилттай хадгалагдлаа.',
          type: 'success'
        })
        this.updatedLocations = []
      } catch (error) {
        this.$notify({
          title: 'Алдаа гарлаа',
          message: 'Алдаа: ' + error,
          type: 'error'
        })
      }
    },
    updatePlaceM1 (item, loc) {
      let indexO = this.updatedLocations.findIndex(x => x._id === item._id)
      if (indexO === -1) {
        // var temp = {
        //   id: id,
        //   lat: loc.lat(),
        //   lng: loc.lng(),
        //   desc: 'kfc өөрчлөв'
        // }
        item.lat = loc.lat()
        item.lng = loc.lng()
        item.latitude = loc.lat()
        item.longitude = loc.lng()
        this.updatedLocations.push(item)
      } else {
        this.updatedLocations[indexO].lat = loc.lat()
        this.updatedLocations[indexO].lng = loc.lng()
        this.updatedLocations[indexO].latitude = loc.lat()
        this.updatedLocations[indexO].longitude = loc.lng()
      }
    },
    reload () {
      this.selectedPod = {}
      let outlet = this.outlet
      if (outlet === '') {
        outlet = '*'
      }
      let brand = this.brand
      if (brand === '') {
        brand = '*'
      }
      addressServices.getPods(brand, outlet, '*').then(data => {
        for (var i = 0; i < data.length; i++) {
          var temp1 = []
          var poligon = []
          if (data[i].poligon) {
            poligon = data[i].poligon.split(' ')
          }
          for (var j = 0; j < poligon.length; j = j + 2) {
            var temp = {
              lat: 0,
              lng: 0
            }
            temp.lat = parseFloat(poligon[j])
            temp.lng = parseFloat(poligon[j + 1])
            temp1.push(temp)
          }
          data[i].paths = temp1
        }
        this.paths = data
        this.newPod = false
      })
    },
    focusCenterPlot (pod) {
      this.selectedPod = pod
      addressServices.getAddressByPod('*', this.selectedPod.podcode).then(data => {
        data.forEach(element => {
          element.pos = {
            lat: parseFloat(element.lat),
            lng: parseFloat(element.lon)
          }
        })
        this.addresses = data
      })
    },
    changeOutlet () {
      addressServices.getPods(this.brand, this.outlet, '*').then(data => {
        for (var i = 0; i < data.length; i++) {
          var temp1 = []
          var poligon = []
          if (data[i].poligon) {
            poligon = data[i].poligon.split(' ')
          }
          for (var j = 0; j < poligon.length; j = j + 2) {
            var temp = {
              lat: 0,
              lng: 0
            }
            temp.lat = parseFloat(poligon[j])
            temp.lng = parseFloat(poligon[j + 1])
            temp1.push(temp)
          }
          data[i].paths = temp1
        }
        this.paths = data
      })
    },
    changeBrand () {
      addressServices.getOutletsByPods(this.brand).then(data => {
        this.outlets = data
      })
      addressServices.getPods(this.brand, '*', '*').then(data => {
        for (var i = 0; i < data.length; i++) {
          var temp1 = []
          var poligon = []
          if (data[i].poligon) {
            poligon = data[i].poligon.split(' ')
          }
          for (var j = 0; j < poligon.length; j = j + 2) {
            var temp = {
              lat: 0,
              lng: 0
            }
            temp.lat = parseFloat(poligon[j])
            temp.lng = parseFloat(poligon[j + 1])
            temp1.push(temp)
          }
          data[i].paths = temp1
        }
        this.paths = data
      })
    },
    handleMarkerClick (marker) {
      this.clickedMarker = marker
      this.infoWindowVisible = true
    },
    handleMarkerDragEnd (marker, loc) {
      marker.pos = {
        lat: loc.latLng.lat(),
        lng: loc.latLng.lng()
      }
      this.clickedMarker = marker
    },
    change () {
      this.$nextTick(() => {
        this.$forceUpdate()
      })
    }
  }
}

</script>
