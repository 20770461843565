<template>
  <el-dialog title="Хуудсын мэдээлэл засах" :visible.sync="visible" :before-close="handleHide">
    <el-form v-if="modeDetected" label-position="top">
      <el-form-item label="Нэр">
        <el-input v-model="formData.title"></el-input>
      </el-form-item>
      <el-form-item label="Дэлгэрэнгүй">
        <el-input v-model="formData.description"></el-input>
      </el-form-item>
      <el-form-item label="Идэвхитэй эсэх">
        <el-switch v-model="isActiveHandler" @change="changeIsActive"></el-switch>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
      <el-button>Буцах</el-button>
      <el-button :loading="submitLoading" @click="handleSave" type="primary">Хадгалах</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
export default {
  props: ['visible', 'handleHide', 'currentData', 'refetch'],
  data () {
    return {
      modeDetected: false,
      submitLoading: false,
      isActiveHandler: false,
      formData: {
        id: '',
        title: '',
        description: '',
        is_active: 0
      }
    }
  },
  methods: {
    async handleSave () {
      let response = await fetch('http://52.77.176.213/api/web/saveCareerPageInfo', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(this.formData)
      })
      response = await response.json()
      if (response.status === 'success') {
        this.$notify({ title: 'Амжилттай', message: 'Амжилттай хадгалагдлаа.', type: 'success' })
        this.refetch()
        this.handleHide()
      } else {
        this.$notify({ title: 'Алдаа гарлаа', message: 'Алдаа гарлаа', type: 'error' })
      }
    },
    changeIsActive (val) {
      if (val) {
        this.formData.is_active = 1
      } else {
        this.formData.is_active = 0
      }
    }
  },
  created () {
    if (this.currentData) {
      this.formData = this.currentData
      if (this.currentData.is_active === 1) {
        this.isActiveHandler = true
      } else {
        this.isActiveHandler = false
      }
    }
    this.modeDetected = true
  }
}
</script>
