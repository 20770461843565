import { render, staticRenderFns } from "./couponProduct.vue?vue&type=template&id=708b7fb0&"
import script from "./couponProduct.vue?vue&type=script&lang=js&"
export * from "./couponProduct.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports