<template>
  <div class="orders">
    <el-container style="height: 100%;">
      <el-main class="ordersMain">
        <el-row>
          <el-col :span="24">
            <deliveryOrders></deliveryOrders>
          </el-col>
        </el-row>
      </el-main>
    </el-container>
  </div>
</template>
<script>
import deliveryOrders from '@/components/deliveryOrders'
import managerServices from '@/services/managerServices.js'
export default {
  created () {
    this.$bus.on('driverReload', () => {
      let hours = (new Date()).getHours()
      let date1 = ''; let date2 = ''
      if (hours < 8) {
        date1 = new Date(new Date((new Date()).getFullYear(), (new Date()).getMonth(), (new Date()).getDate() - 1, 0, 0, 0).setHours(8))
        date2 = new Date(new Date((new Date()).getFullYear(), (new Date()).getMonth(), (new Date()).getDate(), 0, 59, 59).setHours(hours))
      } else {
        date1 = new Date(new Date((new Date()).getFullYear(), (new Date()).getMonth(), (new Date()).getDate(), 0, 0, 0).setHours(8))
        date2 = new Date(new Date((new Date()).getFullYear(), (new Date()).getMonth(), (new Date()).getDate() + 1, 0, 0, 0).setHours(8))
      }
      managerServices.getDriversByOutlet(JSON.stringify(this.outlet), date1, date2).then(data => {
        this.driverData = data
      })
    })
  },
  components: {
    deliveryOrders
  },
  data () {
    return {
      driverData: []
    }
  },
  methods: {
    showStartDriver () {
      this.$bus.emit('dialogShowStartDriver', this.driverData)
    }
  }
}
</script>
<style lang="scss">
  .orders {
    height: 100%;
    .el-table--mini,
    .el-table--small,
    .el-table__expand-icon {
      font-size: 14px;
    }

    .ordersMain {
      padding: 0 10px 0 0;

      .box-card {
        .el-card__header {
          background: #ddece778;
          padding: 10px 20px;
        }
      }

      h3 {
        margin-top: 0;
        color: #464646;
      }
    }

    .ordersAside {
      height: 95%;
      .box-card {
        margin-bottom: 1rem;
        .el-table__header-wrapper {
          display: none;
        }
        .el-card__body {
          padding: 13px;
        }
      }

      img {
        display: block;
        margin: 0 auto;
        margin-top: 5px;
      }

      h3 {
        margin-top: 0;
      }

      h4 {
        text-align: center;
        margin: 10px auto;
        margin-bottom: 5px;
      }
    }
  }
</style>
