<template>
<div>
  <el-row style="padding:10px">
    <el-col :span="4">
    <el-select v-model="filter.outletname" placeholder="Салбар">
    <el-option v-for="(item,index) in filter.outletDataSource" :key="index" :label="item.outletname" :value="item.outletname"></el-option>
    </el-select>
    </el-col>
    <el-col :span="1"></el-col>
    <el-col :span="4">
    <el-button type="primary" @click="getData">Шүүх</el-button>
    </el-col>
  </el-row>
  <el-row style="padding-top:20px;s">
        <el-table :data="data" size="mini" v-loading="loading" element-loading-background="rgba(0, 0, 0, 0.3)" border stripe>
          <el-table-column label="Салбар" prop="outletname" width="140px"></el-table-column>
          <el-table-column label="Тохиргоо">
              <el-table-column label="УЗ авах" prop="have_advanced">
                <template slot-scope="scope">
                  <span style="color:green" v-if="scope.row.have_advanced==1">Авна</span>
                  <span style="color:red" v-if="scope.row.have_advanced==0">Авахгүй</span>
                </template>
              </el-table-column>
              <el-table-column label="Хүргэлттэй" prop="have_delivery">
               <template slot-scope="scope">
                  <span style="color:green" v-if="scope.row.have_delivery==1">Хүргэлттэй</span>
                  <span style="color:red" v-if="scope.row.have_delivery==0">Хүргэлтгүй</span>
                </template>
              </el-table-column>
              <el-table-column label="Очиж авах" prop="have_pickup">
                <template slot-scope="scope">
                  <span style="color:green" v-if="scope.row.have_pickup==1">Авна</span>
                  <span style="color:red" v-if="scope.row.have_pickup==0">Авахгүй</span>
                </template>
              </el-table-column>
          </el-table-column>
          <el-table-column label="Төлөв" prop="status">
            <template slot-scope="scope">
                  <span style="color:green" v-if="scope.row.status==1">Идвэхитэй</span>
                  <span style="color:red" v-if="scope.row.status==0">Идвэхигүй</span>
                </template>
          </el-table-column>
          <el-table-column label="Захиалга шилжүүлэх" prop="is_redirect">
            <template slot-scope="scope">
              <span style="color:green" v-if="scope.row.is_redirect==1">Шилжүүлнэ</span>
              <span style="color:red" v-if="scope.row.is_redirect==0">Шилжүүлэхгүй</span>
            </template>
          </el-table-column>
          <el-table-column label="Шилүүлэх цаг">
              <el-table-column label="Эхлэх" prop="starttime">
              </el-table-column>
              <el-table-column label="Дуусах" prop="endtime">
              </el-table-column>
          </el-table-column>
          <!-- <el-table-column label="Вэб дээр харагдах" prop="web_show">
            <template slot-scope="scope">
              <span style="color:green" v-if="scope.row.web_show==1">Харагдана</span>
              <span style="color:red" v-if="scope.row.web_show==0">Харагдахгүй</span>
            </template>
          </el-table-column> -->
          <!-- <el-table-column label="Үйлчилгээний төрөл" prop="operation_type">
            <template slot-scope="scope">
              <span v-if="scope.row.operation_type=='main'">Үндсэн</span>
              <span v-if="scope.row.operation_type=='express'">Экспрэсс</span>
            </template>
          </el-table-column> -->
          <el-table-column width="180px">
            <template slot-scope="scope">
              <div style="display:flex;flex-direction:row;">
              <el-button size="mini" type="warning" @click="handleEditOutlet(scope.row)" icon="el-icon-edit">Засах</el-button>
              <el-button size="mini" type="primary" @click="handleShow(scope.row)" icon="el-icon-view">Харах</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <outlet-edit :getData="getData"></outlet-edit>
        <outlet-view></outlet-view>
  </el-row>
</div>
</template>

<script>
import managerServices from '@/services/managerServices.js'
import dateFormat from 'dateformat'
import moment from 'moment'
import OutletEdit from '../../components/outletSettings/outletEdit.vue'
import OutletView from '../../components/outletSettings/outletView.vue'

export default {
  data: function () {
    return {
      loading: false,
      data: [],
      filter: {
        outletname: '',
        outletDataSource: []
      }
    }
  },
  mounted () {},
  components: { OutletEdit, OutletView },
  created () {
    managerServices.getOutlets().then((res) => {
      if (res.statusCode === 200) {
        this.filter.outletDataSource = res.result
      }
    }).catch(err => {
      console.log(err)
      this.filter.outletDataSource = []
    })
    this.getData()
  },
  computed: {},
  methods: {
    getData () {
      this.loading = true
      managerServices.getOutletConfigs(this.filter.outletname).then((res) => {
        this.loading = false
        if (res.statusCode === 200) {
          this.data = res.result.map(row => ({
            ...row,
            starttime: row.redirect_starttime ? dateFormat(row.redirect_starttime, 'HH:MM:ss') : null,
            endtime: row.redirect_endtime ? dateFormat(row.redirect_endtime, 'HH:MM:ss') : null,
            start_time: row.start_time ? moment(row.start_time, 'HH:mm') : null,
            end_time: row.end_time ? moment(row.end_time, 'HH:mm') : null,
            google_map_url: row.google_map_url ? row.google_map_url : '' }))
        } else {
          this.$notify({ type: 'warning', message: 'Мэдээлэл татхад алдаа гарлаа' })
        }
      })
    },
    handleEditOutlet (row) {
      this.$bus.emit('dialogShowOutlet', Object.assign({}, row))
    },
    handleShow (row) {
      this.$bus.emit('dialogShowOutletConfigView', row)
    }
  },
  watch: {
    'filter.outletname' () {
      this.getData()
    }
  }
}
</script>
