import { mainRequests } from '@/helpers/custom'
export default {
  getMenus (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests.postMenu('/menu/getManagerMenu', payload).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  }
}
